export const filelist = [
    {
        name: "Informationsschreiben",
        path: "/documents/info_writing_for_20221213/Informationsschreiben.pdf"
    },
    {
        name: "Lohntabelle 2023 mit Basis 107.0 Punkte",
        path: "/documents/info_writing_for_20221213/Lohntabelle_2023_mit_Basis_107.0_Punkte.pdf"
    },
    {
        name: "Steuersatzmeldung 2022 1",
        path: "/documents/info_writing_for_20221213/Steuersatzmeldung_2022_1.pdf"
    },
    {
        name: "Steuersatzmeldung 2022 1 - Word",
        path: "/documents/info_writing_for_20221213/Steuersatzmeldung_2022_1.docx"
    },
    {
        name: "Katholikenzahlmeldung 2022 1",
        path: "/documents/info_writing_for_20221213/Katholikenzahlmeldung_2022_1.pdf"
    },
    {
        name: "Katholikenzahlmeldung 2022 1 - Word",
        path: "/documents/info_writing_for_20221213/Katholikenzahlmeldung_2022_1.docx"
    },
    {
        name: "Merkblatt Verhaltenskodex für Kirchgemeinden 2022",
        path: "/documents/info_writing_for_20221213/Merkblatt_Verhaltenskodex_für_Kirchgemeinden_2022.pdf"
    },
    {
        name: "Merkblatt Bildrechte für Kantonalkirchen_2022",
        path: "/documents/info_writing_for_20221213/Merkblatt_Bildrechte_für_Kantonalkirchen_2022.pdf"
    },
]