export default {
    id: 20160520,
    date: 20160520,
    title: 'Session des Kantonskirchenrates vom 20. Mai 2016',
    subtitle: 'Der Kantonskirchenrat hielt seine ordentliche Frühjahrssession <br>' +
        'am 20. Mai 2016, ab 14.00 Uhr im SJBZ in Einsiedeln.',
    sessionDate: '20.05.2016',
    resultPdfs: [
        {
            value: 'Protokoll der Session vom 20. Mai 2016',
            link: '/protocols/20160520/20160615190725.pdf',
        },
        {
            value: 'Informationsschreiben vom 12. Juni 2017',
            link: '/protocols/20160520/20160615190940.pdf',
        },
    ],
    shortResults: [
        {
            name: '20170519sr1',
            value: 'der Rechenschaftsbericht 2015 der Rekurskommission wurde genehmigt;'
        },
        {
            name: '20170519sr2',
            value: 'die Jahresrechnung 2015 und Bilanz per 31. Dezember 2015 wurden genehmigt;'
        },
        {
            name: '20170519sr3',
            value: 'der Tätigkeitsbericht 2015 des Kantonalen Kirchenvorstandes wurde genehmigt.'
        },
    ],
    Traktanden: [
        {
            name: '20160520tt1',
            value: 'Gebet, Begrüssung und Präsenz',
        },
        {
            name: '20160520tt2',
            value: 'Rechenschaftsbericht 2015 der Rekurskommission',
        },
        {
            name: '20160520tt3',
            value: 'Jahresrechnung 2015 und Bilanz per 31. Dezember 2015',
        },
        {
            name: '20160520tt4',
            value: 'Tätigkeitsbericht 2015 des Kantonalen Kirchenvorstandes',
        },
        {
            name: '20160520tt5',
            value: 'Informationen der Ressortchefs und Fragestunde',
        },
        {
            name: '20160520tt6',
            value: 'Verschiedenes',
        },
    ],
    Unterlagen: [
        {
            value: 'Sessionseinladung',
            link: '/protocols/20160520/20160428105816.pdf',
        },
        {
            value: 'Beilagenblatt',
            link: '/protocols/20160520/20160428105913.pdf',
        },
        {
            value: 'Rechenschaftsbericht 2015',
            link: '/protocols/20160520/20160428110004.pdf',
        },
        {
            value: 'Bericht und Antrag der GPK an den Kantonskirchenrat',
            link: '/protocols/20160520/20160428110120.pdf',
        },
    ]
}