export default {
    id: 7,
    type:"InformationWrting",
    name: 'infoWritingFor20190101',
    title: 'Informationsschreiben und Besoldungsentscheide des Kantonalen Kirchenvorstandes\n' +
        'per 1. Januar 2019',
    subtitle: '(Jahresarbeitsstunden/Teuerungsausgleich/\n' +
        'Lohntabellen / Meldung Katholikenzahl / Terminliste)',
    value: 'Der Kantonale Kirchenvorstand beschloss an der Sitzung vom 13. Dezember 2018:<br>' +
        '<br>' +
        '1. Die “Lohnklassen und Lohnstufen” gemäss Anhang 2 der Personal- und Besoldungsverordnung ' +
        'werden für das Jahr 2019 nicht angehoben, sondern bleiben unverändert gegenüber den Jahren 2011 - 2018 ' +
        '(auf den Indexstand von unverändert 104.2 Punkten, Basis Dezember 2005 = 100 Punkte). ' +
        'Indexstand 30. November 2018: 103.2 <br>' +
        '<br>' +
        '2. Die Jahresarbeitsstunden für das Jahr 2019 gemäss § 14 Abs. 1 des Personal- und Besoldungsgesetzes ' +
        'belaufen sich auf 2’080 Stunden (ohne Ferienanspruch gerechnet).',
    target: "InfoSite",
    Post: true,
    date: 20190101,
    files: {
        filesTitle: "Dateien: ",
        filesList: [
            {
                name: "Informationsschreiben vom 14. Dezember 2018",
                path: '/documents/info_writing_for_20190101/informationsschreiben.pdf'
            },
            {
                name: "Lohntabelle 2019 (pdf)",
                path: '/documents/info_writing_for_20190101/lohntabelle.pdf'
            },
            {
                name: "Lohntabelle 2019 (Excel-Tabelle)",
                path: '/documents/info_writing_for_20190101/lohntabelle.xlsx'
            },
            {
                name: "Meldung Katholikenzahl per 1. Januar 2019 (pdf)",
                path: '/documents/info_writing_for_20190101/katholikenzahl.pdf'
            },
            {
                name: "Meldung Katholikenzahl per 1. Januar 2019 (Word)",
                path: '/documents/info_writing_for_20190101/katholikenzahl.docx'
            },
            {
                name: "Terminliste für Kirchgemeinden",
                path: '/documents/info_writing_for_20190101/terminliste.pdf'
            },
        ]
    }
}