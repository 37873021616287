import {filelist} from "../file_lists/filelist20221213";

export default {
    id: 27,
    name: "20221116InformationWriting",
    type: "InformationWrting",
    title: "Informationsschreiben und Besoldungsentscheide des Kantonalen Kirchenvorstandes per 1. Januar 2023",
    subtitle: "(Teuerungsausgleich / Lohntabellen / Meldung Steuerfuss / Meldung Katholikenzahl)",
    value: `
Der Kantonale Kirchenvorstand beschloss: <br>
Die “Lohnklassen und Lohnstufen” gemäss Anhang 2 der Personal- und
Besoldungsverordnung werden per 1. Januar 2023 für das Jahr 2023 auf den
Indexstand von 107.0 Punkten (auf der Basis Dezember 2005 = 100 Punkte)
erhöht.<br>
Die Kirchgemeinden haben den beschlossenen Steuerfuss für das Jahr 2023
mittels des Formulars bis am 20. Dezember 2022 zu melden.<br>
Und die Katholikenzahlen per 1. Januar 2023 etc. sind mittels des
Formulars bis am 31. Januar 2023 von den Kirchgemeinden zu melden.
Das angekündigte Merkblatt zur Umsetzung des Verhaltenskodexes zum
Umgang mit Macht und zur Prävention von Machtmissbrauch im Bistum Chur
im Kanton Schwyz konnte inzwischen verabschiedet werden.<br>
Die RKZ hat ein neues “Merkblatt Bildrechte” des Katholischen
Medienzentrums Zürich publiziert. Dieses informiert darüber, was
kirchliche Mitarbeitende bei der Publikation und Verwendung von Bildern
auf Websites und in sozialen Medien beachten müssen.
    `,
    target: "InfoSite",
    Post: true,
    date: 20221116,
    files: {
        filesTitle: "Dateien",
        filesList: filelist
    }
}