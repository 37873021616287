export default {
    id: 30,
    name: "20230628InformationWriting",
    type: "InformationWrting",
    title: "Den sexuellen Missbrauch in der römisch-katholischen Kirche aufarbeiten",
    value: `Die drei nationalen kirchlichen Institutionen der Schweiz - SBK, RKZ und KOVOS - haben 2021 gemeinsam entschieden, ein unabhängiges wissenschaftliches Pilotprojekt zur Geschichte des sexuellen Missbrauchs von Minderjährigen und Erwachsenen durch katholische Kleriker, kirchliche Angestellte und Ordensangehörige in der Schweiz seit den 1950er Jahren durchzuführen. Dazu haben sie der Universität Zürich einen Forschungsauftrag erteilt. Im Zentrum stehen dabei die Strukturen, die sexuellen Missbrauch ermöglichten und die verhinderten, dass dieser aufgedeckt und geahndet wurde.<br>
<br>
Am 12. September 2023 wird der Schlussbericht des einjährigen Pilotprojekts veröffentlicht. Er wird erste grundlegende Fragen klären: Welche missbrauchsrelevanten Akten sind in den kirchlichen Archiven vorhanden? Wie wurde und wird mit diesen Akten umgegangen? Was ist ihnen zu entnehmen? Welche anderen Quellen gibt es? <br>
<br>
Ende Juni 2023 haben SBK, RKZ und KOVOS zudem entschieden, die unabhängige historische Erforschung in einem dreijährigen Folgeprojekt 2024-2026 zu vertiefen. Damit will die Kirche ihre Verantwortung gegenüber den Betroffenen und der Gesellschaft wahrnehmen und ihre eigene Vergangenheit aufarbeiten. Zentrales Anliegen ist, den Missbrauch in den eigenen Reihen und dessen Ursachen noch entschiedener zu bekämpfen und weitere Opfer zu verhindern.<br>
<br>
Mehr zum Projekt: <a href="https://www.missbrauch-kath-info.ch">www.missbrauch-kath-info.ch</a>`,
    target: "InfoSite",
    Post: true,
    date: 20230629,
}
