export default {
    id: 20,
    name: "20201020InformationWriting",
    type: "InformationWrting",
    title: 'Information zur Session des Kantonskirchenrates vom 23. Oktober 2020',
    value: 'Die Session vom Freitag, 23. Oktober 2020, 14.00 Uhr, im Schweizerischen ' + 
        'Jugend- und Bildungs-Zentrum, Lincolnweg 23 in 8840 Einsiedeln, kann wie eingeladen stattfinden.' +
        'Es gelten die Massnahmen aufgrund des Schutzkonzeptes gemäss dem Schreiben vom 19. Oktober 2020',
    target: "InfoSite",
    Post: true,
    date: 20201020,
    files: {
        filesTitle: "Dateien:",
        filesList: [
            {
                name: "Schreiben",
                path: '/documents/info_writing_for_20201023/Schreiben.pdf'
            },
        ]
    }
}
