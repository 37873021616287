export default {
    id: 20180525,
    date: 20180525,
    uploadDate: '21.06.2018',
    title: 'Session des Kantonskirchenrates vom 25. Mai 2018',
    shortDescriptionString: 'Sessionsprotokoll / Informationsschreiben / Papstbesuch / Motion Ausländerstimmrecht / ' +
        'Ergebnisse in Kurzform / Beitritt zur RKZ - Referat Luc Humbel, Präsident RKZ /' +
        'Sessionseinladung und Unterlagen',
    subtitle: 'Der Kantonskirchenrat hielt seine ordentliche Frühlingssession ab ' +
        'am Freitag, 25. Mai 2018, im Schweizerischen Jugend- und Bildungszentrum SJBZ in Einsiedeln.',
    sessionDate: '25.05.2018',
    resultPdfs: [
        {
            value: 'Protokoll der Session vom 25. Mai 2018',
            link: '/protocols/20180525/protokoll.pdf',
        },
        {
            value: 'Informationsschreiben vom 22. Juni 2018',
            link: '/protocols/20180525/informationsschreiben.pdf',
        },
        {
            value: 'Papstbesuch - Aufruf an Kirchgemeinden',
            link: '/protocols/20180525/papst_besuch.pdf',
        },
        {
            value: 'Antrag Nachkredit für Papstbesuch',
            link: '/protocols/20180525/antrag_nachkredit_pabstbesuch.pdf',
        },
        {
            value: 'Motion Ausländerstimmrecht',
            link: '/protocols/20180525/motion_auslaenderstimmrecht.pdf',
        },
    ],
    shortResults: [
        {
            name: 'sr1',
            value: 'der Rechenschaftsbericht 2017 der Rekurskommission wurde genehmigt;'
        },
        {
            name: 'sr2',
            value: 'die Jahresrechnung 2017 und Bilanz per 31. Dezember 2017 wurden genehmigt;'
        },
        {
            name: 'sr3',
            value: 'der Tätigkeitsbericht 2017 des Kantonalen Kirchenvorstandes wurde genehmigt;'
        },
        {
            name: 'sr4',
            value: 'Der Beitritt zur Römisch-Katholischen Zentralkonferenz (RKZ) ' +
                'wurde mit 91 Stimmen gegen 11 Nein und 4 Enthaltungen beschlossen ' +
                '(ein Antrag auf Unterstellung unter das obligatorische Referendum wurde deutlich abgelehnt; ' +
                'dieser Beitrittsbeschluss unterliegt jedoch dem fakultativen Referendum);'
        },
        {
            name: 'sr5',
            value: 'In die vorberatende Kommission zur Überprüfung der Gesetze der Röm.-kath. Kantonalkirche Schwyz ' +
                'wurden Andreas Marty (als Präsident), Dr. Ruedi Beeler, Marlis Birchler, Maria Bürgler, ' +
                'Anton Schnellmann, Urs Peter Seeholzer und Peter Studiger einstimmig gewählt.'
        },
    ],
    shortResultsSpecialInformations: [
        {
            title: "Informationen zur RKZ - Referat Luc Humbel, Präsident RKZ ",
            links: [
                {
                    name: 'Informationsschreiben vom 29. Mai 2018',
                    file: '/protocols/20180525/sr_special/informationsschreiben.pdf'
                },
                {
                    name: 'Referat Luc Humbel, Präsident RKZ',
                    file: '/protocols/20180525/sr_special/referat.pdf'
                },
                {
                    name: 'Präsentation Luc Humbel - Beitritt zur RKZ',
                    file: '/protocols/20180525/sr_special/praesentation.pdf'
                }
            ]
        }
    ],
    Traktanden: [
        {
            name: 'tt1',
            value: 'Gebet, Begrüssung und Präsenz',
        },
        {
            name: 'tt2',
            value: 'Rechenschaftsbericht 2017 der Rekurskommission',
        },
        {
            name: 'tt3',
            value: 'Jahresrechnung 2017 und Bilanz per 31. Dezember 2017',
        },
        {
            name: 'tt4',
            value: 'Tätigkeitsbericht 2017 des Kantonalen Kirchenvorstandes',
        },
        {
            name: 'tt5',
            value: 'Beschluss über den Beitritt zur Römisch-Katholischen Zentralkonferenz (RKZ)',
        },
        {
            name: 'tt6',
            value: 'Wahl einer vorberatenden Kommission zur Überprüfung ' +
                'der Gesetze der Röm.-kath. Kantonalkirche Schwyz',
        },
        {
            name: 'tt7',
            value: 'Informationen der Ressortchefs und Fragestunde',
        },
        {
            name: 'tt8',
            value: 'Verschiedenes',
        },
    ],
    Unterlagen: [
        {
            value: 'Sessionseinladung',
            link: '/protocols/20180525/sessionseinladung.pdf',
        },
        {
            value: 'Beilagenblatt',
            link: '/protocols/20180525/beilagenblatt.pdf',
        },
        {
            value: 'Rechenschaftsbericht 2017',
            link: '/rechenschaftsberichte/20170424192009.pdf',
        },
        {
            value: 'Bericht und Antrag der GPK an den Kantonskirchenrat',
            link: '/protocols/20180525/bericht_und_antrag.pdf',
        },
        {
            value: 'Ergänzungsbericht Römisch-Katholischen Zentralkonferenz (RKZ)',
            link: '/protocols/20180525/ergaenzungsbericht.pdf',
        },
    ]
}