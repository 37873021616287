import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify, {
    iconfont: 'md',
    theme: {
        primary: "#D50000",
        secondary: "#e57373",
        accent: "#2196F3",
        error: "#f44336",
        warning: "#ffeb3b",
        info: "#2196f3",
        success: "#4caf50",
        white: "#FFFFFF"
    }
});
