import Vue from 'vue'
import Vuex from 'vuex'
import texts from './store/data/texts'
import locations from './store/data/locations'
import praesidentInnen from "./store/data/praesidentInnen";
import protokolle from "./store/data/protokolle";
import menu from "./store/menu/menu"
import kirchenvorstand from "./store/data/kirchenvorstand";
import documents from "./store/data/documents";
import rechenschaftsberichte from "./store/data/rechenschaftsberichte";
import mustervorlagen from "./store/data/mustervorlagen";
import pfarreien from "./store/data/pfarreien";
import kantonskirchenrat from "./store/data/kantonskirchenrat";
import kirchgemeinden from "./store/data/kirchgemeinden";


Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        texts:texts.texts,
        locations: locations.locations,
        praesidentInnen: praesidentInnen.praesidentInnen,
        Protokolle: protokolle.protokolle,
        menu: menu.menu,
        Kirchenvorstand: kirchenvorstand.kirchenvorstand,
        documents: documents.documents,
        rechenschaftsberichte: rechenschaftsberichte.rechenschaftsberichte,
        mustervorlagen: mustervorlagen.mustervorlagen,
        Pfarreien: pfarreien.pfarreien,
        Kantonskirchenrat: kantonskirchenrat.kantonskirchenrat,
        kirchgemeinden: kirchgemeinden.kirchgemeindenTable,
        viewSizeSmall: false,
        searchResults: false,
    },
    getters: {
        texts: function (state) {
            return state.texts
        },
        textsForKeysInArray: (state) => (keysArray) => {
            let textsToReturn = {};
            state.texts.forEach((langObj) => {
                keysArray.forEach((langKey) => {
                    if (langObj.name === langKey) {
                        textsToReturn[langObj.name] = langObj
                    }
                });
            });
            return textsToReturn
        },
        postsNew: function (state) {
            let posts = [];
            let currDateString = printDateMinusYear( 3 );
            let dateAboveInFuture = printDateMinusYear(0);
            state.texts.forEach(function(langObj){
                if (langObj.Post === true &&
                    langObj.date >= parseInt(currDateString) &&
                    langObj.date <= parseInt(dateAboveInFuture)) {
                    posts.push(langObj);
                }

            });
            state.Protokolle.SessionProtokolle.filter(proto => proto.date >= parseInt(currDateString) && 
                    proto.date <= parseInt(dateAboveInFuture)).forEach(
                (proto) => {
                    proto.target = "ProtokolleDetailed";
                    posts.push(proto);
                }
            );
            return posts.sort(dynamicSort("date"))
        },
        postsOld: function (state) {
            let posts = [];
            let currDateString = printDateMinusYear( 3 );
            state.texts.forEach(function(langObj){
                if (langObj.Post === true && langObj.date <= parseInt(currDateString)) {
                    posts.push(langObj);
                }

            });

            state.Protokolle.SessionProtokolle.filter(proto => proto.id <= parseInt(currDateString)).forEach(
                (proto) => {
                    proto.target = "ProtokolleDetailed";
                    posts.push(proto);
                }
            );
            return posts.sort(dynamicSort('date'));
        },
        registeredMembers: function (state) {
            return state.locations
        },
        PraesidentInnen: function (state) {
            return state.praesidentInnen
        },
        ProtocolTitles (state) {
            return state.Protokolle.gleichbleibendeTitel
        },
        SessionProtokolle: function (state) {
            return state.Protokolle.SessionProtokolle.sort(dynamicSort('date'))
        },
        ProtokollTableHeaders: function (state) {
            return state.Protokolle.headers
        },
        ProtokollTablePagination: function (state) {
            return state.Protokolle.pagination
        },
        menu: function (state) {
            return state.menu
        },
        KirchenvorstandMember: function(state) {
            return state.Kirchenvorstand.VorstandPersonen
        },
        searchData (state ) {
            return state
        },
        documents ( state ) {
            return state.documents
        },
        rechenschaftsberichte ( state ) {
            return state.rechenschaftsberichte
        },
        Sekretariat ( state ) {
            return state.texts.filter( text => text.name === 'sekretariat' )[0]
        },
        Rekurskommission ( state ) {
            return state.texts.filter( text => text.name === 'Rekurskommission' )
        },
        Mustervorlagen ( state ) {
            return state.mustervorlagen
        },
        Pfarreien ( state ) {
            return state.Pfarreien
        },
        MitgliederKantonskirchenrates (state) {
            return state.Kantonskirchenrat.files.filter(file => file.name === 'Kantonskirchenrat Mitglieder')
        },
        ErsatzMitgliederKantonskirchenrates (state) {
            return state.Kantonskirchenrat.files.filter(file => file.name === 'Kantonskirchenrat Ersatzmitglieder')[0]
        },
        KommissionenKantonskirchenrates (state) {
            return state.Kantonskirchenrat.files.filter(file => file.name === 'Kommissionen des Kantonskirchenrates')
        },
        infoSite: (state) => (id) => {
            let textToReturn = {};
            state.texts.forEach( (text) => {
                if (text !== undefined && text.hasOwnProperty('id') ) {
                    if (text.id.toString() === id.toString()) {
                        textToReturn = text;
                    }
                }
            });
            return textToReturn;
        },
        sessionProtokoll: (state) => (id) => {
            return state.Protokolle.SessionProtokolle
                .filter( protocol => protocol.id.toString() === id.toString())[0];
        },
        viewSizeSmall (state) {
            return state.viewSizeSmall
        },
        showSearchResults (state) {
            return state.searchResults
        },
        kirchgemeinden (state) {
            return state.kirchgemeinden
        },
        links (state){
            let links = [];
            state.texts.forEach( text => {
                if (text.hasOwnProperty('name')) {
                    if (text.name === 'Link') links.push(text);
                }
            });
            return links
        }
    },
    mutations: {
        toggleExpanded (state, phone) {
            state.Pfarreien.DekanatBereiche.forEach( (bereich, indexBereich) => {
                bereich.Dekanate.forEach ( (dekanat, indexDekanat) => {
                    if (dekanat.phone === phone) {
                        state.Pfarreien.DekanatBereiche[indexBereich].Dekanate[indexDekanat].expanded = !dekanat.expanded;
                    }
                })
            })
        },
        setViewSizeToSmall (state) {
            state.viewSizeSmall = true;
        },
        setViewSizeToNormal (state) {

            state.viewSizeSmall = false;
        },
        setSearchResultsOn (state) {
            state.searchResults = true;
        },
        setSearchResultsOff (state) {

            state.searchResults = false;
        }
    },
    actions: {
        toggleExpanded (context, phone) {
            context.commit('toggleExpanded', phone)
        },
        setViewSizeToSmall (context) {
            context.commit('setViewSizeToSmall')
        },
        setViewSizeToNormal (context) {
            context.commit('setViewSizeToNormal')
        },
        setSearchResultsOn (context) {
            context.commit('setSearchResultsOn')
        },
        setSearchResultsOff (context) {

            context.commit('setSearchResultsOff')
        }
    }
})

function printDateMinusYear( i ) {
    let temp = new Date();
    return padStr(temp.getFullYear() - i) +
        padStr(1 + temp.getMonth()) +
        padStr(temp.getDate());
}

function padStr(i) {
    return (i < 10) ? "0" + i : "" + i;
}

function dynamicSort(property) {
    let sortOrder = 1;
    return function (a,b) {
        let result = (a[property] > b[property]) ? -1 : (a[property] < b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}
