export default {
    id: 32,
    name: "20231218InformationWriting",
    type: "InformationWrting",
    title: "Informationsschreiben und Besoldungsentscheide des Kantonalen Kirchenvorstandes per 1. Januar 2024",
    subtitle: "(Teuerungsausgleich / Lohntabellen / Meldung Steuerfuss / Meldung Katholikenzahl)",
    value: `
    Der Kantonale Kirchenvorstand beschloss: <br>
    Die “Lohnklassen und Lohnstufen” gemäss Anhang 2 der Personal- und Besoldungsverordnung werden per 1. Januar 2024 für das Jahr 2024 auf den Indexstand von 108.5 Punkten (auf der Basis Dezember 2005 = 100 Punkte) erhöht. <br>
    Die Kirchgemeinden haben den beschlossenen Steuerfuss für das Jahr 2024 mittels des Formulars bis allerspätestens am 22. Dezember 2023 zu melden. <br>
    Die Katholikenzahlen per 1. Januar 2024 etc. sind mittels des Formulars bis am 19. Januar 2024 von den Kirchgemeinden zu melden. <br>
    Die Kirchgemeinden haben den Fragebogen betreffend Kommunaluntersuche zu beantworten.
    `,
    target: "InfoSite",
    Post: true,
    date: 20231218,
    files: {
        filesTitle: "Dateien",
        filesList: [
            {
                name: "1. Informationsschreiben vom 18. Dezember 2023",
                path: "/documents/info_writing_for_20231218/1_Informationsschreiben_vom_18._Dezember_2023.pdf"
            },
            {
                name: "2. Lohntabelle 2024",
                path: "/documents/info_writing_for_20231218/2_Lohntabelle_2024.pdf"
            },
            {
                name: "3. Steuersatzmeldung",
                path: "/documents/info_writing_for_20231218/3_Steuersatzmeldung.pdf"
            },
            {
                name: "4. Steuersatzmeldung - Word",
                path: "/documents/info_writing_for_20231218/4_Steuersatzmeldung.docx"
            },
            {
                name: "5. Katholikenzahlmeldung - Word",
                path: "/documents/info_writing_for_20231218/5_Katholikenzahlmeldung.docx"
            },
            {
                name: "6. Katholikenzahlmeldung",
                path: "/documents/info_writing_for_20231218/6_Katholikenzahlmeldung.pdf"
            },
            {
                name: "7. Fragebogen-Kommunaluntersuch 2023 - Word",
                path: "/documents/info_writing_for_20231218/7_Fragebogen-Kommunaluntersuch_2023.docx"
            },
            {
                name: "8. Handreichung Bistum Chur 2023",
                path: "/documents/info_writing_for_20231218/8_Handreichung_Bistum_Chur_2023.pdf"
            },
        ]
    }
}
