export default {
    id: 20240502,
    date: 20240502,
    uploadDate: '2.5.2024',
    title: 'Session des Kantonskirchenrates vom 31. Mai 2024',
    shortDescriptionString: `Sessionsprotokoll / Informationsschreiben / Ergebnisse in Kurzform / Traktanden /Sessionseinladung und Unterlagen`,
    subtitle: `Der Kantonskirchenrat hielt seine ordentliche Frühlingssession am 31. Mai 2024 im Schweizerischen Jugend- und Bildungszentrum SJBZ in Einsiedeln ab.`,
    sessionDate: '31.5.2024',
    resultPdfs: [
        {
            value: 'Protokoll der Session vom 31. Mai 2024',
            link: '/protocols/20240531/Sessionsprotokoll.pdf',
        },
        {
            value: 'Informationsschreiben vom 10. Juni 2024',
            link: '/protocols/20240531/Informationsschreiben.pdf',
        },
    ],
    shortResults: [
        {
            name: 'sr1',
            value: 'Der Rechenschaftsbericht 2023 der Rekurskommission wurde genehmigt.'
        },
        {
            name: 'sr2',
            value: 'Die Jahresrechnung 2023 und Bilanz per 31. Dezember 2023 wurden genehmigt.'
        },
        {
            name: 'sr3',
            value: 'Der Tätigkeitsbericht 2023 des Kantonalen Kirchenvorstandes wurde genehmigt.'
        },
        {
            name: 'sr4',
            value: 'Die Änderung des Gesetzes über die Organisation der Kirchgemeinden bezüglich der Wahlvoraussetzungen in den Kirchgemeinden wurde in einer abgeänderten Form beschlossen, so dass lediglich das Wohnsitzerfordernis wegfällt, nicht aber weitere Vorgaben geändert werden.'
        },
    ],
    Traktanden: [
        {
            name: 'tt1',
            value: 'Gebet, Begrüssung und Präsenz',
        },
        {
            name: 'tt2',
            value: 'Rechenschaftsbericht 2023 der Rekurskommission',
        },
        {
            name: 'tt3',
            value: 'Jahresrechnung 2023 und Bilanz per 31. Dezember 2023',
        },
        {
            name: 'tt4',
            value: 'Tätigkeitsbericht 2023 des Kantonalen Kirchenvorstandes',
        },
        {
            name: 'tt5',
            value: 'Änderung des Gesetzes über die Organisation der Kirchgemeinden bezüglich der Wahlvoraussetzungen in den Kirchgemeinden',
        },
        {
            name: 'tt6',
            value: 'Informationen der Ressortchefs und Fragestunde',
        },
        {
            name: 'tt7',
            value: 'Verschiedenes',
        },
    ],
    Unterlagen: [
        {
            value: "1 Sessionseinladung",
            link: "/protocols/20240531/1_Sessionseinladung.pdf"
        },
        {
            value: "2 Beilagenblatt",
            link: "/protocols/20240531/2_Beilagenblatt.pdf"
        },
        {
            value: "3 Rechenschaftsbericht 2023",
            link: "/protocols/20240531/3_Rechenschaftsbericht_2023.pdf"
        },
        {
            value: "4 GPK-Bericht",
            link: "/protocols/20240531/4_GPK-Bericht.pdf"
        },
        {
            value: "5 KGOG-Änderung",
            link: "/protocols/20240531/5_KGOG-Aenderung.pdf"
        },
        {
            value: "6 Verhaltenskodex 2024 - Einladung",
            link: "/protocols/20240531/6_Verhaltenskodex_2024_-_Einladung.pdf"
        },
        {
            value: "7 Voranzeige Strategietag 2024",
            link: "/protocols/20240531/7_Voranzeige_Strategietag_2024.pdf"
        },
    ]
}