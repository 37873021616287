export default {
    id: 2,
    type:"InformationWrting",
    name: 'b_u_a_gesetzte',
    title: 'Bericht und Antrag zur generellen Überprüfung der Gesetze der Röm.-kath. Kantonalkirche Schwyz',
    value: '',
    target: "InfoSite",
    Post: true,
    date: 20180213,
    files: {
        filesTitle: "Dateien",
        filesList: [
            {
                name: "Bericht und Antrag zur generellen Überprüfung der Gesetze der Röm.-kath. Kantonalkirche Schwyz",
                path: '/documents/b_u_a/ueberprüfung_der_gesetzte.pdf'
            },
        ]
    }
}