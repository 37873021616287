export default {
    id: 13,
    name: "20200101InformationWriting",
    type: "InformationWrting",
    title: 'Informationsschreiben und Besoldungsentscheide des Kantonalen Kirchenvorstandes per 1. Januar 2020',
    subtitle: '(Teuerungsausgleich/ Lohntabellen / Meldung Katholikenzahl)',
    value: 'Der Kantonale Kirchenvorstand beschloss: <br>' +
        '<br>' +
        'Die “Lohnklassen und Lohnstufen” gemäss Anhang 2 der Personal- und Besoldungsverordnung werden ' +
        'für das Jahr 2020 nicht angehoben, sondern bleiben unverändert gegenüber den Jahren 2011 - 2019 ' +
        '(auf den Indexstand von unverändert 104.2 Punkten, Basis Dezember 2005 = 100 Punkte). ' +
        'Indexstand 30. November 2018: 103.1',
    target: "InfoSite",
    Post: true,
    date: 20200101,
    files: {
        filesTitle: "Brief und Formulare:",
        filesList: [
            {
                name: "Informationsschreiben vom 11. Dezember 2019",
                path: '/documents/diverse_informationen/20191212202206.pdf'
            },
            {
                name: "Lohntabelle 2020 (pdf)",
                path: '/documents/diverse_informationen/20191212202621.pdf'
            },
            {
                name: "Lohntabelle 2020 (Excel-Tabelle)",
                path: '/documents/diverse_informationen/20191212202743.xlsx'
            },
            {
                name: "Meldung Katholikenzahl per 1. Januar 2020 (pdf)",
                path: '/documents/diverse_informationen/20191212202823.pdf'
            },
            {
                name: "Meldung Katholikenzahl per 1. Januar 2020 (Word)",
                path: '/documents/steuersaetze/20191212202955.docx'
            },
        ]
    }
}