export default {
    id: 11,
    type:"InformationWrting",
    name: 'Jubiläumsfeier_20_Jahre',
    title: 'Jubiläumsfeier 20 Jahre Römisch-katholische Kantonalkirche Schwyz',
    subtitle: 'Samstag, 16. November 2019, 18:00 Uhr',
    value: '<div class="information-writing-date">Samstag, 16. November 2019, 14.00 Uhr<br>' +
        'im Kloster Einsiedeln </div>',
    target: "InfoSite",
    Post: true,
    date: 20191116,
    files: {
        filesTitle: "Brief und Formulare::",
        filesList: [
            {
                name: "Einladung zur Jubiläumsfeier",
                path: '/documents/jubilaeumsfeier/einladung.pdf'
            },
            {
                name: "Entstehungsgeschichte und Entwicklung der Römisch-katholischen Kantonalkirche Schwyz " +
                    "in den vergangenen 20 Jahren, Referat von Dr. Linus Bruhin, " +
                    "Sekretär der Römisch-katholischen Kantonalkirche Schwyz",
                path: '/documents/jubilaeumsfeier/referat_dr_bruhin.pdf'
            },
            {
                name: "Ist die duale Struktur ein Zukunftsmodell? Referat lic.iur. Luc Humbel, Präsident RKZ",
                path: '/documents/jubilaeumsfeier/referat_lic_iur_humbel.pdf'
            },
            {
                name: "Das demokratische Schweizer Staatskirchenrecht, " +
                    "Prof. Dr. iur. can. et lic. theol. Adrian Loretan, Universität Luzern",
                path: '/documents/jubilaeumsfeier/prof_dr_loretan.pdf'
            },
        ]
    }
}