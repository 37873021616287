<template>
    <div>
        <div class="nav-item">
            <div class="subheading hover-bold" @click.stop="navPage(item.target)">
                {{item.title}}
            </div >
            <div>
                <v-icon v-if="!item.submenuActive"
                        @click.stop="toggleSubMenuActive(indexSubMenu)"
                        color="grey darken-2">
                    expand_more
                </v-icon>
                <v-icon v-else @click.stop="toggleSubMenuActive(indexSubMenu)"
                        color="grey darken-2">
                    expand_less
                </v-icon>
            </div>
        </div>
        <div class="sub-menu-grid">
            <div v-show="item.submenuActive" class="pa-0 ma-1"
                 v-for="(submenu, indexSubSubMenu) in item.submenus"
                 :key="indexSubSubMenu"
            >
                <div v-if="submenu.hasOwnProperty('subsubmenus')"
                     class=" grey--text text--darken-2 ma-1 pa-0 hover-bold">
                    <submenu-with-submenus
                            :submenu="submenu"
                            :indexSubSubMenu="indexSubSubMenu"
                            :index-sub-menu="indexSubMenu">
                    </submenu-with-submenus>
                </div>

                <div v-else
                     @click.stop="navPage(submenu.target)"
                     class="text-xs-left grey--text text--darken-2 ma-1 pa-0 hover-bold" >
                    <div> {{submenu.title}} </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import router from "../../router";
    import SubmenuWithSubmenus from "./SubmenuWithSubmenus";
    export default {
        name: "MobileMenuItem",
        props: ['item', 'indexSubMenu'],
        components: {
            SubmenuWithSubmenus
        },
        methods: {
            navPage (page) {
                router.push({path: page});
            },
            mouseOverMenu(menu) {
                this.menu[menu].submenuActive = true;
            },
            mouseLeaveMenu (menu) {
                this.menu[menu].submenuActive = false;
            },
            toggleSubMenuActive (menu) {
                this.menu[menu].submenuActive = !this.menu[menu].submenuActive;
            },
            toggleSearch () {
                this.searchState = !this.searchState
            }
        },
        computed: {
            menu () {
                return this.$store.getters.menu
            },
        }
    }
</script>

<style scoped>

</style>