<template>
    <div class="menu-point">
        <div v-if="item.hasOwnProperty('submenus')" class="submenu-point link"
             @mouseleave="mouseLeaveMenu(index)"
             @click.stop="navPage(item.target)">
            <desktop-submenu-item :item="item" />
        </div>

        <div v-else
             @click.stop="navPage(item.target)"
             class="link"
        >
            <v-flex class="menu-point-font">
                {{item.title}}
            </v-flex>
        </div>
    </div>
</template>

<script>
    import router from '../../router';
    import DesktopSubmenuItem from "./DesktopSubmenuItem";
    export default {
        name: "MainNavItem",
        props: [
            'item',
            'index'
        ],
        components: {
            DesktopSubmenuItem
        },
        methods: {
            navPage (page) {
                router.push({path: page});
            },
            mouseOverMenu(menu) {
                this.menu[menu].submenuActive = true;
            },
            mouseLeaveMenu (menu) {
                this.menu[menu].submenuActive = false;
            },
            toggleSubMenuActive (menu) {
                this.menu[menu].submenuActive = !this.menu[menu].submenuActive;
            },
            SubSubMenuActive (menu, submenu) {
                this.menu[menu].submenus[submenu].subsubmenuActive = true;
            },
            SubSubMenuNotActive (menu, submenu) {
                this.menu[menu].submenus[submenu].subsubmenuActive = false;
            },
            toggleSubSubMenuActive (menu, submenu) {
                this.menu[menu].submenus[submenu].subsubmenuActive = !this.menu[menu].submenus[submenu].subsubmenuActive;
            },
            toggleSearch () {
                this.searchState = !this.searchState
            }
        },
        computed: {
            searchTexts() {
                let searchText = [];
                for (let langKey in this.$store.getters.text) {
                    if (this.$store.getters.text.hasOwnProperty(langKey)) {
                        searchText.push(this.$store.getters.text[langKey].value)
                    }
                }
                return searchText
            },
            menu () {
                return this.$store.getters.menu
            }

        }
    }
</script>

<style scoped>

</style>