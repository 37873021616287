export default {
    id: 18,
    name: "20200525InformationWriting",
    type: "InformationWrting",
    title: 'Information zur Session des Kantonskirchenrates vom 29. Mai 2020',
    subtitle: 'Verschiebung des Sessionsortes und der Anfangszeit ',
    value: 'Die Session des Kantonskirchenrats vom 29. Mai 2020  ' +
        'wird in das "Kultur- und Kongresszentrum ZWEI RABEN" ' +
        'an der Hauptstrasse 20 in 8840 Einsiedeln verschoben werden (ehemaliges "Dorfzentrum"). ' +
        'Auch wird sie ausnahmsweise erst um 15.00 Uhr beginnen.',
    target: "InfoSite",
    Post: true,
    date: 20200525,
    files: {
        filesTitle: "Dateien:",
        filesList: [
            {
                name: "Schreiben",
                path: '/protocols/20200504/Schreiben.pdf'
            },
        ]
    }
}
