export default {
    kirchenvorstand:{
        Target: '/Organisation/Kirchenvorstand',
        VorstandPersonen: [
            {
                foto: '/img/boesch.jpg',
                Name: 'Lorenz Bösch',
                funktion: 'Präsident',
                adresse: {
                    PLZ: '6440',
                    Ort: 'Ingenbohl',
                    Strasse: 'Klosterstrasse',
                    Nr: '7',
                },
                tel: '+41 79 426 54 19',
                eMail: 'lorenz.boesch@sz.kath.ch',
                Details: 'www.kath.ch/adressverzeichnis/?pers_id=2015'

            },
            {
                foto: '/img/Birchler-Staebler.jpeg',
                Name: 'Karin Birchler-Stäbler',
                funktion: 'Ressort Finanzen',
                adresse: {
                    PLZ: '8845',
                    Ort: 'Studen',
                    Strasse: 'Riedweg',
                    Nr: '1',
                },
                tel: '+41 55 414 30 40',
                eMail: 'karin.birchler@sz.kath.ch',
                Details: 'www.kath.ch/adressverzeichnis/?pers_id=6548'
            },
            {
                foto: '/img/Bachmann.jpeg',
                Name: 'Vreni Bürgi-Bachmann',
                funktion: 'Ressort Bildung',
                adresse: {
                    PLZ: '8832',
                    Ort: 'Wollerau',
                    Strasse: 'Rebbergstrasse',
                    Nr: '49',
                },
                tel: ' +41 78 817 42 34',
                eMail: 'vreni.buergi@sz.kath.ch',
                Details: 'www.kath.ch/adressverzeichnis/?pers_id=3759'
            },
            {
                foto: '/img/adrian_mueller.png',
                Name: 'Dr. Adrian Müller',
                funktion: 'Ressort Seelsorge',
                adresse: {
                    PLZ: '6430',
                    Ort: 'Schwyz',
                    Strasse: 'Herrengasse',
                    Nr: '33',
                },
                tel: '+41 79 395 12 03',
                eMail: 'adrian.mueller@sz.kath.ch',
                Details: 'www.kath.ch/adressverzeichnis/?pers_id=737'
            },
            {
                foto: '/img/reichmuth.jpg',
                Name: 'Othmar Reichmuth',
                funktion: 'Ressort Rechtswesen',
                adresse: {
                    PLZ: '6434',
                    Ort: 'Illgau',
                    Strasse: 'Buoflen',
                    Nr: '',
                },
                tel: '+41 79 248 98 65',
                eMail: 'othmar.reichmuth@sz.kath.ch',
                Details: ''
            },
        ]
    }
}