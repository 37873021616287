export default {
    id: 21,
    name: "20201216InformationWriting",
    type: "InformationWrting",
    title: 'Informationsschreiben und Besoldungsentscheide des Kantonalen' +
    'Kirchenvorstandes per 1. Januar 2020 (Teuerungsausgleich/ Lohntabellen /' +
    'Meldung Katholikenzahl)',
    subtitle: '(Teuerungsausgleich/ Lohntabellen / Meldung Katholikenzahl)',
    value: 'Der Kantonale Kirchenvorstand beschloss: <br>' +
        '<br>' +
        'Die “Lohnklassen und Lohnstufen” gemäss Anhang 2 der Personal- und' +
        'Besoldungsverordnung werden für das Jahr 2021 nicht angehoben, sondern' +
        'bleiben unverändert gegenüber den Jahren 2011 - 2020 (auf den Indexstand' +
        'von unverändert 104.2 Punkten, Basis Dezember 2005 = 100 Punkte).' +
        'Indexstand 30. November 2020: 102.4',
    target: "InfoSite",
    Post: true,
    date: 20201216,
    files: {
        filesTitle: "Brief und Formulare:",
        filesList: [
            {
                name: "Informationsschreiben vom 14. Dezember 2020",
                path: '/documents/info_writing_for_20201216/Brief_Kirchgemeinden.pdf'
            },
            {
                name: "Lohntabelle 2020",
                path: '/documents/info_writing_for_20201216/Lohntabelle.pdf'
            },
            {
                name: "Katholikenzahlmeldungen",
                path: '/documents/info_writing_for_20201216/Katholikenzahlmeldung.pdf'
            },
        ]
    }
}