<template>
    <div class="text-xs-center link" @click.stop="navPage('/Dokumente/Archiv')">
        {{aktuellesNote[0]['value']}}
    </div>
</template>

<script>
    import router from '../router';
    export default {
        name: "archiv_note",
        methods: {
            navPage(page) {
                router.push({path: page});
            },
        },
        computed:{
            aktuellesNote() {
                let aktuellesNote = this.$store.getters.texts.filter(text => text.name === 'aktuellesNote');
                return aktuellesNote
            }
        }
    }
</script>

<style scoped>

</style>