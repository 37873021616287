export default {
    id: 20220422,
    date: 20220422,
    uploadDate: '22.4.2022',
    title: 'Session des Kantonskirchenrates vom 20. Mai 2022',
    shortDescriptionString: `Ergebnisse in Kurzform / Traktanden /Sessionseinladung und Unterlagen`,
    subtitle: `Der Kantonskirchenrat hielt seine ordentliche Frühlingssession am 20. Mai 2022 im Schweizerischen Jugend- und Bildungszentrum SJBZ in Einsiedeln ab.`,
    sessionDate: '20.5.2022',    
    resultPdfs: [
        {
            value: 'Protokoll der Session vom 20. Mai 2022',
            link: '/protocols/20220520/6_Sessionsprotokoll.pdf',
        },
        {
            value: 'Informationsschreiben vom 15. Juni 2022',
            link: '/protocols/20220520/7_Informationsschreiben.pdf',
        },
    ],
    shortResults: [
        {
            name: 'sr1',
            value: 'Der Rechenschaftsbericht 2021 der Rekurskommission wurde genehmigt.'
        },
        {
            name: 'sr2',
            value: 'Die Jahresrechnung 2021 und Bilanz per 31. Dezember 2021 wurden genehmigt.'
        },
        {
            name: 'sr3',
            value: 'Der Tätigkeitsbericht 2021 des Kantonalen Kirchenvorstandes wurde genehmigt.'
        },
        {
            name: 'sr4',
            value: 'Es wurde Kenntnis vom Wirksamkeitsbericht “Finanzausgleich 2013 - 2022” genommen.t'
        },
    ],
    Traktanden: [
        {
            name: 'tt1',
            value: 'Gebet, Begrüssung und Präsenz',
        },
        {
            name: 'tt2',
            value: 'Beschluss über die Gültigkeit der Ersatzwahlen in den Kantonskirchenrat in der Kirchgemeinde Freienbach und Vereidigung eines neuen Mitglieds',
        },
        {
            name: 'tt3',
            value: 'Rechenschaftsbericht 2021 der Rekurskommission',
        },
        {
            name: 'tt4',
            value: 'Jahresrechnung 2021 und Bilanz per 31. Dezember 2021',
        },
        {
            name: 'tt5',
            value: 'Tätigkeitsbericht 2021 des Kantonalen Kirchenvorstandes',
        },
        {
            name: 'tt6',
            value: 'Kenntnisnahme des Wirksamkeitsberichts “Finanzausgleich 2013 - 2022”',
        },
        {
            name: 'tt7',
            value: 'Informationen der Ressortchefs und Fragestunde',
        },
        {
            name: 'tt8',
            value: 'Verschiedenes',
        },
    ],
    Unterlagen: [
        {
            value: 'Sessionseinladung',
            link: '/protocols/20220520/1_Sessionseinladung.pdf',
        },
        {
            value: 'Beilagenblatt',
            link: '/protocols/20220520/2_Beilagenblatt.pdf',
        },
        {
            value: 'Rechenschaftsbericht 2021',
            link: '/protocols/20220520/3_Rechenschaftsbericht_2021.pdf',
        },
        {
            value: 'GPK Antrag',
            link: '/protocols/20220520/4_GPK-Antrag.pdf',
        },
        {
            value: 'Wirksamkeitsbericht Finanzausgleich 2022',
            link: '/protocols/20220520/5_Wirksamkeitsbericht_Finanzausgleich_2022.pdf',
        }
    ]
}