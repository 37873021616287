export default {
    id: 20160701,
    date: 20160701,
    title: 'Konstituierende Session des Kantonskirchenrates vom 1. Juli 2016',
    shortDescriptionString: 'Sessionsprotokoll / Ergebnisse der Wahlen / Informationsschreiben / ' +
        'Unterlagen zur Session',
    subtitle: 'Am 1. Juli 2016, fand im Schweizerischen Jugend- und Bildungszentrum in Einsiedeln die ' +
        'konstituierende Session des neuen Kantonskirchenrates statt.',
    sessionDate: '21.10.2016',
    resultPdfs: [
        {
            value: 'Protokoll der 2., konstituierenden Session 2016 des Kantonskirchenrates vom 1. Juli 2016',
            link: '/protocols/20160701/20160701_protokoll.pdf',
        },
        {
            value: 'Wahlen anlässlich der konstituierenden Session des Kantonskirchenrats vom 1. Juli 2016',
            link: '/protocols/20160701/20160701_wahlen.pdf',
        },
        {
            value: 'Informationsschreiben vom 18. Juli 2016',
            link: '/protocols/20160701/20160701_infoschreiben.pdf',
        },
    ],
    Traktanden: [
        {
            name: 'tt1',
            value: 'Teil1: Vorsitz Alterspräsident',
            unterTraktanden: [
                {
                    name: 'utt1',
                    prefix: '1',
                    value: 'Begrüssung und Präsenz',
                },
                {
                    name: 'utt2',
                    prefix: '2',
                    value: 'Bezeichnung von provisorischen Stimmenzählern',
                },
                {
                    name: 'utt3',
                    prefix: '3',
                    value: 'Beschluss über die Gültigkeit der Wahl der Mitglieder des Kantonskirchenrats',
                },
                {
                    name: 'utt4',
                    prefix: '4',
                    value: 'Wahl der Präsidentin bzw. des Präsidenten des Kantonskirchenrat',
                },
            ]
        },
        {
            name: 'tt2',
            value: 'Teil II: Vorsitz Präsidentin bzw. Präsident des Kantonskirchenrats',
            unterTraktanden: [
                {
                    name: 'utt1',
                    prefix: '5',
                    value: 'Gottesdienst, samt Vereidigung der Mitglieder des Kantonskirchenrats ' +
                        '(Amtseid oder Amtsgelübde)',
                },
                {
                    name: 'utt2',
                    prefix: '6',
                    value: 'Wahlen in das Büro des Kantonskirchenrats:',
                    unterUnterTraktanden: [
                        {
                            name: 'uutt1',
                            prefix: 'a)',
                            value: 'Wahl der Vizepräsidentin bzw. des Vizepräsidenten des Kantonskirchenrates',
                        },
                        {
                            name: 'uutt2',
                            prefix: 'b)',
                            value: 'Wahl von zwei Stimmenzählerinnen bzw. Stimmenzählern',
                        },
                    ]
                },
                {
                    name: 'utt3',
                    prefix: '7',
                    value: 'Wahl der Sekretärin bzw. des Sekretärs des Kantonskirchenrates',
                },
                {
                    name: 'utt4',
                    prefix: '8',
                    value: ' Wahl des Kantonalen Kirchenvorstandes:',
                    unterUnterTraktanden: [
                        {
                            name: 'uutt1',
                            prefix: 'a)',
                            value: 'Wahl der Präsidentin bzw. des Präsidenten des Kantonalen Kirchenvorstandes',
                        },
                        {
                            name: 'uutt2',
                            prefix: 'b)',
                            value: 'Wahl der bzw. des Finanzverantwortlichen des Kantonalen Kirchenvorstandes',
                        },
                        {
                            name: 'uutt2',
                            prefix: 'c)',
                            value: 'Wahl der drei Mitglieder des Kantonalen Kirchenvorstandes',
                        },
                    ]
                },
                {
                    name: 'utt3',
                    prefix: '9',
                    value: 'Vereidigung der Mitglieder des Kantonalen Kirchenvorstandes (Amtseid oder Amtsgelübde)',
                },
                {
                    name: 'utt3',
                    prefix: '10',
                    value: 'Wahl der Sekretärin bzw. des Sekretärs des Kantonalen Kirchenvorstandes',
                },
                {
                    name: 'utt2',
                    prefix: '11',
                    value: 'Wahlen in das Büro des Kantonskirchenrats:',
                    unterUnterTraktanden: [
                        {
                            name: 'uutt1',
                            prefix: 'a)',
                            value: 'Wahl der Präsidentin bzw. des Präsidenten der Rekurskommission',
                        },
                        {
                            name: 'uutt2',
                            prefix: 'b)',
                            value: 'Wahl der zwei Mitglieder und der zwei Ersatzmitglieder der Rekurskommission',
                        },
                    ],
                },
                {
                    name: 'utt3',
                    prefix: '12',
                    value: 'Wahl der Geschäftsprüfungskommission mit einer Präsidentin bzw. ' +
                        'einem Präsidenten und acht Mitgliedern',
                },
                {
                    name: 'utt3',
                    prefix: '13',
                    value: 'Wahl einer vorberatenden Kommission für die Übernahme der Organisation der ' +
                        'Anderssprachigenseelsorge durch die Kantonalkirche',
                },
                {
                    name: 'utt3',
                    prefix: '13',
                    value: 'Informationen der Ressortchefs und Fragestunde',
                },
                {
                    name: 'utt3',
                    prefix: '14',
                    value: 'Verschiedenes',
                },
            ]
        },
    ],
    Unterlagen: [
        {
            value: 'Sessionseinladung',
            link: '/protocols/20160701/Sessionseinladung.pdf',
        },
        {
            value: 'Beilagenblatt',
            link: '/protocols/20160701/Beilagenblatt.pdf',
        },
        {
            value: 'Kantonskirchenrat in der 5. Legislatur 2016 - 2020',
            link: '/protocols/20160701/kkr5l_2016_2020.pdf',
        },
        {
            value: 'Antrag GPK Gesamterneuerungswahl Kantonskirchenrat',
            link: '/protocols/20160701/a_GPK.pdf',
        },
        {
            value: 'Wahlvorschläge',
            link: '/protocols/20160701/wv.pdf',
        },
        {
            value: 'Bericht und Antrag für die Übernahme der Organisation ' +
                'der Anderssprachigenseelsorge durch die Kantonalkirche',
            link: '/protocols/20160701/b_u_a.pdf',
        },
    ]
}