export default {
    id: 20230927,
    date: 20230927,
    uploadDate: '27.9.2022',
    title: 'Session des Kantonskirchenrates vom 20. Oktober 2023',
    shortDescriptionString: `Sessionsprotokoll / Informationsschreiben / Ergebnisse in Kurzform / Traktanden / Sessionseinladung und Unterlagen`,
    subtitle: `Der Kantonskirchenrat hielt seine ordentliche Herbstsession am 20. Oktober 2023 im Schweizerischen Jugend- und Bildungszentrum SJBZ in Einsiedeln ab.`,
    sessionDate: '22.10.2023',
    resultPdfs: [
        {
            value: 'Protokoll der Session vom 20. Oktober 2023',
            link: '/protocols/20231020/12_Sessionsprotokoll.pdf',
        },
        {
            value: 'Informationsschreiben vom 10. November 2023',
            link: '/protocols/20231020/13_Schreiben.pdf',
        },
    ],
    shortResults: [
        {
            name: 'sr1',
            value: 'Der Voranschlag 2024 mit einem Total des Aufwandes von Fr. 2’314’920.50 und dem Pro-Kopf-Beitrag an die Kantonalkirche von gesamthaft Fr. 25.-- wurde gemäss dem unveränderten Antrag des Kantonalen Kirchenvorstandes beschlossen.'
        },
        {
            name: 'sr2',
            value: 'Der Finanzausgleich 2024 wurde gemäss dem unveränderten Antrag mit einem Ausgleich des Normleistungsaufwandes von Fr. 290.80 (d.h. einem Ausgleich der Normkosten zu 92.5%) und mit einem Gesamtbudget von Fr. 1’323’403.-- beschlossen.'
        },
        {
            name: 'sr3',
            value: 'Im Personal- und Besoldungsrecht der Röm.-kath. Kantonalkirche Schwyz werden die Bezeichnungen von “Pastoralassistent” in “Seelsorger” und von “Gemeindeleiter” in “Pfarreibeauftragter” geändert, wie das vom bischöflichen Ordinariat Chur bereits beschlossen wurde. Dieser Beschluss unterliegt dem fakultativen Referendum.'
        },
    ],
    Traktanden: [
        {
            name: 'tt1',
            value: 'Gebet, Begrüssung und Präsenz',
        },
        {
            name: 'tt3',
            value: 'Beschluss über den Voranschlag 2024',
        },
        {
            name: 'tt4',
            value: 'Beschluss über den Finanzausgleich 2024',
        },
        {
            name: 'tt5',
            value: 'Beschlüsse über die Änderungen im Personal- und Besoldungsrecht der Röm.-kath. Kantonalkirche Schwyz',
        },
        {
            name: 'tt6',
            value: 'Informationen der Ressortchefs und Fragestunde',
        },
        {
            name: 'tt7',
            value: 'Verschiedenes',
        },
    ],
    Unterlagen: [
        {
            value: "1 Sessionseinladung",
            link: "/protocols/20231020/1_Sessionseinladung.pdf"
        },
        {
            value: "2 Beilagenblatt",
            link: "/protocols/20231020/2_Beilagenblatt.pdf"
        },
        {
            value: "3 Antrag Voranschlag 2024",
            link: "/protocols/20231020/3_Antrag_Voranschlag_2024.pdf"
        },
        {
            value: "4 KAS-Voranschlag 2024",
            link: "/protocols/20231020/4_KAS-Voranschlag_2024.pdf"
        },
        {
            value: "5 ASS-Voranschlag 2024",
            link: "/protocols/20231020/5_ASS-Voranschlag_2024.pdf"
        },
        {
            value: "6 Bericht zum Voranschlag 2024",
            link: "/protocols/20231020/6_Bericht_zum_Voranschlag_2024.pdf"
        },
        {
            value: "7 Finanzplanung 2024-2027",
            link: "/protocols/20231020/7_Finanzplanung_2024-2027.pdf"
        },
        {
            value: "8 GPK-Antrag Budget 2024",
            link: "/protocols/20231020/8_GPK-Antrag_Budget_2024.pdf"
        },
        {
            value: "9 Antrag Finanzausgleich 2024 mit Korrektur Seite 14",
            link: "/protocols/20231020/9_Antrag_Finanzausgleich_2024.pdf"
        },

        {
            value: "10 Finanzausgleich 2018-2024 Varianten",
            link: "/protocols/20231020/10_Finanzausgleich_2018-2024_Varianten.pdf"
        },
        {
            value: "11 Änderungen Personalrecht",
            link: "/protocols/20231020/11_Aenderungen_Personalrecht.pdf"
        },
    ]
}