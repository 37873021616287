export default {
    SearchTextData : function (searchPoolRaw) {
        this.searchPoolRaw = searchPoolRaw;

        this.texts = function () {
            let normalizedForSearchTexts = [];
            this.searchPoolRaw.texts.forEach((text) => {

                if (text.hasOwnProperty('title')) {
                    normalizedForSearchTexts.push({
                        id: text.id,
                        value: text.title,
                        target: text.target,
                    });
                }
                if (text.hasOwnProperty('subtitle')) {
                    normalizedForSearchTexts.push({
                        id: text.id,
                        value: text.subtitle,
                        target: text.target,
                    });
                }
                if (text.hasOwnProperty('value')) {
                    normalizedForSearchTexts.push({
                        id: text.id,
                        value: text.value,
                        target: text.target,
                    });
                }

            });

            return normalizedForSearchTexts //
        };

        this.registeredMembers = function () {
            let normalizedForSearchLocations = [];
            this.searchPoolRaw.locations.forEach(function (location) {
                //helper function
                function registeredMembersValue() {
                    let searchString = "";
                    location.mitglieder.forEach(function (mitglied) {
                            searchString += mitglied.name + " " + mitglied.year.toString(10) + " "
                                + mitglied.profession + " "
                                + mitglied.location + " "
                                + mitglied.PLZ + " "
                                + mitglied.orignal_location + " "
                                + mitglied.adress + " "
                                + mitglied.stimmengewicht.toString(10)
                                + " ";
                        }
                    );
                    return searchString;
                }

                let value = registeredMembersValue();

                normalizedForSearchLocations.push({
                    value: value,
                    target: "/Organisation/Kantonskirchenrat",
                })
            });
            return normalizedForSearchLocations
        };
        /**
         * @return {string}
         */
        this.protocolValue = function (listToSearch) {
            let searchString = "";
            listToSearch.forEach(listEntry => {
                searchString += listEntry.value + " "
            });
            return searchString;
        };
        this.protocols = function () {
            let normalizedforSearchProtocols = [];
            this.searchPoolRaw.Protokolle.SessionProtokolle.forEach(proto => {
                let value = "";

                if(proto.hasOwnProperty('sessionDate')) {
                    value += proto.sessionDate + " ";
                }
                if(proto.hasOwnProperty('title')) {
                    value += proto.title + " ";
                }
                if(proto.hasOwnProperty('subtitle')) {
                    value += proto.subtitle + " ";
                }
                if(proto.hasOwnProperty('resultPdfs')) {
                    value += this.protocolValue(proto.resultPdfs);
                }
                if(proto.hasOwnProperty('shortResults')) {
                    value += this.protocolValue(proto.shortResults);
                }
                if(proto.hasOwnProperty('Traktanden')) {
                    value += this.protocolValue(proto.Traktanden);
                }
                if(proto.hasOwnProperty('Unterlagen')) {
                    value += this.protocolValue(proto.Unterlagen);
                }

                normalizedforSearchProtocols.push({
                    value: value,
                    target: this.searchPoolRaw.Protokolle.target,
                    id: proto.id,
                })
            });

            return normalizedforSearchProtocols
        };
        this.pfarreien = function () {
            let normalizedforSearchPfarreien = [];
            this.searchPoolRaw.Pfarreien.DekanatBereiche.forEach(bereich => {
                //helper function
                /**
                 * @return {string}
                 */
                function BereichValue(listToSearch) {
                    let searchString = "";
                    listToSearch.forEach(listEntry => {
                        searchString += listEntry.Ort + " ";
                        searchString += listEntry.Pfarramt + " ";
                        searchString += listEntry.Strasse + " ";
                        searchString += listEntry.PLZ + " ";
                        searchString += listEntry.phone + " ";
                        searchString += listEntry.Fax + " ";
                        searchString += listEntry.email + " ";
                        searchString += listEntry.website + " ";
                        searchString += listEntry.Details + " ";
                        searchString += listEntry.expanded + " ";

                    });
                    return searchString
                }

                let value = bereich.name + " ";
                value += BereichValue(bereich.Dekanate);

                normalizedforSearchPfarreien.push({
                    value: value,
                    target: '/Kirchgemeinden/Pfarreien',
                })
            });

            return normalizedforSearchPfarreien
        };
        this.rechenschaftsberichte = function () {
            let normalizedForSearchRechenschaftsberichte = [];
            let searchstring = this.searchPoolRaw.rechenschaftsberichte.title;
            this.searchPoolRaw.rechenschaftsberichte.berichte.forEach(bericht => {
                searchstring += " " + bericht.year
            });
            normalizedForSearchRechenschaftsberichte.push({
                value: searchstring,
                target: '/Organisation/Kirchenvorstand',
            });
            return normalizedForSearchRechenschaftsberichte
        };
        this.KirchenVorstand = function () {
            let normalizedforSearchKirchenVorstand = [];

            this.searchPoolRaw.Kirchenvorstand.VorstandPersonen.forEach(person => {

                let value = person.Name + "<br>";
                value += person.funktion + "<br>";
                value += person.tel + "<br>";
                value += person.eMail + "<br>";
                value += person.Details + "<br>";
                value += person.adresse.PLZ + "<br>";
                value += person.adresse.Ort + "<br>";
                value += person.adresse.Strasse + "<br>";
                value += person.adresse.Nr + "<br>";

                normalizedforSearchKirchenVorstand.push({
                    value: value,
                    target: '/Organisation/Kirchenvorstand',
                })
            });

            return normalizedforSearchKirchenVorstand
        };
        this.Menu = function () {
            let normalizedforSearchMenu = [];

            //helper function
            function subs(listToSearch) {
                let subsNormalized = [];
                listToSearch.forEach(listEntry => {

                    subsNormalized.push({
                        value: listEntry.title,
                        target: listEntry.target,
                    })
                });
                return subsNormalized
            }


            this.searchPoolRaw.menu.forEach(menu => {

                normalizedforSearchMenu.push({
                    value: menu.title,
                    target: menu.target,
                });

                if (menu.submenus) {
                    normalizedforSearchMenu = normalizedforSearchMenu.concat(subs(menu.submenus));
                    menu.submenus.forEach(submenu => {
                        if (submenu.subsubmenus) {
                            normalizedforSearchMenu = normalizedforSearchMenu.concat(subs(submenu.subsubmenus));
                        }
                    })
                }


            });

            return normalizedforSearchMenu
        };
        this.documents = function(){
            let normalizedForSearchRechtssammlung = [];
            this.searchPoolRaw.documents.forEach( document => {
                normalizedForSearchRechtssammlung.push({
                    value: document.description,
                    target: '/Dokumente/'
                })
            });
            return normalizedForSearchRechtssammlung;
        }
    }
}