<template>
    <div class="">
        <div>
            <v-img
                    :src="titleImage"
                    class="home-title-image"
            >
                <div class="pb-5">
                    <h1 v-html="lang['homeText']" class="home-title ">
                    </h1>
                </div>
            </v-img>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home',
    data() {
        return {
            titleImage: {
                src: '/img/kloster.png'
            },
        }
    },
    computed:{
        lang() {
            let HomeLangs = {};
            this.$store.getters.texts.forEach(function(langObj){
                if (langObj.target === '/') {
                    //set the name to the value. if a name already exits it gets overwritten.
                    HomeLangs[langObj.name] = langObj.value
                }
            });
            return HomeLangs
        }
    }
}
</script>
<style>
    .carousel {
        height: 200px !important;
    }
</style>


