export default {
    menu: [
        {
            title: 'Startseite',
            target: '/'
        },
        {
            title: 'Organisation',
            target: '/Organisation',
            submenus: [
                {
                    title: 'Sekretariat',
                    target: '/Organisation/Sekretariat'
                },
                {
                    title: 'Kantonskirchenrat',
                    target: '/Organisation/Kantonskirchenrat'
                },
                {
                    title: 'Kirchenvorstand',
                    target: '/Organisation/Kirchenvorstand'
                },
                {
                    title: 'Rekurskommission',
                    target: '/Organisation/Rekurskommission'
                },

            ],
            submenuActive: false
        },
        {
            title: 'Dokumente',
            target: '/Dokumente',
            submenus:[
                {
                    title: 'Rechtssammlung',
                    target: '/Dokumente/Rechtssammlung'
                },
                {
                    title: 'Mustervorlagen',
                    target: '/Dokumente/Mustervorlagen'
                },
                {
                    title: 'Terminliste',
                    target: '/Dokumente/Terminliste'
                },
                {
                    title: 'Rechenschaftsberichte',
                    target: '/Dokumente/Rechenschaftsberichte'
                },
                {
                    title: 'Sessionsprotokolle',
                    target: '/Organisation/Kantonskirchenrat/Protokolle'
                },
                {
                    title: 'Finanzausgleich',
                    target: '/Dokumente/Finanzausgleich'
                },
            ],
            submenuActive: false
        },
        {
            title: 'Kirchgemeinden',
            target: '/Kirchgemeinden',
            submenus: [
                {

                    title: 'Präsidentinnen und Präsidenten',
                    target: '/Kirchgemeinden/PraesidentInnen'

                },
                {
                    title: 'Pfarreien',
                    target: '/Kirchgemeinden/Pfarreien',

                },
            ],
            submenuActive: false
        },
        {
            title: 'Links',
            target: '/Links'
        },
        {
            title: 'Kontakt',
            target: '/Contact'
        },

    ]
}