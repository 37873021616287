export default {
    id: 20190524,
    date: 20190524,
    uploadDate: '24.05.2019',
    title: 'Session des Kantonskirchenrates vom 24. Mai 2019   ',
    shortDescriptionString: 'Sessionsprotokoll / Informationsschreiben / ' +
        'Ergebnisse in Kurzform / Traktanden /' +
        'Sessionseinladung und Unterlagen',
    subtitle: 'Der Kantonskirchenrat hielt seine ordentliche Frühjahrssession am 24. Mai 2019 im SJBZ in Einsiedeln.',
    sessionDate: '07.07.2019',
    resultPdfs: [
        {
            value: 'Protokoll der Session vom 24. Mai 2019',
            link: '/protocols/20190524/protokoll.pdf',
        },
        {
            value: 'Informationsschreiben vom 8. November 2019',
            link: '/protocols/20190524/informationsschreiben.pdf',
        },
    ],
    shortResults: [
        {
            name: 'sr1',
            value: 'der Rechenschaftsbericht 2018 der Rekurskommission wurde genehmigt.'
        },
        {
            name: 'sr2',
            value: 'die Jahresrechnung 2018 und Bilanz per 31. Dezember 2018 wurden genehmigt.'
        },
        {
            name: 'sr3',
            value: 'der Tätigkeitsbericht 2018 des Kantonalen Kirchenvorstandes wurde genehmigt.'
        },
        {
            name: 'sr4',
            value: 'der Nachkredit von Fr. 30’000.-- für die Referendumsabstimmung zum RKZ-Beitritt wurde gewährt.'
        },
        {
            name: 'sr5',
            value: 'der Nachkredit von Fr. 10’000.-- für einen Jubiläumsanlass ' +
                '20 Jahre Röm.-kath. Kantonalkirche Schwyz wurde gewährt.'
        },
        {
            name: 'sr6',
            value: 'das Gesetz über die Katechetische Arbeitsstelle der Röm.-kath. Kantonalkirche Schwyz ' +
                'wurde gemäss dem Antrag des Kantonalen Kirchenvorstandes erlassen.'
        },
        {
            name: 'sr7',
            value: 'der Beschluss über die Gesetzesänderungen gemäss der Überprüfung der Gesetze ' +
                'der Röm.-kath. Kantonalkirche Schwyz wurde gemäss dem Antrag des Kantonalen Kirchenvorstandes ' +
                'und mit den von der vorberatenden Kommission beantragten Änderungen gefasst ' +
                '(mit einer kleinen klarstellenden Änderung).'
        },
        {
            name: 'sr8',
            value: 'die Motion betreffend “Schaffung eines Stimm- und Wahlrechts ' +
                'für katholische Ausländerinnen und Ausländer für die katholische Kirche im Kanton Schwyz” ' +
                'wurde für erheblich erklärt.'
        },
        {
            name: 'sr9',
            value: 'bei der Ersatzwahl des Vizepräsidiums des Kantonskirchenrats wurde ' +
                'Johannes Schwimmer (Kirchgemeinde Schwyz) zum neuen Vizepräsidenten des Kantonskirchenrats gewählt.'
        },
    ],
    Traktanden: [
        {
            name: 'tt1',
            value: 'Gebet, Begrüssung und Präsenz',
        },
        {
            name: 'tt2',
            value: 'Rechenschaftsbericht 2018 der Rekurskommission',
        },
        {
            name: 'tt3',
            value: 'Jahresrechnung 2018 und Bilanz per 31. Dezember 2018',
        },
        {
            name: 'tt4',
            value: 'Tätigkeitsbericht 2018 des Kantonalen Kirchenvorstandes',
        },
        {
            name: 'tt5',
            value: 'Nachkredit 2019 für die Referendumsabstimmung zum RKZ-Beitritt',
        },
        {
            name: 'tt6',
            value: 'Nachkredit 2019 für einen Jubiläumsanlass 20 Jahre Röm.-kath. Kantonalkirche Schwyz',
        },
        {
            name: 'tt7',
            value: 'Beschluss über das Gesetz über die Katechetische Arbeitsstelle ' +
                'der Röm.-kath. Kantonalkirche Schwyz',
        },
        {
            name: 'tt8',
            value: 'Beschluss über die Gesetzesänderungen gemäss der Überprüfung der Gesetze ' +
                'der Röm.-kath. Kantonalkirche Schwyz',
        },
        {
            name: 'tt9',
            value: 'Beantwortung der Motion M 1-2018 betreffend “Schaffung eines Stimm- und Wahlrechts ' +
                'für katholische Ausländerinnen und Ausländer für die katholische Kirche im Kanton Schwyz”',
        },
        {
            name: 'tt10',
            value: 'Ersatzwahl des Vizepräsidiums des Kantonskirchenrats',
        },
        {
            name: 'tt11',
            value: 'Informationen der Ressortchefs und Fragestunde',
        },
        {
            name: 'tt12',
            value: 'Verschiedenes',
        },
    ],
    Unterlagen: [
        {
            value: 'Sessionseinladung',
            link: '/protocols/20190524/sessionseinladung.pdf',
        },
        {
            value: 'Beilagenblatt',
            link: '/protocols/20190524/beilagenblatt.pdf',
        },
        {
            value: 'Rechenschaftsbericht 2018',
            link: '/rechenschaftsberichte/2018.pdf',
        },
        {
            value: 'Bericht und Antrag der GPK an den Kantonskirchenrat',
            link: '/protocols/20190524/bericht_und_antrag.pdf',
        },
        {
            value: 'Nachkredit RKZ-Abstimmung',
            link: '/protocols/20190524/nk_rkz.pdf',
        },
        {
            value: 'Nachkredit Jubiläumsanlass 20 Jahre Röm.-kath. Kantonalkirche Schwyz',
            link: '/protocols/20190524/nk_jubilaeum.pdf',
        },
        {
            value: 'Stellungnahme des Kirchenvorstands zur KAS-Übernahme',
            link: '/protocols/20190524/stellungsnahme_kas.pdf',
        },
        {
            value: 'Bericht der Kommission zur KAS-Übernahme',
            link: '/protocols/20190524/bericht_kas.pdf',
        },
        {
            value: 'Überarbeitung Rechtssammlung 2019 - Bericht und Antrag an KKR 2019-03-31',
            link: '/protocols/20190524/ueberarbeitung_rechtssammlung_b_und_a.pdf',
        },
        {
            value: 'Überarbeitung Rechtssammlung 2019 - Synopse Anträge Kommission 2019-03-31 - samt KVS',
            link: '/protocols/20190524/ueberarbeitung_rechtssammlung_sak.pdf',
        },
        {
            value: 'Beantwortung der Motion zum Stimm- und Wahlrecht für Ausländerinnen und Ausländer',
            link: '/protocols/20190524/motion_wahlrecht_auslaender.pdf',
        },
    ]
}