export default {
    id: 9,
    type:"InformationWrting",
    name: 'rkz_beitritt_refer_vote',
    title: 'RKZ Beitritt - Referendumsabstimmung',
    value: 'Abstimmungsbroschüre /  Dekret für die Referendumsabstimmung vom 30. Juni 2019 / ' +
        'Medienmitteilung vom 3. April 2019 und Informationsschreiben ' +
        'vom 4. April 2019 zur Abstimmung vom 30. Juni 2019',
    target: "InfoSite",
    Post: true,
    date: 20190405,
    files: {
        filesTitle: "Brief und Formulare:",
        filesList: [
            {
                name: "Abstimmungsbroschüre",
                path: '/documents/rkz_beitritt_referdungsabstimmung/abstimmungsbroschuere.pdf'
            },
            {
                name: "Dekret für die Referendumsabstimmung vom 30. Juni 2019",
                path: '/documents/rkz_beitritt_referdungsabstimmung/dekret.pdf'
            },
            {
                name: "Medienmitteilung vom 3. April 2019",
                path: '/documents/rkz_beitritt_referdungsabstimmung/medienmitteilung.pdf'
            },
            {
                name: "Informationsschreiben vom 4. April 2019",
                path: '/documents/rkz_beitritt_referdungsabstimmung/informationsschreiben.pdf'
            },
        ]
    }
}