export default {
    id: -1,
    name: "ContactInfo",
    ContactInfoTitle: 'Info',
    ContactInfoP1: "Bitte wenden sie sich an das Sekretariat.",
    ContactInfoP2: "Erklärungen betreffend Kirchen-Eintritte und Kirchen-Austritte sind an die Kirchgemeinde des " +
        "jeweiligen Wohnortes zu richten, nicht an die Kantonalkirche!",
    target: "/Organisation/Sekretariat",
    Post: false,
    date: 20141017,
}
