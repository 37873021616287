export default {
    kantonskirchenrat: {
        files: [
            {
                file: '/Kantonskirchenrat/Kantonskirchenraete_-_7_Legislatur.pdf',
                name: 'Kantonskirchenrat Mitglieder'
            },
            {
                file: '/Kantonskirchenrat/Kantonskirchenrat_ersatzmitglieder.pdf',
                name: 'Kantonskirchenrat Ersatzmitglieder'
            },
            {
                file: '/Kantonskirchenrat/Kommissionen_des_Kantonskirchenrats_2022.pdf',
                name: 'Kommissionen des Kantonskirchenrates'
            },
        ]
    }
}