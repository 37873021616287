<template>
    <div class="mobile-navigation">
        <div
                v-for="(item, indexSubMenu) in menu"
                :key="indexSubMenu"
                class=""
                @mouseover="mouseOverMenu(indexSubMenu)"
                @mouseleave="mouseLeaveMenu(indexSubMenu)"
        >
            <mobile-menu-item-with-submenus
                 class="text-xs-left link menu-card"
                 v-if="item.submenus"
                 @mouseleave="mouseLeaveMenu(indexSubMenu)"
                 :item="item"
                 :index-sub-menu="indexSubMenu"
            >
            </mobile-menu-item-with-submenus>

            <div v-else
                 @click.stop="navPage(item.target)"
                 class="link nav-item-without-submenues "
            >
                <div class="subheading menu-card hover-bold">
                    {{item.title}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import router from "../../router";
    import MobileMenuItemWithSubmenus from "./MobileMenuItemWithSubmenus";
    export default {
        name: "MobileNavigation",
        components: {
            MobileMenuItemWithSubmenus
        },
        data () {
            return {
                clipped: true,
                drawer: false,
                fixed: false,
                title: 'Römisch-katholische Kantonalkirche Schwyz',
                searchState: false,
            }
        },
        methods: {
            navPage (page) {
                router.push({path: page});
            },
            mouseOverMenu(menu) {
                this.menu[menu].submenuActive = true;
            },
            mouseLeaveMenu (menu) {
                this.menu[menu].submenuActive = false;
            },
            toggleSearch () {
                this.searchState = !this.searchState
            }
        },
        computed: {
            searchTexts() {
                let searchText = [];
                for (let langKey in this.$store.getters.text) {
                    if (this.$store.getters.text.hasOwnProperty(langKey)) {
                        searchText.push(this.$store.getters.text[langKey].value)
                    }
                }
                return searchText
            },
            menu () {
                return this.$store.getters.menu
            },
            viewSizeSmall () {
                return this.$store.getters.viewSizeSmall
            }
        }
    }
</script>

<style scoped>

</style>