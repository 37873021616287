export default {
    id: 15,
    name: "20200208InformationWriting",
    type: "InformationWrting",
    title: 'Gesamterneuerungswahl des Kantonskirchenrates',
    value: 'Für die Gesamterneuerungswahl des Kantonskirchenrates ist der 10. Mai 2020 ' +
        'als Wahltermin für Urnenwahlen festgesetzt worden, sofern in einer Kirchgemeinde keine stille Wahl erfolgt. ' +
        'Die Wahlvorschläge sind bis am 30. März 2020 der entsprechenden Kirchgemeinde einzureichen.',
    target: "InfoSite",
    Post: true,
    date: 20200208,
    files: {
        filesTitle: "Dateien",
        filesList: [
            {
                name: "Formular Wahlvorschläge",
                path: '/documents/info_writing_for_20200208/formular_wahlvorschlaege.pdf'
            },
            {
                name: "Dekret",
                path: '/documents/info_writing_for_20200208/dekret.pdf'
            },
        ]
    }
}