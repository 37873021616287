<template>
    <div>
        <div class="nav-item">
            <div @click.stop="navPage(submenu.target)">
                {{submenu.title}}
            </div>
            <div>
                <v-icon v-if="!submenu.subsubmenuActive"
                        @click.stop="toggleSubSubMenuActive(indexSubMenu,indexSubSubMenu)"
                        color="grey darken-2">
                    expand_more
                </v-icon>
                <v-icon v-else
                        @click.stop="toggleSubSubMenuActive(indexSubMenu,indexSubSubMenu)"
                        color="grey darken-2">
                    expand_less
                </v-icon>
            </div>
        </div>

        <div v-show="submenu.subsubmenuActive"
             class="sub-sub-menu-grid"
        >

            <div v-for="(subsubmenu, k) in submenu.subsubmenus"
                 :key="k"
                 @click.stop="navPage(subsubmenu.target)"
            >
                {{subsubmenu.title}}
            </div>
        </div>
    </div>
</template>

<script>
    import router from "../../router";
    export default {
        name: "SubmenuWithSubmenus",
        props: ['submenu', 'indexSubSubMenu', 'indexSubMenu'],
        methods: {
            navPage (page) {
                router.push({path: page});
            },
            mouseOverMenu(menu) {
                this.menu[menu].submenuActive = true;
            },
            mouseLeaveMenu (menu) {
                this.menu[menu].submenuActive = false;
            },
            toggleSubMenuActive (menu) {
                this.menu[menu].submenuActive = !this.menu[menu].submenuActive;
            },
            SubSubMenuActive (menu, submenu) {
                this.menu[menu].submenus[submenu].subsubmenuActive = true;
            },
            SubSubMenuNotActive (menu, submenu) {
                this.menu[menu].submenus[submenu].subsubmenuActive = false;
            },
            toggleSubSubMenuActive (menu, submenu) {
                this.menu[menu].submenus[submenu].subsubmenuActive = !this.menu[menu].submenus[submenu].subsubmenuActive;
            },
            toggleSearch () {
                this.searchState = !this.searchState
            }
        },
        computed: {
            menu () {
                return this.$store.getters.menu
            },
        }
    }
</script>

<style scoped>

</style>