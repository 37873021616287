export default {
    documents: [
        {
            type: 'finanzausgleichGesetz',
            file: '/documents/finanzausgleich/os.pdf',
            number: '',
            description: 'Organisationsstatut vom 8. April 1998, § 35',
            short: '',
        },
        {
            type: 'finanzausgleichGesetz',
            file: '/documents/finanzausgleich/FAG.pdf',
            number: '',
            description: 'Finanzausgleichsgesetz vom 5. April 2001',
            short: '',
        },
    ]
}