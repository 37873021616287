export default {
    id: 20200529,
    date: 20200529,
    uploadDate: '04.05.2020',
    title: 'Session des Kantonskirchenrates vom 29. Mai 2020',
    shortDescriptionString: 'Sessionsprotokoll / Informationsschreiben /' + 
    ' Ergebnisse in Kurzform / Traktanden / Unterlagen zur Session',
    subtitle: 'Der Kantonskirchenrat hielt seine ordentliche Frühjahrssession ' + 
    'am 29. Mai 2020 im Kultur- und Kongresszentrum ZWEI RABEN in Einsiedeln ' + 
    '(unter Einhaltung der aktuellen Hygiene- und Abstandsvorschriften) ab. ' + 
    'Protokoll der Session vom 29. Mai 2020',
    sessionDate: '29.05.2020',
    resultPdfs: [
        {
            value: 'Protokoll der Session vom 29. Mai 2020',
            link: '/protocols/20200504/protokoll.pdf',
        },
        {
            value: 'Informationsschreiben vom 5. Juni 2020',
            link: '/protocols/20200504/informationsschreiben.pdf',
        },
    ],
    shortResults: [
        {
            name: 'sr1',
            value: 'der Rechenschaftsbericht 2019 der Rekurskommission wurde genehmigt.'
        },
        {
            name: 'sr2',
            value: 'der Nachkredit 2019 von Fr. von Fr. 7\'143.-- ' +
            'für die Sitzungsgelder 2019 der Rekurskommission wurde gewährt.'
        },
        {
            name: 'sr3',
            value: 'die Jahresrechnung 2019 und Bilanz per 31. Dezember 2019 wurden genehmigt.'
        },
        {
            name: 'sr4',
            value: 'der Tätigkeitsbericht 2019 des Kantonalen Kirchenvorstandes wurde genehmigt.'
        },
        {
            name: 'sr5',
            value: 'der Beschluss über die Mitfinanzierung der Spitalseelsorge wurde gefasst ' +
            '(dieser Beschluss unterliegt dem fakultativen Referendum).'
        },
        {
            name: 'sr6',
            value: 'der Finanzierungsbeschluss über Fr. 15\'000.-- für die RKZ-Plenarversammlung ' +
            'vom 25./26. März 2022 in Einsiedeln wurde gefasst.'
        },
    ],
    Traktanden: [
        {
            name: 'tt1',
            value: 'Gebet, Begrüssung und Präsenz',
        },
        {
            name: 'tt2',
            value: 'Rechenschaftsbericht 2019 der Rekurskommission',
        },
        {
            name: 'tt3',
            value: 'Nachkredit 2019 für die Sitzungsgelder 2019 der Rekurskommission',
        },
        {
            name: 'tt4',
            value: 'Jahresrechnung 2019 und Bilanz per 31. Dezember 2019',
        },
        {
            name: 'tt5',
            value: 'Tätigkeitsbericht 2019 des Kantonalen Kirchenvorstandes',
        },
        {
            name: 'tt6',
            value: 'Beschluss über die Mitfinanzierung der Spitalseelsorge',
        },
        {
            name: 'tt7',
            value: 'Finanzierungsbeschluss für die RKZ-Plenarversammlung vom 25./26. März 2022 in Einsiedeln',
        },
        {
            name: 'tt8',
            value: 'Informationen der Ressortchefs und Fragestunde',
        },
        {
            name: 'tt9',
            value: 'Verschiedenes',
        },
    ],
    Unterlagen: [
        {
            value: 'Sessionseinladung',
            link: '/protocols/20200504/sessionseinladung.pdf',
        },
        {
            value: 'Beilagenblatt',
            link: '/protocols/20200504/beilagenblatt.pdf',
        },
        {
            value: 'Mitfinanzierung Spitalseelsorge',
            link: '/protocols/20200504/mitfinanzierung_spitalseelsorge.pdf',
        },
        {
            value: 'Nachkredit Rekurskommission',
            link: '/protocols/20200504/nachkredit_rekurskommission.pdf',
        },
        {
            value: 'Rechenschaftsbericht 2019',
            link: '/rechenschaftsberichte/rechenschaftsbericht_2019.pdf',
        },
        {
            value: 'RKZ-Plenarversammlung 2022',
            link: '/protocols/20200504/rkz-plenarversammlung_2022.pdf',
        },
        {
            value: 'Schreiben',
            link: '/protocols/20200504/Schreiben.pdf',
        },
    ]
}