export default {
    id: 20220928,
    date: 20220928,
    uploadDate: '28.9.2022',
    title: 'Session des Kantonskirchenrates vom 21. Oktober 2022   ',
    shortDescriptionString: `Sessionsprotokoll / Informationsschreiben / Ergebnisse in Kurzform / Traktanden /Sessionseinladung und Unterlagen`,
    subtitle: `Der Kantonskirchenrat hielt seine ordentliche Herbstsession am 21. Oktober 2022 im Schweizerischen Jugend- und Bildungszentrum SJBZ in Einsiedeln ab.`,
    sessionDate: '21.10.2022',
    resultPdfs: [
        {
            value: 'Protokoll der Session vom 21. Oktober 2022',
            link: '/protocols/20221021/sessionsprotokoll20221021.pdf',
        },
        {
            value: 'Informationsschreiben vom 15. Oktober 2022',
            link: '/protocols/20221021/schreiben.pdf',
        },
        {
            value: 'Voranschlag 2023 KKR',
            link: '/protocols/20221021/voranschlag_2023_kkr_20221021.pdf',
        },
    ],
    shortResults: [
        {
            name: 'sr1',
            value: 'Der Voranschlag 2023 mit einem Total des Aufwandes von Fr. 2’296’045.-- und dem Pro-Kopf-Beitrag an die Kantonalkirche von gesamthaft Fr. 25.20 wurde gemäss dem reduzierten Antrag des Kantonalen Kirchenvorstandes beschlossen.'
        },
        {
            name: 'sr2',
            value: 'Der Finanzausgleich 2023 wurde gemäss dem unveränderten Antrag mit einem Ausgleich des Normleistungsaufwandes von Fr. 298.75 (d.h. einem Ausgleich der Normkosten zu 97.5%) und mit einem Gesamtbudget von Fr. 1’292’069.-- beschlossen.'
        },
        {
            name: 'sr3',
            value: 'Bei der Ersatzwahl in den Kantonalen Kirchenvorstand wurde Bruder Dr. Adrian Müller, Kapuzinerkloster, Herrengasse 33, 6430 Schwyz, als Nachfolger von Hans-Peter Schuler zum Mitglied des Kantonalen Kirchenvorstandes gewählt.'
        },
    ],
    Traktanden: [
        {
            name: 'tt1',
            value: 'Gebet, Begrüssung und Präsenz',
        },
        {
            name: 'tt2',
            value: 'Beschluss über die Gültigkeit der Ersatzwahlen in den Kantonskirchenrat in der Kirchgemeinde Schwyz und Vereidigung der beiden neuen Mitglieder',
        },
        {
            name: 'tt3',
            value: 'Beschluss über den Voranschlag 2023',
        },
        {
            name: 'tt4',
            value: 'Beschluss über den Finanzausgleich 2023',
        },
        {
            name: 'tt5',
            value: 'Ersatzwahl in den Kantonalen Kirchenvorstand',
        },
        {
            name: 'tt6',
            value: 'Informationen der Ressortchefs und Fragestunde',
        },
        {
            name: 'tt7',
            value: 'Verschiedenes',
        },
    ],
    Unterlagen: [
        {
            value: 'Sessionseinladung',
            link: '/protocols/20221021/sessionseinladung.pdf',
        },
        {
            value: 'Beilagenblatt',
            link: '/protocols/20221021/Beilagenblatt.pdf',
        },
        {
            value: 'GPK-Antrag Ersatzwahlen',
            link: '/protocols/20221021/gpk-antrag_wahlen_kg_schwyz.pdf',
        },
        {
            value: 'Antrag Voranschlag 2023',
            link: '/protocols/20221021/antrag_voranschlag_2023.pdf',
        },
        {
            value: 'KAS-Voranschlag 2023',
            link: '/protocols/20221021/KAS-Voranschlag_2023.pdf',
        },
        {
            value: 'ASS-Voranschlag 2023',
            link: '/protocols/20221021/ASS-Voranschlag_2023.pdf',
        },
        {
            value: 'Bericht zum Voranschlag 2023',
            link: '/protocols/20221021/Bericht_zum_Voranschlag_2023.pdf',
        },
        {
            value: 'Finanzplanung 2023-2026',
            link: '/protocols/20221021/Finanzplanung_2023-2026.pdf',
        },
        {
            value: 'GPK - Antrag Budget 2023',
            link: '/protocols/20221021/GPK_-_Antrag_Budget_2023.pdf',
        },
        {
            value: 'Antrag Finanzausgleich 2023 mit 97.5%',
            link: '/protocols/20221021/FA_2023__mit_97.5.pdf',
        },
        {
            value: 'Antrag Finanzausgleich 2023 mit 92.5%',
            link: '/protocols/20221021/FA_2023_mit_92.5_-_Seite_11_mit_der_Uebersicht.pdf',
        },
        {
            value: 'Antrag Finanzausgleich 2023 mit 95.0%',
            link: '/protocols/20221021/FA_2023_mit_95_-_Seite_11_mit_der_Ueberseicht.pdf',
        },
        {
            value: 'Finanzausgleich 2016-2023 Varianten',
            link: '/protocols/20221021/Finanzausgleich_2016-2023_Varianten.pdf',
        },
        {
            value: 'Perspektiven der Kirchenentwicklung - Einladung',
            link: '/protocols/20221021/Perspektiven_der_Kirchenentwicklung_-_Einaldung.pdf',
        },
    ]
}