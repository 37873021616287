export default {
    id: 20171020,
    date: 20171020,
    uploadDate: '21.06.2018',
    title: 'Session des Kantonskirchenrates vom 20. Oktober 2017',
    shortDescriptionString: 'Sessionsprotokoll / Informationsschreiben / ' +
        'Ergebnisse in Kurzform und Unterlagen zu den einzelnen Traktanden',
    subtitle: 'Der Kantonskirchenrat hielt seine ordentliche Herbstsession ' +
        'am Freitag, 20. Oktober 2017, im SJBZ Einsiedeln ab.',
    sessionDate: '20.10.2017',
    resultPdfs: [
        {
            value: 'Protokoll der Session vom 20. Oktober 2017',
            link: '/protocols/20171020/protokoll.pdf',
        },
        {
            value: 'Informationsschreiben vom 10. November 2017',
            link: '/protocols/20171020/informationsschreiben.pdf',
        },
    ],
    shortResults: [
        {
            name: 'sr1',
            value: 'Der Voranschlag 2018 wurde gemäss dem unveränderten Antrag mit einem Total von' +
                'Fr. 1’695’239.-- und dem Pro-Kopf-Beitrag an die Kantonalkirche von gesamthaft Fr. 17.40 beschlossen.'
        },
        {
            name: 'sr2',
            value: 'Der Finanzausgleich 2018 wurde gemäss dem unveränderten Antrag mit einem Ausgleich ' +
                'des Normleistungsaufwandes von Fr. 256.05 (d.h. einem Ausgleich der Normkosten zu 90%) ' +
                'und mit einem Gesamtbudget von Fr. 1’023’949.-- beschlossen.'
        },
        {
            name: 'sr3',
            value: 'Bei der Ersatzwahl in den Kantonalen Kirchenvorstand wurde für den aus gesundheitlichen Gründen ' +
                'zurückgetretenen Werner Bruhin (Schwyz) neu Stephan Betschart (Muotathal) ' +
                'als Mitglied des Kantonalen Kirchenvorstands gewählt.' +
                'Der Amtsantritt wird per 1. November 2017 sein.'
        },
    ],
    Traktanden: [
        {
            name: 'tt1',
            value: 'Gebet, Begrüssung und Präsenz',
        },
        {
            name: 'tt2',
            value: 'Beschluss über den Voranschlag 2018',
        },
        {
            name: 'tt3',
            value: 'Beschluss über den Finanzausgleich 2018',
        },
        {
            name: 'tt4',
            value: 'Ersatzwahl in den Kantonalen Krichenvorstand',
        },
        {
            name: 'tt5',
            value: 'Informationen der Ressortchefs und Fragestunde',
        },
        {
            name: 'tt6',
            value: 'Verschiedenes',
        },
    ],
    Unterlagen: [
        {
            value: 'Sessionseinladung',
            link: '/protocols/20171020/sessionseinladung.pdf',
        },
        {
            value: 'Beilagenblatt',
            link: '/protocols/20171020/beilagenblatt.pdf',
        },
        {
            value: 'Antrag Voranschlag 2018',
            link: '/protocols/20171020/antrag_voranschlag.pdf',
        },
        {
            value: 'ASS Voranschlag 2018',
            link: '/protocols/20171020/ass_voranschlag.pdf',
        },
        {
            value: 'Bericht zum Voranschlag 2018',
            link: '/protocols/20171020/bericht_zum_voranschlag.pdf',
        },
        {
            value: 'Finanzplanung 2018 - 2021',
            link: '/protocols/20171020/finanzplanung.pdf',
        },
        {
            value: 'Antrag Finanzausgleich 2018 (90%)',
            link: '/protocols/20171020/antrag_finanzausgleich.pdf',
        },
        {
            value: 'Vergleich Finanzausgleich 2018 (92.5%)',
            link: '/protocols/20171020/vergleich_finanzausgleich.pdf',
        },
        {
            value: 'Übersicht Finanzausgleich 2009 - 2018',
            link: '/protocols/20171020/uebersicht_finanzausgleich.pdf',
        },
        {
            value: 'Bericht und Antrag der GPK',
            link: '/protocols/20171020/bericht_und_antrag.pdf',
        },
        {
            value: 'Ersatzwahl in den Kantonalen Kirchenvorstand',
            link: '/protocols/20171020/ersatzwahl.pdf',
        },
        {
            value: 'Lebenslauf Stephan Betschart',
            link: '/protocols/20171020/lebenslauf_stephan_betschart.pdf',
        },
    ]
}