export default {
    dekanat: {
        name: 'Dekanat Ausserschwyz',
        Dekanate: [
            {
                Ort: 'Altendorf, Hl. Michael',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Dorfplatz 5',
                PLZ: '8852 Altendorf',
                phone: '055 442 13 49 oder Sekretariat 055 442 24 55',
                Fax: '055 442 27 60',
                email: 'pfarramt@pfarrei-altendorf.ch',
                website: 'www.pfarrei-altendorf.ch',
                Details: 'www.kath.ch/addresses/?instid=34',
                expanded: false
            },
            {
                Ort: 'Buttikon, Hl. Josef',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Kirchweg 1',
                PLZ: '8863 Buttikon',
                phone: '055 444 15 41',
                email: 'pfarramt.buttikon@bluewin.ch',
                website: 'www.pfarrei-buttikon.ch',
                Details: 'www.kath.ch/addresses/?instid=291',
                expanded: false
            },
            {
                Ort: 'Einsiedeln, Maria Himmelfahrt',
                Pfarramt: 'Röm.-kath. Pfarrei Einsiedeln',
                PLZ: '8840 Einsiedeln',
                phone: '055 418 62 11',
                Fax: '055 418 62 12',
                email: 'sekretariat@pfarrei-einsiedeln.ch',
                website: 'www.pfarrei-einsiedeln.ch/',
                Details: 'www.kath.ch/addresses/?instid=493',
                expanded: false
            },
            {
                Ort: 'Feusisberg, Hl. Jakobus d. Ä.',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Dorfstrasse 37',
                PLZ: '8835 Feusisberg',
                phone: '044 784 04 63',
                Fax: '044 784 07 74',
                Details: 'www.kath.ch/addresses/?instid=550',
                expanded: false
            },
            {
                Ort: 'Freienbach, Hl. Adelrich',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Kirchstrasse 47',
                PLZ: '8807 Freienbach SZ',
                phone: '055 410 14 18',
                Fax: '055 410 18 82',
                email: 'pfarramt.freienbach@swissonline.ch',
                website: 'www.pfarreifreienbach.ch',
                Details: 'www.kath.ch/addresses/?instid=574',
                expanded: false
            },
            {
                Ort: 'Galgenen, Hl. Martin',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Kirchweg 1',
                PLZ: '8854 Galgenen',
                phone: '055 440 13 94',
                email: 'pfarreigalgenen@bluewin.ch',
                website: 'www.pfarrei-galgenen.ch',
                Details: 'www.kath.ch/addresses/?instid=596',
                expanded: false
            },
            {
                Ort: 'Innerthal, Hl. Katharina',
                Pfarramt: 'Kath. Pfarramt Innerthal',
                Strasse: 'Postfach Postfach 14',
                PLZ: '8857 Vorderthal',
                phone: '+41 55 446 11 41',
                email: 'pfarramt.vorderthal@bluewin.ch',
                website: 'www.kirche-waegital.ch',
                Details: 'www.kath.ch/addresses/?instid=784',
                expanded: false
            },
            {
                Ort: 'Lachen, Heilig Kreuz',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Kirchweg 1',
                PLZ: '8853 Lachen SZ',
                phone: '055 451 04 74 (Pfarramt) 055 451 04 70 (Sekretariat)',
                email: 'sekretariat@kirchelachen.ch',
                website: 'www.kirchelachen.ch',
                Details: 'www.kath.ch/addresses/?instid=846',
                expanded: false
            },
            {
                Ort: 'Nuolen, Hl. Margaretha',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Seestrasse 71',
                PLZ: '8855 Nuolen',
                phone: '+41 55 440 24 70',
                Fax: '055 450 51 42',
                Details: 'www.kath.ch/addresses/?instid=1151',
                expanded: false
            },
            {
                Ort: 'Pfäffikon, St. Meinrad',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Mühlematte 3',
                PLZ: '8808 Pfäffikon SZ',
                phone: '055 410 22 65',
                Fax: '055 410 22 25',
                email: 'pfarramt.pfaeffikon@swissonline.ch',
                website: 'www.pfarreipfaeffikon.ch',
                Details: 'www.kath.ch/addresses/?instid=1222',
                expanded: false
            },
            {
                Ort: 'Reichenburg, Hl. Laurentius',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Postfach 264',
                PLZ: '8864 Reichenburg SZ',
                phone: '055 444 11 26',
                email: 'st.laurentius@bluewin.ch',
                website: 'www.st-laurentius-pfarrei.ch',
                Details: 'www.kath.ch/addresses/?instid=1293',
                expanded: false
            },
            {
                Ort: 'Schindellegi, Hl. Anna',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Postfach Postfach 527',
                PLZ: '8832 Wollerau',
                phone: '044 787 01 70',
                email: 'sekretariat@seelsorgeraum-berg.ch',
                website: 'www.seelsorgeraum-berg.ch',
                Details: 'www.kath.ch/addresses/?instid=1421',
                expanded: false
            },
            {
                Ort: 'Schübelbach, Hll. Konrad u. Ulrich',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Grünhaldenstrasse 11',
                PLZ: '8862 Schübelbach',
                phone: '055 440 11 75',
                email: 'sekretariat@pfarrei-schuebelbach.ch',
                website: 'www.pfarrei-schuebelbach.ch',
                Details: 'www.kath.ch/addresses/?instid=1435',
                expanded: false
            },
            {
                Ort: 'Siebnen, Hl. Herz Jesu',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Kirchweg 1',
                PLZ: '8854 Siebnen',
                phone: '055 440 13 56',
                Fax: '055 440 92 57',
                email: 'pfarrei.siebnen@bluewin.ch',
                Details: 'www.kath.ch/addresses/?instid=1471',
                expanded: false
            },
            {
                Ort: 'Tuggen, Hll. Erhard u. Viktor',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Buchbergstrasse 6',
                PLZ: '8856 Tuggen',
                phone: '+41 55 445 11 74',
                email: 'info@pfarrei-tuggen.ch',
                website: 'www.pfarrei-tuggen.ch',
                Details: 'www.kath.ch/addresses/?instid=1667',
                expanded: false
            },
            {
                Ort: 'Vorderthal, Hll. Peter und Paul',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Postfach Postfach 14',
                PLZ: 'Öffnungszeiten Pfarrbüro: Donnerstag 13.30 Uhr - 15.30 Uhr Vorderthal',
                phone: '055 446 11 41',
                email: 'pfarramt.vorderthal@bluewin.ch',
                website: 'www.kirche-waegital.ch',
                Details: 'www.kath.ch/addresses/?instid=1770',
                expanded: false
            },
            {
                Ort: 'Wangen, Hl. Kolumban',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Dorfplatz 8',
                PLZ: '8855 Wangen',
                phone: '055 460 10 38',
                email: 'sekretariat@pfarrei-wangensz.ch',
                Details: 'www.kath.ch/addresses/?instid=1793',
                expanded: false
            },
            {
                Ort: 'Wollerau, Hl. Verena',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Postfach Postfach 527',
                PLZ: '8832 Wollerau',
                phone: '044 787 01 70',
                email: 'sekretariat@seelsorgeraum-berg.ch',
                website: 'www.seelsorgeraum-berg.ch',
                Details: 'www.kath.ch/addresses/?instid=1851',
                expanded: false
            },
        ],
    }
}