export const filelist = [
    {
        value: "1. Sessionsprotokoll",
        link: "/protocols/20240612/10_Sessionsprotokoll.pdf"
    },
    {
        value: "2. Informationsschreiben",
        link: "/protocols/20240612/9_Informationsschreiben.pdf"
    },
    {
        value: "3. Wahlen 2024 1.lst",
        link: "/protocols/20240612/11_Wahlen_2024_1.pdf"
    },
]