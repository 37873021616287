import {filelist} from "./filelists/filelist20240630"
export default {
    id: 20240529,
    date: 20240529,
    uploadDate: '29.05.2024',
    title: 'Konstituierende Session des Kantonskirchenrates vom 21. Juni 2024 Sessionseinladung und Unterlagen',
    shortDescriptionString: 'Sessionsprotokoll / Informationsschreiben / Ergebnisse in Kurzform / Traktanden / Sessionseinladung und Unterlagen',
    subtitle: `Der Kantonskirchenrat hielt seine konstituierende Session am 21. Juni 2024 im  Schweizerischen Jugend- und Bildungs-Zentrum SJBZ in Einsiedelnab.<br>
    Die Ergebnisse in Kurzform: <br>
    Der Kantonskirchenrat der Röm.-kath. Kantonalkirche Schwyz hat an seiner konstituierenden Session vom 21. Juni 2024 folgende Wahlen vorgenommen:`,
    sessionDate: '21.6.2024',
    shortResults: [
        {
            name: 'sr1',
            value: `Büro des Kantonskirchenrates:<br>
Präsident: Johannes Schwimmer (Schwyz)<br>
Vizepräsidentin: Maria Bürgler (Illgau)<br>
Stimmenzähler: Emil Gnos (Küssnacht) und Dr. Brigitte Fischer Züger (Altendorf)`
        },
        {
            name: 'sr2',
            value: `Sekretär des Kantonskirchenrats: Dr. Linus Bruhin (Nuolen)`
        },
        {
            name: 'sr3',
            value: `Kantonaler Kirchenvorstand:<br>
            Präsident: Lorenz Bösch (Ingenbohl-Brunnen)<br>
            Finanzverantwortliche: Karin Birchler (Studen)<br>
            Mitglieder: Vreni Bürgi (Wollerau), Dr. Adrian Müller (Schwyz) und Othmar Reichmuth (Illgau)`
        },
        {
            name: 'sr4',
            value: `Sekretär des Kantonalen Kirchenvorstands: Dr. Linus Bruhin (Nuolen)`
        },
        {
            name: 'sr5',
            value: `Rekurskommission:<br>
            Präsident: lic.iur. Arthur Schilter (Arth) <br>
            Mitglieder: lic.iur. Annegreth Fässler (Schwyz) und Dr. Andreas Hubli (Altendorf)<br>
            Ersatzmitglieder: lic.iur. Jolanda Fleischli (Wangen) und Peter Trutmann (Immensee)`
        },
        {
            name: 'sr6',
            value: `Geschäftsprüfungskommission:<br>
            Präsident: Andreas Marty (Einsiedeln)<br>
            Mitglieder:  Dr. Ruedi Beeler (Rothenthurm), Claude Camenzind (Freienbach), Bruno Grätzer (Morschach-Stoos), Urs Peter Seeholzer (Küssnacht), Marcel Grepper (Arth-Goldau), Aldo Dubacher (Steinen), Josef Cavelti (Wollerau) und Lara Stuppia (Lachen)`
        },
    ],
    resultPdfs: filelist,
    Traktanden: [
        {
            name: 'tt1',
            value: 'Teil1: Vorsitz Alterspräsident',
            unterTraktanden: [
                {
                    name: 'utt1',
                    prefix: '1',
                    value: 'Begrüssung und Präsenz',
                },
                {
                    name: 'utt2',
                    prefix: '2',
                    value: 'Bezeichnung von provisorischen Stimmenzählern',
                },
                {
                    name: 'utt3',
                    prefix: '3',
                    value: 'Beschluss über die Gültigkeit der Wahl der Mitglieder des Kantonskirchenrats',
                },
                {
                    name: 'utt4',
                    prefix: '4',
                    value: 'Wahl der Präsidentin bzw. des Präsidenten des Kantonskirchenrat',
                },
            ]
        },
        {
            name: 'tt2',
            value: 'Teil II: Vorsitz Präsidentin bzw. Präsident des Kantonskirchenrats',
            unterTraktanden: [
                {
                    name: 'utt1',
                    prefix: '5',
                    value: 'Gottesdienst, samt Vereidigung der Mitglieder des Kantonskirchenrats ' +
                        '(Amtseid oder Amtsgelübde)',
                },
                {
                    name: 'utt2',
                    prefix: '6',
                    value: 'Wahlen in das Büro des Kantonskirchenrats:',
                    unterUnterTraktanden: [
                        {
                            name: 'uutt1',
                            prefix: 'a)',
                            value: 'Wahl der Vizepräsidentin bzw. des Vizepräsidenten des Kantonskirchenrates',
                        },
                        {
                            name: 'uutt2',
                            prefix: 'b)',
                            value: 'Wahl von zwei Stimmenzählerinnen bzw. Stimmenzählern',
                        },
                    ]
                },
                {
                    name: 'utt3',
                    prefix: '7',
                    value: 'Wahl der Sekretärin bzw. des Sekretärs des Kantonskirchenrates',
                },
                {
                    name: 'utt4',
                    prefix: '8',
                    value: ' Wahl des Kantonalen Kirchenvorstandes:',
                    unterUnterTraktanden: [
                        {
                            name: 'uutt1',
                            prefix: 'a)',
                            value: 'Wahl der Präsidentin bzw. des Präsidenten des Kantonalen Kirchenvorstandes',
                        },
                        {
                            name: 'uutt2',
                            prefix: 'b)',
                            value: 'Wahl der bzw. des Finanzverantwortlichen des Kantonalen Kirchenvorstandes',
                        },
                        {
                            name: 'uutt2',
                            prefix: 'c)',
                            value: 'Wahl der drei Mitglieder des Kantonalen Kirchenvorstandes',
                        },
                    ]
                },
                {
                    name: 'utt3',
                    prefix: '9',
                    value: 'Vereidigung der Mitglieder des Kantonalen Kirchenvorstandes (Amtseid oder Amtsgelübde)',
                },
                {
                    name: 'utt3',
                    prefix: '10',
                    value: 'Wahl der Sekretärin bzw. des Sekretärs des Kantonalen Kirchenvorstandes',
                },
                {
                    name: 'utt2',
                    prefix: '11',
                    value: 'Wahlen in das Büro des Kantonskirchenrats:',
                    unterUnterTraktanden: [
                        {
                            name: 'uutt1',
                            prefix: 'a)',
                            value: 'Wahl der Präsidentin bzw. des Präsidenten der Rekurskommission',
                        },
                        {
                            name: 'uutt2',
                            prefix: 'b)',
                            value: 'Wahl der zwei Mitglieder und der zwei Ersatzmitglieder der Rekurskommission',
                        },
                    ],
                },
                {
                    name: 'utt3',
                    prefix: '12',
                    value: 'Wahl der Geschäftsprüfungskommission mit einer Präsidentin bzw. ' +
                        'einem Präsidenten und acht Mitgliedern',
                },
                {
                    name: 'utt3',
                    prefix: '14',
                    value: 'Informationen der Ressortchefs und Fragestunde',
                },
                {
                    name: 'utt3',
                    prefix: '15',
                    value: 'Verschiedenes',
                },
            ]
        },
    ],
    Unterlagen: [
        {
            value: "1 Sessionseinladung",
            link: "/protocols/20240612/1_Sessionseinladung.pdf"
        },
        {
            value: "2 Beilagenblatt",
            link: "/protocols/20240612/2_Beilagenblatt.pdf"
        },
        {
            value: "3 Kantonskirchenräte - 7 Legislatur 1",
            link: "/protocols/20240612/3_Kantonskirchenraete_-_7_Legislatur_1.pdf"
        },
        {
            value: "4 Prüfbericht GPK",
            link: "/protocols/20240612/4_Pruefbericht_GPK.pdf"
        },
        {
            value: "5 Wahlvorschläge",
            link: "/protocols/20240612/5_Wahlvorschlaege.pdf"
        },
        {
            value: "6 Wahlvorschlag Reichmuth",
            link: "/protocols/20240612/6_Wahlvorschlag_Reichmuth.pdf"
        },
        {
            value: "7 Einladung Strategietag 2024 - Word",
            link: "/protocols/20240612/7_Einladung_Strategietag_2024.docx"
        },
        {
            value: "7 Einladung Strategietag 2024",
            link: "/protocols/20240612/7_Einladung_Strategietag_2024.pdf"
        },
        {
            value: "8 Einladung Verhaltenskodex 2024",
            link: "/protocols/20240612/8_Einladung_Verhaltenskodex_2024.pdf"
        },
        
    ]
}