export default {
    id: 24,
    name: "20211215InformationWriting",
    type: "InformationWrting",
    title: 'Informationsschreiben und Besoldungsentscheide des Kantonalen Kirchenvorstandes per 1. Januar 2022',
    subtitle: 'Teuerungsausgleich / Lohntabellen / Meldung Steuerfuss / Meldung Katholikenzahl',
    value: `Der Kantonale Kirchenvorstand beschloss: <br>
Die “Lohnklassen und Lohnstufen” gemäss Anhang 2 der Personal- und Besoldungsverordnung werden für das Jahr 2022 nicht angehoben, 
sondern bleiben unverändert gegenüber den Jahren 2011 - 2021 (auf den Indexstand von unverändert 104.2 Punkten, Basis Dezember 2005 = 100 Punkte). Indexstand 30. November 2020: 102.4
Die Kirchgemeinden haben den beschlossenen Steuerfuss für das Jahr 2022 mittels des Formulars bis am 20. Dezember 2021 zu melden.
Und die Katholikenzahlen per 1. Januar 2022 etc. sind mittels des Formulars bis am 31. Januar 2021 von den Kirchgemeinden zu melden.`,
    target: "InfoSite",
    Post: true,
    date: 20211215,
    files: {
        filesTitle: "Brief und Formulare:",
        filesList: [
            {
                name: "Informationsschreiben vom 13. Dezember 2021",
                path: '/documents/info_writing_for_20211215/Informationsschreiben.pdf'
            },
            {
                name: "Lohntabelle 2022 (pdf)",
                path: '/documents/info_writing_for_20211215/Lohntabelle_2022.pdf'
            },
            {
                name: "Meldung beschlossener Steuersatz 2022 (pdf)",
                path: '/documents/info_writing_for_20211215/Steuersatzmeldung.pdf'
            },
            {
                name: "Meldung beschlossener Steuersatz 2022 (Word)",
                path: '/documents/info_writing_for_20211215/Steuersatzmeldung.doc'
            },
            {
                name: "Meldung Katholikenzahl per 1. Januar 2022 (pdf)",
                path: '/documents/info_writing_for_20211215/Katholikenzahlmeldung.pdf'
            },
            {
                name: "Meldung Katholikenzahl per 1. Januar 2022 (Word)",
                path: '/documents/info_writing_for_20211215/Katholikenzahlmeldung.doc'
            },
        ]
    }
}