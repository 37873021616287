export default {
    id: 0,
    type:"InformationWrting",
    name: 'infoWritingFor20180101',
    title: 'Vakanz im Vorstand der Kantonalkirche',
    value: 'Auf ärztlichen Rat hin hat lic.iur Werner Bruhin, ehemaliger Präsident des Verwaltungsgerichtes, ' +
        'als Vorstandsmitglied der Römisch-katholischen Kantonalkirche, mit sofortiger Wirkung, seine Demission ' +
        'eingereicht. Werner Bruhin hat in den vergangen 5 Jahren das Ressort «Recht» betreut und war überdies ' +
        'Vizepräsident im Vorstand der Kantonalkirche. Im Rahmen der Aufsichtstätigkeit, aber auch auf Anfragen, ' +
        'stand er vielen Kirchgemeinden bei Rechtsfragen kompetent beratend zur Seite. Sein überlegtes und ' +
        'sachlich begründetes Urteil, sowie seine Kollegialität werden die übrigen Vorstandsmitglieder sehr ' +
        'vermissen. Die Kantonalkirche Schwyz ist Werner Bruhin zu grossem Dank verpflichtet, hat er doch ' +
        'massgeblich bei der Erarbeitung der neuen Kirchenverfassung und bei der erfolgreichen ' +
        'Volksabstimmung mitgewirkt.<br>' +
        '<br>' +
        'Der Vorstand dankt Werner Bruhin für seine Tätigkeit im Dienste der Kantonalkirche und wünscht ihm, ' +
        'seiner Frau Cäcilia und der Familie für die Zukunft viel Kraft und alles Gute. Es bleibt zu hoffen, ' +
        'dass die Lücke im Kirchenvorstand bald geschlossen werden kann. Der Kantonskirchenrat, als Wahlbehörde, ' +
        'tagt am 20.Oktober 2017.<br>' +
        ' <br>' +
        '<br>' +
        'Werner Inderbitzin<br>' +
        '<br>' +
        'Präsident Vorstand der Kantonalkirche',
    target: "InfoSite",
    Post: true,
    date: 20170714,
}