export default {
    praesidentInnen: [
        {
            location: 'Alpthal',
            locationLink: 'https://www.kath.ch/addresses/?instid=3613',
            leader: 'Felix Beeler',
            link: 'https://www.kath.ch/addresses/?persid=2612'
        },
        {
            location: 'Altendorf',
            locationLink: 'https://www.kath.ch/addresses/?instid=3539',
            leader: 'Andreas Hubli-Weber',
            link: 'https://www.kath.ch/addresses/?persid=8530'
        },
        {
            location: 'Arth-Goldau',
            locationLink: 'https://www.kath.ch/addresses/?instid=656',
            leader: 'Georg Schuler',
            link: ' https://www.kath.ch/addresses/?persid=10757',
        },
        {
            location: 'Buttikon',
            locationLink: 'https://www.kath.ch/addresses/?instid=3540',
            leader: 'Peter Krieg',
            link: 'https://www.kath.ch/addresses/?persid=10818',
        },
        {
            location: 'Einsiedeln',
            locationLink: 'https://www.kath.ch/addresses/?instid=3541',
            leader: 'Albert Schönbächler',
            link: 'https://www.kath.ch/addresses/?persid=7662',
        },
        {
            location: 'Feusisberg',
            locationLink: 'https://www.kath.ch/addresses/?instid=5153',
            leader: 'Thomas Fritsche',
            link: 'https://www.kath.ch/addresses/?persid=1925',
        },
        {
            location: 'Freienbach',
            locationLink: 'https://www.kath.ch/addresses/?instid=3542',
            leader: 'Armin Immoos',
            link: 'http://www.kath.ch/addresses/?persid=7581',
        },
        {
            location: 'Galgenen',
            locationLink: 'http://www.kath.ch/addresses/?instid=3543',
            leader: 'Christian Nairz',
            link: 'https://www.kath.ch/addresses/?persid=8073',
        },
        {
            location: 'Gersau',
            locationLink: 'https://www.kath.ch/addresses/?instid=3570',
            leader: 'Angela Anderes',
            link: 'https://www.kath.ch/addresses/?persid=7672',
        },
        {
            location: 'Illgau',
            locationLink: 'http://www.kath.ch/addresses/?instid=3572',
            leader: 'Ernst Bürgler',
            link: 'https://www.kath.ch/addresses/?persid=10667',
        },
        {
            location: 'Immensee',
            locationLink: 'https://www.kath.ch/addresses/?instid=3573',
            leader: 'Brigitte Rickenbacher-Zimmermann',
            link: 'https://www.kath.ch/addresses/?persid=10451',
        },
        {
            location: 'Ingenbohl',
            locationLink: 'https://www.kath.ch/addresses/?instid=3574',
            leader: 'Daniel von Euw',
            link: 'https://www.kath.ch/addresses/?persid=11110',
        },
        {
            location: 'Küssnacht am Rigi',
            locationLink: 'https://www.kath.ch/addresses/?instid=3575',
            leader: 'Hanstoni Gamma-Moos',
            link: 'https://www.kath.ch/addresses/?persid=1928',
        },
        {
            location: 'Lachen',
            locationLink: 'https://www.kath.ch/addresses/?instid=3544',
            leader: 'Philipp Dobler',
            link: 'https://www.kath.ch/addresses/?persid=11843',
        },
        {
            location: 'Lauerz',
            locationLink: 'https://www.kath.ch/addresses/?instid=3576',
            leader: 'Josef Baggenstos-Zgraggen',
            link: 'https://www.kath.ch/addresses/?persid=8185',
        },
        {
            location: 'Merlischachen',
            locationLink: 'https://www.kath.ch/addresses/?instid=3577',
            leader: 'Peter Zimmermann',
            link: 'https://www.kath.ch/addresses/?persid=6904',
        },
        {
            location: 'Morschach',
            locationLink: 'http://www.kath.ch/addresses/?instid=3578',
            leader: 'Walter Steiner',
            link: 'http://www.kath.ch/addresses/?persid=8058',
        },
        {
            location: 'Muotathal',
            locationLink: 'http://www.kath.ch/addresses/?instid=6226',
            leader: 'Helen Schelbert-Gwerder',
            link: 'http://www.kath.ch/addresses/?persid=5318',
        },
        {
            location: 'Nuolen',
            locationLink: 'https://www.kath.ch/addresses/?instid=3545',
            leader: 'Hans Landolt',
            link: 'https://www.kath.ch/addresses/?persid=10449',
        },
        {
            location: 'Oberiberg',
            locationLink: 'https://www.kath.ch/addresses/?instid=3580',
            leader: 'Pius Holdener-Nussbaumer',
            link: 'https://www.kath.ch/addresses/?persid=9920',
        },
        {
            location: 'Reichenburg',
            locationLink: 'https://www.kath.ch/addresses/?instid=3547',
            leader: 'Martin Kistler',
            link: 'https://www.kath.ch/addresses/?persid=5314',
        },
        {
            location: 'Riemenstalden',
            locationLink: 'https://www.kath.ch/addresses/?instid=3581',
            leader: 'Felix Betschart-Müller',
            link: 'https://www.kath.ch/addresses/?persid=2010',
        },
        {
            location: 'Rothenthurm',
            locationLink: 'https://www.kath.ch/addresses/?instid=3582',
            leader: 'Dr. Ruedi Beeler',
            link: 'https://www.kath.ch/addresses/?persid=7681',
        },
        {
            location: 'Sattel',
            locationLink: 'https://www.kath.ch/addresses/?instid=3583',
            leader: 'Esther Moser-Trinkler',
            link: 'https://www.kath.ch/addresses/?persid=11836',
        },
        {
            location: 'Schindellegi',
            locationLink: 'https://www.kath.ch/addresses/?instid=3548',
            leader: 'Sibylle Has',
            link: 'https://www.kath.ch/addresses/?persid=12076',
        },
        {
            location: 'Schübelbach',
            locationLink: 'https://www.kath.ch/addresses/?instid=3549',
            leader: 'Erhard Gresch',
            link: 'http://www.kath.ch/addresses/?persid=9921',
        },
        {
            location: 'Schwyz',
            locationLink: 'https://www.kath.ch/addresses/?instid=3584',
            leader: 'Urs Zehnder-Reichmuth',
            link: 'https://www.kath.ch/addresses/?persid=2439',
        },
        {
            location: 'Siebnen',
            locationLink: 'https://www.kath.ch/addresses/?instid=3550',
            leader: 'Michael Lanelli',
            link: 'https://www.kath.ch/addresses/?persid=8059',
        },
        {
            location: 'Steinen',
            locationLink: 'https://www.kath.ch/addresses/?instid=3612',
            leader: 'Vakant',
            link: '',
        },
        {
            location: 'Steinerberg',
            locationLink: 'https://www.kath.ch/addresses/?instid=3586',
            leader: 'Richard Renggli',
            link: 'https://www.kath.ch/addresses/?persid=6905',
        },
        {
            location: 'Studen',
            locationLink: 'https://www.kath.ch/addresses/?instid=3587',
            leader: 'Fässler Stefan',
            link: 'https://www.kath.ch/addresses/?persid=10477',
        },
        {
            location: 'Tuggen',
            locationLink: 'https://www.kath.ch/addresses/?instid=3551',
            leader: 'Rolf Hinder',
            link: 'https://www.kath.ch/addresses/?persid=11109',
        },
        {
            location: 'Unteriberg',
            locationLink: 'https://www.kath.ch/addresses/?instid=3588',
            leader: 'Edy Marty-Spindor',
            link: 'https://www.kath.ch/addresses/?persid=2072',
        },
        {
            location: 'Wägital',
            locationLink: 'https://www.kath.ch/addresses/?instid=5197',
            leader: 'Armin Schnyder',
            link: 'https://www.kath.ch/addresses/?persid=7791',
        },
        {
            location: 'Wangen',
            locationLink: 'https://www.kath.ch/addresses/?instid=3553',
            leader: 'Xaver Bruhin',
            link: 'https://www.kath.ch/addresses/?persid=3241',
        },
        {
            location: 'Wollerau',
            locationLink: 'https://www.kath.ch/addresses/?instid=3554',
            leader: 'André Veya',
            link: 'https://www.kath.ch/addresses/?persid=8531',
        },

    ]
}