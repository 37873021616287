import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}
function loadComponent(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/components/${view}.vue`)
}

export default new Router({
  routes: [
      {
          path: '/',
          name: 'home',
          component: loadView( 'Home' ),
      },
      {
          path: '/organisation',
          name: 'organisation',
          component: loadComponent( 'Organisation' ),
      },
      {
          path: '/institution',
          name: 'institution',
          component: loadView( 'Institutions' ),
      },
      {
          path: '/contact',
          name: 'contact',
          component: loadView( 'Contact' ),
      },
      {
          path: '/links',
          name: 'links',
          component: loadView( 'Links' ),
      },
      {
          path: '/Aktuelles',
          name: 'Aktuelles',
          component: loadView( 'Aktuelles' ),
      },
      {
          path: '/Organisation/Sekretariat',
          name: 'Sekretariat',
          component: loadView('Sekretariat' ),
      },
      {
          path: '/Organisation/Kantonskirchenrat',
          name: 'Kantonskirchenrat',
          component: loadView( 'Kantonskirchenrat' ),
      },
      {
          path: '/Organisation/Kantonskirchenrat/Protokolle',
          name: 'Protokolle',
          component: loadView( 'Protokolle' ),
      },
      {
          path: '/Organisation/Kantonskirchenrat/Protokolle/detailed/:id',
          name: 'ProtokolleDetailed',
          component: loadComponent( 'Kantonskirchenrat/Protokolle/detailed' ),
          props: true,
      },
      {
          path: '/InfoSite/:id',
          name: 'InfoSite',
          component: loadView( 'InfoSite' ),
          props: true,
      },
      {
          path: '/Organisation/Kirchenvorstand',
          name: 'Kirchenvorstand',
          component: loadView( 'Kirchenvorstand' ),
      },
      {
          path: '/Organisation/Rekurskommission',
          name: 'Rekurskommission',
          component: loadView( 'Rekurskommission' ),
      },
      {
          path: '/Dokumente',
          name: 'Dokumente',
          component: loadView( 'Dokumente' ),
      },
      {
          path: '/Dokumente/Rechtssammlung',
          name: 'Rechtssammlung',
          component: loadView( 'Rechtssammlung' ),
      },
      {
          path: '/Dokumente/Terminliste',
          name: 'Terminliste',
          component: loadView( 'Terminlisten' ),
      },
      {
          path: '/Dokumente/Rechenschaftsberichte',
          name: 'Rechenschaftsberichte',
          component: loadComponent( 'Rechenschaftsberichte' ),
      },
      {
          path: '/Dokumente/Finanzausgleich',
          name: 'Finanzausgleich',
          component: loadView( 'Finanzausgleich' ),
      },
      {
          path: '/Dokumente/Mustervorlagen',
          name: 'Mustervorlagen',
          component: loadView( 'Mustervorlagen' ),
      },
      {
          path: '/Dokumente/Archiv',
          name: 'Archiv',
          component: loadView( 'Archiv' ),
      },
      {
          path: '/Kirchgemeinden/',
          name: 'Kirchgemeinden',
          component: loadView( 'Kirchgemeinden' ),
      },
      {
          path: '/Kirchgemeinden/PraesidentInnen',
          name: 'PraesidentInnen',
          component: loadView( 'PraesidentInnen' ),
      },
      {
          path: '/Kirchgemeinden/Pfarreien',
          name: 'Pfarreien',
          component: loadView( 'Pfarreien' ),
      },


  ],
    /*mode: 'history'*/
})
