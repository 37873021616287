<template>
    <div class="logo-centered ">
        <img class="logo-image"
             src="/img/logo.png"
             alt="logo"
        >
    </div>
</template>

<script>
    export default {
        name: "logo"
    }
</script>

<style scoped>

</style>