export default {
    id: 29,
    name: "20230124InformationWriting",
    type: "InformationWrting",
    title: 'Datenerhebung bei den Kirchgemeinden für die Webseite <a href="https://www.kirchensteuern-sei-dank.ch/"> kirchensteuern-sei-dank.ch</a>',
    value: `
Für ein besseres Sichtbar-Machen der Leistungen der Kirchengemeinden und
der Kirche im Kanton Schwyz  sollen diese auf der Webseite  <a href="https://www.kirchensteuern-sei-dank.ch/"> kirchensteuern-sei-dank.ch</a>
angeführt werden. Die Kirchgemeinden sind
deshalb aufgefordert spätestens bis Ende Mai 2023 das Excel-Formular
auszufüllen. Eine erste Hilfestellung dafür steht mit der
Bedienungsanleitung zur Verfügung.
    `,
    target: "InfoSite",
    Post: true,
    date: 20230124,
    files: {
        filesTitle: "Dateien",
        filesList: [
            {
                name: "Anleitung",
                path: '/documents/info_writing_for_20230224/Anleitung.docx'
            },
            {
                name: "Erhebung bei KG 2023",
                path: '/documents/info_writing_for_20230224/Erhebung_bei_KG_2023.xlsx'
            }
        ]
    }
}