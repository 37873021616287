<template>
    <div class="search-wrapper pb-4">
        <div class="hidden-lg-and-up">
            <v-text-field
                    auto-grow
                    height="12"
                    v-model="matchVal"
                    class=""
                    @click="turnSearchResultsOn"
            >
                <v-icon slot="prepend" small> search </v-icon>

            </v-text-field>
        </div>
        <div  class="hidden-md-and-down mb-2">
            <v-text-field
                    auto-grow
                    height="12"
                    v-model="matchVal"
                    class=""
                    dark
                    @click="turnSearchResultsOn"
            >
                <v-icon slot="prepend" small> search </v-icon>
            </v-text-field>
        </div>
        <!--
        ---------------------------------------------------------------------------------------------------------------
        used from mobile and desktop
        ---------------------------------------------------------------------------------------------------------------
        -->
        <div class="search-entry-wrapper">
            <div v-if="showSearchResults && matchVal !== ''"
                 v-for="(Match, index) in searchMatches(matchVal)"
                 :key="index"
                 @click.stop="navPage(Match.target, Match.id)"
                 class="black--text text-xs-left link kath-sz-card link-card search-entry"
            >
                <div class="pa-2"
                     v-html="Match.value">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VTextarea from "vuetify/es5/components/VTextarea/VTextarea";
    import router from '../router';
    import search from '../assets/js/search'


    export default {
        name: "search",
        components: {VTextarea},
        data () {
            return {
                matchVal : '',
                showMatchesData: false,
            }
        },
        props: {
            mobile: Boolean,
        },
        methods: {
            navPage(page, protocollId) {
                this.turnSearchResultsOff();
                if (page.indexOf('/')!==-1) {
                    router.push({path: page})
                } else {
                    router.push({name: page, params: {id: protocollId}});
                }
            },
            searchMatches (searchValue) {
                let searchPoolRaw = this.$store.getters.searchData;
                const searchDataRaw = new search.SearchTextData(searchPoolRaw);
                let searchData = [];

                searchData = searchData.concat(
                    searchDataRaw.texts(),
                    searchDataRaw.registeredMembers(),
                    searchDataRaw.protocols(),
                    searchDataRaw.pfarreien(),
                    searchDataRaw.rechenschaftsberichte(),
                    searchDataRaw.KirchenVorstand(),
                    searchDataRaw.Menu(),
                    searchDataRaw.documents(),
                );

                searchData =  searchData.filter( (sDataEntry) => {
                    let filterRegex = new RegExp(searchValue, 'i');
                    return sDataEntry['value'].match(filterRegex)
                });
                let found = [];
                searchData.forEach( (sDataEntry) => {
                    if (sDataEntry.value.length > 200) {
                        let sDataEntryToPush = {
                            value: sDataEntry.value.slice(0, 200) + '...',
                            target: sDataEntry.target,
                            id: sDataEntry.id};

                        if (found.indexOf(sDataEntryToPush) === -1) {
                            found.push(sDataEntryToPush)
                        }
                    } else {
                        let sDataEntryToPush = {
                            value: sDataEntry.value,
                            target: sDataEntry.target,
                            id: sDataEntry.id
                        };
                        if (found.indexOf(sDataEntryToPush) === -1) {
                            found.push(sDataEntryToPush)
                        }
                    }
                });
                return found

            },
            turnSearchResultsOn  () {
                this.$store.dispatch('setSearchResultsOn')
            },
            turnSearchResultsOff  () {
                this.$store.dispatch('setSearchResultsOff')
            },

        },
        computed: {
            showSearchResults () {
                return this.$store.getters.showSearchResults
            }
        }
    }
</script>

<style scoped>

</style>