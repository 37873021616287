export default {
    locations: [
        {
            location: 'Alpthal',
            mitglieder: [
                {
                    name: 'Felix Beeler',
                    year: 1961,
                    profession: 'Landwirt',
                    location: 'Alpthal',
                    PLZ: 8849,
                    orignal_location: 'Alpthal',
                    adress: 'Etternstrasse 2',
                    stimmengewicht: 1
                }

            ]
        },
        {
            location: 'Galgenen',
            mitglieder: [
                {
                    name: 'Urs Ziegler',
                    year: 1960,
                    profession: 'Forstwart',
                    location: 'Altendorf',
                    PLZ: 8852,
                    orignal_location: 'Galgenen',
                    adress: 'Höhgadenstrasse 43',
                    stimmengewicht: 2
                },
                {
                    name: 'Helena Rüttimann',
                    year: 1959,
                    profession: 'Hausfrau',
                    location: 'Altendorf',
                    PLZ: 8852,
                    orignal_location: 'Galgenen',
                    adress: 'Stollen',
                    stimmengewicht: 2
                }

            ]
        },
    ]
}