export default {
    id: 33,
    name: "20240206InformationWriting",
    type: "InformationWrting",
    title: "Gesamterneuerungswahlen des Kantonskirchenrates",
    value: `
    In den Kirchgemeinden sind die Gesamterneuerungswahlen der 60 Mitglieder
des Kantonskirchenrates der Röm.-kath. Kantonalkirche Schwyz sowie je
eines Ersatzmitgliedes auf eine Amtsdauer von vier Jahren durchzuführen.
    `,
    target: "InfoSite",
    Post: true,
    date: 20240206,
    files: {
        filesTitle: "Dateien",
        filesList: [
            {
                name: "Dekret",
                path: "/documents/info_writing_for_20240206/Dekret.pdf"
            },
            {
                name: "Formular Wahlvorschläge (pdf)",
                path: "/documents/info_writing_for_20240206/Formular_Wahlvorschlaege.pdf"
            },
            {
                name: "Formular Wahlvorschläge (Word)",
                path: "/documents/info_writing_for_20240206/Formular_Wahlvorschlaege.docx"
            }
        ]
    }
}
