export default {
    documents: [
        {
            type : 'Terminliste',
            file : '/documents/Terminliste/terminliste.pdf',
            number : '',
            description : 'Terminliste für Kirchgemeinden',
            short: '',
        },
    ]
}