export default {
    rekurskommissionen: [
        {
            id: -1,
            name: "Rekurskommission",
            title: 'Präsident',
            value: 'lic. iur. Arthur Schilter <br>' +
                'Herrengasse 3 <br>' +
                'Postfach 17 <br>' +
                '6431 Schwyz <br>' +
                'Tel. +41 (0)41 811 77 01 <br>' +
                'Fax +41 (0)41 810 01 62 <br>' +
                'Email schilter@akws-law.ch <br>',
            target: "/Organisation/Rekurskommission",
            Post: false,
            date: 20141017,
        },
        {
            id: -1,
            name: "Rekurskommission",
            title: 'Mitglieder',
            value: 'lic. iur. Annegreth Fässler, Mangelegg 27, 6430 Schwyz<br>' +
                'Dr. iur. Andreas Hubli, Katrinenhof 61a, 8852 Altendorf/SZ<br>',
            target: "/Organisation/Rekurskommission",
            Post: false,
            date: 20141017,
        },
        {
            id: -1,
            name: "Rekurskommission",
            title: 'Ersatzmitglieder',
            value: 'lic. iur. Jolanda Fleischli, Seidenstrasse 2, 8853 Lachen <br>' +
                'Peter Trutmann, Badhügel 10, 6405 Immensee <br>',
            target: "/Organisation/Rekurskommission",
            Post: false,
            date: 20141017,
        },
    ]
}