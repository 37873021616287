export default {
    id: 22,
    name: "20210120InformationWriting",
    type: "InformationWrting",
    title: 'Informationsschreiben des Kantonalen ' +
    'Kirchenvorstandes per 20. Januar 2021 (Informationsschreiben)',
    subtitle: '(Informationsschreiben)',
    value: 'An  seiner  ersten  Sitzung  im  Jahr  2021  vom  20.  Januar  2021  hat  sich  der  Kantonale  Kirchenvorstand  mitmehreren Geschäften befasst, über die wir Sie ohne Verzug informieren möchten',
    target: "InfoSite",
    Post: true,
    date: 20201216,
    files: {
        filesTitle: "Brief und Formulare:",
        filesList: [
            {
                name: "Informationsschreiben vom 21. Januar 2021",
                path: '/documents/info_writing_for_20210120/Informationsschreiben.pdf'
            },
        ]
    }
}