export default {
    id: 19,
    name: "20200525InformationWriting",
    type: "InformationWrting",
    title: 'Information zur Session des Kantonskirchenrates vom 19. Juni 2020',
    subtitle: 'Verschiebung des Sessionsortes und der Anfangszeit ',
    value: 'Die konstituierende Session des Kantonskirchenrats vom 19. Juni 2020,' + 
        ' 14.00 Uhr, wird in das “Kultur- und Kongresszentrum ZWEI RABEN” ' + 
        'an der Hauptstrasse 20 in 8840 Einsiedeln verschoben werden ' + 
        '(ehemaliges “Dorfzentrum”).',
    target: "InfoSite",
    Post: true,
    date: 20200615,
    files: {
        filesTitle: "Dateien:",
        filesList: [
            {
                name: "Schreiben",
                path: '/documents/info_writing_for_20200619/Schreiben.pdf'
            },
        ]
    }
}
