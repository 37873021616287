export default {
    id: 3,
    type:"InformationWrting",
    name: 'ReferendungRKZBeitritt',
    title: 'Volksabstimmung über Referendum zum Beitritt zur RKZ/ 16. Dezember 2018\n' +
        'Medienmitteilung - Referendum gegen RKZ-Beitritt zustande gekommen',
    value: '',
    target: "InfoSite",
    Post: true,
    date: 20180821,
    files: {
        filesTitle: "Weitere Information:",
        filesList: [
            {
                name: "Volksabstimmung 16. Dezember 2018 zum Beitritt zur RKZ",
                path: '/documents/referendum_rkz_beitritt/volksabstimmung.pdf'
            },
            {
                name: "Medienmitteilung - RKZ Referendum zustande gekommen",
                path: '/documents/referendum_rkz_beitritt/medienmitteilung.pdf'
            },
        ]
    }
}