<template>
    <div class="full-width ml-0 mr-0 end-bottom-padding subpage-wrapper">
        <startseite class="full-width  ml-0 mr-0 "></startseite>
        <aktuell class="col2"></aktuell>
        <archive-note class="col2"></archive-note>
    </div>
</template>

<script lang="js">
    import Startseite from '../components/Home.vue';
    import Aktuell from '../components/Aktuell.vue';
    import archiveNote from '../components/archiv_note';

    export default {
        name: "Home",
        components: {
            Startseite,
            Aktuell,
            archiveNote,
        },
        beforeCreate() {
            this.$store.dispatch('setViewSizeToNormal');
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
    }
</script>