export default {
    id: 31,
    name: "20231101InformationWriting",
    type: "InformationWrting",
    title: "Webseite kirchensteuern-sei-dank.ch",
    value: `Die Angaben über die Leistungen der Kirchengemeinden und der Kirche im Kanton Schwyz sind auf der Webseite 
<a href="https://sz.kirchensteuern-sei-dank.ch">kirchensteuern-sei-dank.ch</a> ersichtlich. Mit dem QR-Code und den Bannern kann direkt auf diese Informationen zugegriffen werden. Die Kirchgemeinden etc. können diese Zugänge auch für ihre Homepages und Informationen verwenden. Sie sind auch bei den Mustervorlagen unter “Dokumente” herabladbar. <br>`,
    target: "InfoSite",
    Post: true,
    date: 20231101,
}
