export default {
    id: 8,
    type:"InformationWrting",
    name: 'sexuelle_uebergriffe',
    title: 'Sexuelle Übergriffe im kirchlichen Umfeld',
    subtitle: 'Richtlinien der SBK, März 2019',
    target: "InfoSite",
    Post: true,
    date: 20190324,
    files: {
        filesTitle: "Dateien: ",
        filesList: [
            {
                name: "<b>Richtlinien der Schweizer Bischofskonferenz " +
                    "und der Vereinigung der Höhern Ordensobern der Schweiz</b> <br>" +
                    "4. Auflage / Freiburg / März 2019",
                path: '/documents/sexuelle_uebergriffe/richtlinien.pdf'
            },
        ]
    }
}