import home from "./texts/home";
import sekretariat from "./texts/sekretariat";
import rekurskommissionen from "./texts/rekurskommissionen";
import informationWritings from "./texts/informationWritings";
import contact from "./texts/contact";
import links from "./texts/links";
import titles from "./texts/titles";
import aktuelles from "./texts/aktuelles";
import buero from "./texts/buero";

let texts = [
    sekretariat,
    home,
    contact,
    aktuelles,
];

let textsArray = [
    texts,
    rekurskommissionen.rekurskommissionen,
    informationWritings.informationWritings,
    links.links,
    titles.titles,
    titles.subtitles,
    buero
];

function createTexts(textsArray) {
    let textsToReturn = [];
    textsArray.forEach( (texts) => {
        texts.forEach( (text) => {
            textsToReturn.push(text)
        } )
    } );
    return textsToReturn;
}

export default {
  texts: createTexts(textsArray),
}
