<template>
    <v-app>
        <!--
---------------------------------------------------------------------------------------------------------------
mobile navigation
---------------------------------------------------------------------------------------------------------------
-->
        <v-navigation-drawer
            :clipped="clipped"
            v-model="drawer"
            temporary
            fixed
            class="hidden-lg-and-up"
        >
            <mobile-navigation></mobile-navigation>
        </v-navigation-drawer>
        <v-navigation-drawer
                :clipped="clipped"
                v-model="searchState"
                temporary
                fixed
                class="hidden-lg-and-up"
        >
            <v-container  class="text-xs-center mt-0 pt-0">
                <v-layout column allign-center fill height>
                    <v-flex d-flex xs9>
                        <search></search>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-navigation-drawer>
        <v-toolbar
            app
            :clipped-left="clipped"

            color="primary"
            class="hidden-lg-and-up white--text"
        >
            <v-toolbar-side-icon @click.stop="drawer = !drawer" dark></v-toolbar-side-icon>
            <v-icon dark @click.stop="toggleSearch()">search</v-icon>
            <v-toolbar-title v-text="title"></v-toolbar-title>
        </v-toolbar>

        <!--
        ---------------------------------------------------------------------------------------------------------------
        desktop navigation
        ---------------------------------------------------------------------------------------------------------------
        -->
        <header  id="header" class="header-wrapper">
            <nav>
                <div
                        id="navigation-wrapper"
                        class="hidden-md-and-down white--text menu-bar primary navigation-wrapper"
                >
                    <div class="menu-grid navigation-wrapper z2">
                        <div
                                v-for="(item, i) in menu"
                                :key="i"
                                class="menu-point z2"
                                @mouseover="mouseOverMenu(i)"
                                @mouseleave="mouseLeaveMenu(i)"
                        >
                            <main-desktop-nav-item :item="item" :index="i"></main-desktop-nav-item>

                        </div>
                        <div class="search-wrapper  z2">
                            <search ></search>
                        </div>
                    </div>

                </div>
            </nav>
            <div class="logo-wrapper z1 bg-white "
                 id="logo"
            >
                <logo class=" bg-white logo-centered z1"></logo>
            </div>
        </header>
        <v-content app>
            <div :class="viewSizeSmall ? 'app-wrapper-small' : 'app-wrapper'">
                <router-view class="app-content pt-0"/>
            </div>
        </v-content>
        <v-footer :fixed="fixed" app>
            <span>&copy; {{new Date().getFullYear()}}</span>
        </v-footer>
    </v-app>
</template>

<script>
import Home from './views/Home.vue';
import logo from './components/logo.vue';
import search from './components/search.vue';
import MainDesktopNavItem from "./components/navigation/DesktopMainNavItem";
import MobileNavigation from "./components/navigationMobile/MobileNavigation";


import router from './router';
import VInput from "vuetify/es5/components/VInput/VInput";
import VTextField from "vuetify/es5/components/VTextField/VTextField";
import VOverflowBtn from "vuetify/es5/components/VOverflowBtn/VOverflowBtn";

export default {
    name: 'App',
    components: {
        VOverflowBtn,
        VTextField,
        VInput,
        Home,
        logo,
        search,
        MainDesktopNavItem,
        MobileNavigation
    },
    data () {
        return {
            clipped: true,
            drawer: false,
            fixed: false,
            title: 'Römisch-katholische Kantonalkirche Schwyz',
            searchState: false,
        }
    },
    created () {
        var prevScrollpos = window.pageYOffset;
        window.onscroll = function() {
            let currentScrollPos = window.pageYOffset;
            if (prevScrollpos > currentScrollPos) {
                document.getElementById("logo").style.top = "0";
                document.getElementById("navigation-wrapper").style.top = "0"
            } else {
                document.getElementById("logo").style.top = "-200px";
                document.getElementById("navigation-wrapper").style.top = "-200px"
            }
            prevScrollpos = currentScrollPos;
        }
    },
    methods: {
        navPage (page) {
            router.push({path: page});
        },
        mouseOverMenu(menu) {
            this.menu[menu].submenuActive = true;
        },
        mouseLeaveMenu (menu) {
            this.menu[menu].submenuActive = false;
        },
        toggleSubMenuActive (menu) {
            this.menu[menu].submenuActive = !this.menu[menu].submenuActive;
        },
        SubSubMenuActive (menu, submenu) {
            this.menu[menu].submenus[submenu].subsubmenuActive = true;
        },
        SubSubMenuNotActive (menu, submenu) {
            this.menu[menu].submenus[submenu].subsubmenuActive = false;
        },
        toggleSubSubMenuActive (menu, submenu) {
            this.menu[menu].submenus[submenu].subsubmenuActive = !this.menu[menu].submenus[submenu].subsubmenuActive;
        },
        toggleSearch () {
            this.searchState = !this.searchState
        }
    },
    computed: {
        searchTexts() {
            let searchText = [];
            for (let langKey in this.$store.getters.text) {
                if (this.$store.getters.text.hasOwnProperty(langKey)) {
                    searchText.push(this.$store.getters.text[langKey].value)
                }
            }
            return searchText
        },
        menu () {
            return this.$store.getters.menu
        },
        viewSizeSmall () {
            return this.$store.getters.viewSizeSmall
        }
    }
}


</script>

<style>
    @import 'assets/css/main.css';
</style>