export default {
    mustervorlagen: {
        title: 'Mustervorlagen',
        files: [
            {
                name: 'Muster-Kirchgemeindeordnungen',
                files: [

                    {
                        name: 'Muster-Kirchgemeindeordnung (pdf)',
                        file: '/Mustervorlagen/Muster_Kirchgemeindeordnung.pdf',
                    },
                    {
                        name: 'Muster-Kirchgemeindeordnung (doc)',
                        file: '/Mustervorlagen/Muster_Kirchgemeindeordnung.docx',
                    },
                ]
            },
            {
                name: "Muster-Anstellungsverträge",
                files: [
                    {
                        name: 'Geistliche (Pfarrer, Vikare, Kapläne usw.) (doc)',
                        file: '/Mustervorlagen/Muster-Arbeitsvertrag_Geistliche_2024.docx',
                    },
                    {
                        name: 'Katechet/in (doc)',
                        file: '/Mustervorlagen/MusterArbeitsvertragKatechetin.docx',
                    },
                    {
                        name: 'Katechet/in / Mitarbeiter/in in Seelsorge (gemischt) (doc)',
                        file: '/Mustervorlagen/MusterArbeitsvertragKatechetin.docx',
                    },
                    {
                        name: 'Sigrist 100% (doc)',
                        file: '/Mustervorlagen/Muster-Arbeitsvertrag_Sigrist_Vollzeit_2022.docx',
                    },
                    {
                        name: 'Hilfssigrist im Stundenlohn (doc)',
                        file: '/Mustervorlagen/Muster-Arbeitsvertrag_Sigrist_Aushilfe_2022.docx',
                    },

                ]
            },
            {
                name: 'Wegleitung für die Anstellung von Katechetinnen und Katecheten mit Fachausbildung',
                file: '/Mustervorlagen/wegleitung_Juni_2021_end_Logo.pdf',
            },
            {
                name: 'Pensenberechnungstool für die Anstellung von Katechetinnen und Katecheten',
                file: '/Mustervorlagen/Pensenberechnungstool_KAS_New.xlsx',
            },
            {
                name: 'Richtlinien der Schweizer Bischofskonferenz und der Vereinigung ' +
                    'der Höhern Ordensobern der Schweiz (Stand März 2019)',
                file: '/Mustervorlagen/SBK_Richtlinien_Sexuelle_Uebergriffe.pdf',
            },
            {
                name: 'Schutzkonzept für die seelische , geistige und körperliche ' +
                    'Unversehrtheit der Menschen im Bereich des Bistums Chur',
                file: '/Mustervorlagen/Schutzkonzept-Chur-20190402.pdf',
            },
            {
                name: 'Merkblatt Verhaltenskodex für Kirchgemeinden',
                file: '/documents/info_writing_for_20221213/Merkblatt_Verhaltenskodex_für_Kirchgemeinden_2022.pdf',
            },
            {
                name: 'Kirchensteuern sei Dank',
                files: [
                    {
                        name: 'QR Code PDF',
                        file: '/Mustervorlagen/kirchensteuern-sei-dank-qr-code.pdf',
                    },
                    {
                        name: 'QR Code eps',
                        file: '/Mustervorlagen/kirchensteuern-sei-dank-qr-code.eps',
                    },
                    {
                        name: 'QR Code eps small',
                        file: '/Mustervorlagen/kirchensteuern-sei-dank-qr-code-small.eps',
                    },
                    {
                        name: 'Banner Rechteckig png',
                        file: '/Mustervorlagen/kirchensteuern-sei-dank-banner-rectangle.png',
                    },
                    {
                        name: 'Banner Leaderboard png',
                        file: '/Mustervorlagen/kirchensteuern-sei-dank-banner-leaderboard.png',
                    },
                    {
                        name: 'Banner Billboard png',
                        file: '/Mustervorlagen/kirchensteuern-sei-dank-banner-billboard.png',
                    },
                    {
                        name: 'Banner png',
                        file: '/Mustervorlagen/kirchensteuern-sei-dank-banner.png',
                    },
                    {
                        name: 'QR Code png',
                        file: '/Mustervorlagen/kirchensteuern-sei-dank-qr-code.png',
                    },
                    {
                        name: 'QR Code jpg',
                        file: '/Mustervorlagen/kirchensteuern-sei-dank-qr-code.jpg',
                    },

                ]
            },
            {
                name: 'Kirchenaustritt',
                files: [
                    {
                        name: 'Leitlinien betreffend Austritterklärungen aus der Kirchgemeinde bzw. Kantonalen' +
                            ' Körperschaften (so genannte „Kirchenaustritte")',
                        file: '/Mustervorlagen/20180301120430.pdf',
                    },
                ]
            }
        ]
    }
}
