export default {
    id: 26,
    name: "20221116InformationWriting",
    type: "InformationWrting",
    title: 'Einladung zur Fortsetzung der Diskussion zu den Perspektiven der Kirchenentwicklung',
    value: `
    Teil 1: <br>
    Zur weiteren Fortführung der Diskussion zu den Perspektiven der Kirchenentwicklung wird ein entsprechendes Fachreferat mit Vertretern des Schweizerischen Pastoralsoziologischen Instituts St. Gallen (SPI) angeboten, das in zwei Teilen abgehalten wird: <br>
    Dienstag, 11. Oktober 2022, von 19.00 - ca. 20.30 Uhr, mittels eines Webinars<br>
    Freitag, 21. Oktober 2022, um ca. 17.00 Uhr, im Schweizerischen Jugend- und Bildungs-Zentrum SJBZ, Lincolnweg 23, 8840 Einsiedeln (d.h. im Anschluss an die Session des Kantonskirchenrats)
    Beide Anlässe stehen nebst den Mitgliedern des Kantonskirchenrats ausdrücklich auch Mitgliedern der Kirchenräte und weiteren interessierten Personen offen.<br>
    Für die Planung wird um eine Anmeldung mit Namensangabe und der Mitteilung, ob es um das Webinar und/oder das Referat geht, bis spätestens am 6. Oktober 2022 ersucht auf: sekretariat@sz.kath.ch.
    <br>
    <br>
    Teil 2: <br>
    Zur weiteren Fortführung der Diskussion zu den Perspektiven der Kirchenentwicklung wird ein entsprechendes Fachreferat mit Vertretern des Schweizerischen Pastoralsoziologischen Instituts St. Gallen (SPI) angeboten, das in zwei Teilen abgehalten wird: <br>
    Freitag, 9. Dezember 2022, von 16.00 - ca. 18.00 Uhr, mittels eines Webinars<br>
    `,
    target: "InfoSite",
    Post: true,
    date: 20221116,
    files: {
        filesTitle: "Dateien",
        filesList: [
            {
                name: "Perspektiven der Kirchenentwicklung - Einladung 1",
                path: '/documents/info_writing_for_20221021/Perspektiven_der_Kirchenentwicklung_-_Einladung.pdf'
            },
            {
                name: "Perspektiven der Kirchenentwicklung - Präsentation 1",
                path: '/documents/info_writing_for_20221021/Perspektiven_der_Kirchenentwicklung_-Praesentation.pdf'
            },
            {
                name: "Perspektiven der Kirchenentwicklung - Einladung 2",
                path: '/documents/info_writing_for_20221021/Perspektiven_der_Kirchenentwicklung_-_Einladung_neu.pdf'
            },
            {
                name: "Perspektiven der Kirchenentwicklung - Präsentation 2 - Kirche – quo vadis?",
                path: '/documents/info_writing_for_20221021/Kantonskirchenrat_Schwyz_in_Einsiedeln_Dez22.pdf'
            },
        ]
    }
}