export default {
    rechenschaftsberichte: {
        title: 'Rechenschaftsberichte',
        berichte: [
            {
                file : '/rechenschaftsberichte/Rechenschaftsbericht_2023.pdf',
                year: '2023'
            },
            {
                file : '/rechenschaftsberichte/Rechenschaftsbericht_2022.pdf',
                year: '2022'
            },
            {
                file : '/rechenschaftsberichte/Rechenschaftsbericht_2021.pdf',
                year: '2021'
            },
            {
                file : '/rechenschaftsberichte/rechenschaftsbericht_2020.pdf',
                year: '2020'
            },
            {
                file : '/rechenschaftsberichte/rechenschaftsbericht_2019.pdf',
                year: '2019'
            },
            {
                file : '/rechenschaftsberichte/20190522180919.pdf',
                year: '2018'
            },
            {
                file : '/rechenschaftsberichte/20180428231240.pdf',
                year: '2017'
            },
            {
                file : '/rechenschaftsberichte/20170424192009.pdf',
                year: '2016'
            },
            {
                file : '/rechenschaftsberichte/20160428110004.pdf',
                year: '2015'
            },
            {
                file : '/rechenschaftsberichte/20150401100916.pdf',
                year: '2014'
            },
            {
                file : '/rechenschaftsberichte/20140405194729.pdf',
                year: '2013'
            },
            {
                file : '/rechenschaftsberichte/20130403230406.pdf',
                year: '2012'
            },
            {
                file : '/rechenschaftsberichte/20120405173759.pdf',
                year: '2011'
            },
            {
                file : '/rechenschaftsberichte/Rechenschaftsbericht_2010.pdf',
                year: '2010'
            },
            {
                file : '/rechenschaftsberichte/Rechenschaftsbericht_2009.pdf',
                year: '2009'
            },
            {
                file : '/rechenschaftsberichte/Rechenschaftsbericht_2008.pdf',
                year: '2008'
            },
            {
                file : '/rechenschaftsberichte/Rechenschaftsbericht_2007.pdf',
                year: '2007'
            },
            {
                file : '/rechenschaftsberichte/RB_2006.pdf',
                year: '2006'
            },
            {
                file : '/rechenschaftsberichte/RB_2005.pdf',
                year: '2005'
            },
            {
                file : '/rechenschaftsberichte/Rechenschaftsbericht_2004.pdf',
                year: '2004'
            },
            {
                file : '/rechenschaftsberichte/Rechenschaftsbericht_2003.pdf',
                year: '2003'
            },
            {
                file : '/rechenschaftsberichte/Rechenschaftsbericht_2002.pdf',
                year: '2002'
            },
            {
                file : '/rechenschaftsberichte/Rechenschaftsbericht_2001.pdf',
                year: '2001'
            },
            {
                file : '/rechenschaftsberichte/20160607170311.pdf',
                year: '2000'
            },
            {
                file : '/rechenschaftsberichte/20160607170239.pdf',
                year: '1999'
            },

        ]
    }
}