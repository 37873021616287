export default {
    id: -1,
    name: "sekretariat",
    title: 'Linus Bruhin',
    value: "Röm.-kath. Kantonalkirche Schwyz <br>" +
        'Sekretariat<br>' +
        'Dr. iur. Linus Bruhin<br>' +
        'Leutschenstrasse 9<br>' +
        '8807 Freienbach<br>' +
        '<br>' +
        '<b>Briefadresse:<br></b>' +
        'Postfach 137<br>' +
        '8808 Pfäffikon<br>' +
        '<br>' +
        'Tel.    055 415 50 56<br>' +
        'E-Mail sekretariat@sz.kath.ch<br>' +
        '<br>' +
        '<br>' +
        '<b>Bankverbindung </b><br>' +
        '<br>' +
        'Bankverbindung bei der Schwyzer Kantonalbank<br>' +
        '6431 Schwyz<br>' +
        'Römisch-katholische Kantonalkirche Schwyz<br>' +
        'IBAN: CH07 077 7003 1252 9100 2<br>',
    target: "/Organisation/Sekretariat",
    Post: false,
    date: 20141017,
}