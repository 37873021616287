export default {
    id: 6,
    type:"InformationWrtingWithAppointment",
    name: 'meldungSteuersatz20181126',
    title: 'Meldung Steuersatz und Voranschlag für das Jahr 2019' +
        ' per 1. Januar 2019',
    value: 'Für die Berechnungen zum Finanzausgleich und dem Voranschlag der Kantonalkirche ' +
        'ist u.a. auch der dann von der Kirchgemeindeversammlung beschlossene Steuersatz ' +
        'der einzelnen Kirchgemeinden massgebend.Ebenso benötigt die kantonale Steuerverwaltung ' +
        'bereits auf Anfang Januar 2019 diese Angaben der Kirchgemeinden, ' +
        'welche ihr vom Kantonalen Kirchenvorstand weitergeleitet werden.',
    target: "InfoSite",
    Post: false,
    date: 20181126,
    termin: "Termin: Dienstag, 18. Dezember 2018",
    files: {
        filesTitle: "Brief und Formulare:",
        filesList: [
            {
                name: "Meldung Steuersatz per 1. Januar 2019 (pdf)",
                path: '/documents/meldung_steuersatz_2019/steuersatz.pdf'
            },
            {
                name: "Meldung Steuersatz per 1. Januar 2019 (Word)",
                path: '/documents/meldung_steuersatz_2019/steuersatz.docx'
            },
        ]
    }
}