export const filelist = [
    {
        value: "1 Sessionseinladung",
        link: "/protocols/20240925/1_Sessionseinladung.pdf"
    },
    {
        value: "2 Beilagenblatt",
        link: "/protocols/20240925/2_Beilagenblatt.pdf"
    },
    {
        value: "3 Berufseinführung",
        link: "/protocols/20240925/3_Berufseinfuehrung.pdf"
    },
    {
        value: "4 Jubilate",
        link: "/protocols/20240925/4_Jubilate.pdf"
    },
    {
        value: "5 GPK-Bericht",
        link: "/protocols/20240925/5_GPK-Bericht.pdf"
    },
    {
        value: "6 Voranschlag KVS 2025",
        link: "/protocols/20240925/6_Voranschlag_KVS_2025.pdf"
    },
    {
        value: "7 KAS Budget 2025",
        link: "/protocols/20240925/7_KAS_Budget_2025.pdf"
    },
    {
        value: "8 ASS Voranschlag 2025",
        link: "/protocols/20240925/8_ASS_Voranschlag_2025.pdf"
    },
    {
        value: "9 Bericht zum Voranschlag 2025",
        link: "/protocols/20240925/9_Bericht_zum_Voranschlag_2025.pdf"
    },
    {
        value: "10 Finanzplanung 2025-2028",
        link: "/protocols/20240925/10_Finanzplanung_2025-2028.pdf"
    },
    {
        value: "11 Finanzausgleich 2025",
        link: "/protocols/20240925/11_Finanzausgleich_2025.pdf"
    },
    {
        value: "12 Finanzausgleich 2019-2025 Varianten",
        link: "/protocols/20240925/12_Finanzausgleich_2019-2025_Varianten.pdf"
    },
    {
        value: "13 Informationen Kirchgemeinden",
        link: "/protocols/20240925/13_Informationen_Kirchgemeinden.pdf"
    },
    {
        value: "14 Bericht Strategie für Vernehmlassung",
        link: "/protocols/20240925/14_Bericht_Strategie_fuer_Vernehmlassung.pdf"
    },
    {
        value: "10 Finanzplanung 2025-2028",
        link: "/protocols/20240925/10_Finanzplanung_2025-2028.pdf"
    },
    {
        value: "11 Finanzausgleich 2025",
        link: "/protocols/20240925/11_Finanzausgleich_2025.pdf"
    },
    {
        value: "12 Finanzausgleich 2019-2025 Varianten",
        link: "/protocols/20240925/12_Finanzausgleich_2019-2025_Varianten.pdf"
    },
    {
        value: "13 Informationen Kirchgemeinden",
        link: "/protocols/20240925/13_Informationen_Kirchgemeinden.pdf"
    },
    {
        value: "14 Bericht Strategie f�r Vernehmlassung",
        link: "/protocols/20240925/14_Bericht_Strategie_fuer_Vernehmlassung.pdf"
    },
    {
        value: "15 Online-Formular für die Vernehmlassungsantwort: https://forms.office.com/r/PNrYCEYbm5",
        link: " https://forms.office.com/r/PNrYCEYbm5"
    },
    {
        value: "16 Motion Diakonie",
        link: "/protocols/20240925/16_Motion_Diakonie.pdf"
    },
]