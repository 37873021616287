export default {
    id: 20181019,
    date: 20181019,
    uploadDate: '09.11.2018',
    title: 'Session des Kantonskirchenrates vom 19. Oktober 2018',
    shortDescriptionString: 'Sessionsprotokoll / Informationsschreiben / ' +
        'Ergebnisse in Kurzform und Unterlagen zu den einzelnen Traktanden',
    subtitle: 'Der Kantonskirchenrat hielt seine ordentliche Herbstsession am Freitag, 20. Oktober 2017, 14.00 Uhr, ' +
        'im Schweizerischen Jugend- und Bildungszentrum SJBZ in Einsiedeln ab.',
    sessionDate: '19.10.2018',
    resultPdfs: [
        {
            value: 'Protokoll der Session vom 19. Oktober 2018',
            link: '/protocols/20181019/protokoll.pdf',
        },
        {
            value: 'Informationsschreiben vom 8. November 2019',
            link: '/protocols/20181019/informationsschreiben.pdf',
        },
    ],
    shortResults: [
        {
            name: 'sr1',
            value: 'Der Nachkredit von Fr. 13’000.-- für das Jahr 2018 als Beitrag an die Kosten des Papstbesuchs ' +
                'vom 21. Juni 2018 in Genf wurde gewährt.'
        },
        {
            name: 'sr2',
            value: 'Da die Referendumsabstimmung über den RKZ-Beitritt nicht am 16. Dezember 2018 stattfinden wird, ' +
                'musste der dafür nötige Nachkredit nicht behandelt werden. Sofern diese Abstimmung im Jahr 2019 ' +
                'dann doch noch durchzuführen sein wird, ist dann ein entsprechender Nachkredit zu beantragen. '
        },
        {
            name: 'sr3',
            value: 'Der Voranschlag 2019 wurde gemäss dem unveränderten Antrag mit einem Total von Fr. 1’887’624.50 ' +
                'und dem Pro-Kopf-Beitrag an die Kantonalkirche von gesamthaft Fr. 19.80 beschlossen. ' +
                'Falls die Kantonalkirche jedoch im Jahr 2019 der RKZ nicht beitreten könnte, ' +
                'würde sich das Total auf Fr. 1’708’124.50 bei einer Kopfquote von Fr. 17.90 belaufen. '
        },
        {
            name: 'sr4',
            value: 'Der Finanzausgleich 2019 wurde gemäss dem unveränderten Antrag mit einem Ausgleich des ' +
                'Normleistungsaufwandes von Fr. 253.25 (d.h. einem Ausgleich der Normkosten zu 87.5%) ' +
                'und mit einem Gesamtbudget von Fr. 1’039’362.-- beschlossen. '
        },
        {
            name: 'sr5',
            value: 'Bei der Ersatzwahl des Vizepräsidiums des Kantonskirchenrats konnte keine Nachfolge für ' +
                'Antonia Fässler gewählt werden. Da Antonia Fässler ihren Rücktritt um ein halbes Jahr ' +
                'hinausgeschoben hat, wird diese Wahl somit an der Frühlingssession 2019 nochmals zu ' +
                'traktandieren sein. Wählbar ist jedes Mitglied des Kantonskirchenrats.'
        },{
            name: 'sr6',
            value: 'In die vorberatende Kommission für das “Gesetz über die Katechetische Arbeitsstelle ' +
                'der Römisch-katholischen Kantonalkirche Schwyz” wurden antragsgemäss Aurelia Imlig (als Präsidentin), ' +
                'Claude Camenzind, Mily Samaz, Konrad Schelbert und Bruno Wiederkehr einstimmig gewählt.'
        },
    ],
    Traktanden: [
        {
            name: 'tt1',
            value: 'Gebet, Begrüssung und Präsenz',
        },
        {
            name: 'tt2',
            value: 'Nachkredit zugunsten des Papstbesuchs 2018',
        },
        {
            name: 'tt3',
            value: 'Nachkredit für die Referendumsabstimmung zum RKZ-Beitritt 2018',
        },
        {
            name: 'tt4',
            value: 'Beschluss über den Voranschlag 2019',
        },
        {
            name: 'tt5',
            value: 'Beschluss über den Finanzausgleich 2019',
        },
        {
            name: 'tt6',
            value: 'Ersatzwahl des Vizepräsidiums des Kantonskirchenrats',
        },
        {
            name: 'tt7',
            value: 'Wahl einer vorberatenden Kommission für die Übernahme der Organisation der Katechetischen ' +
                'Arbeitsstelle durch die Kantonalkirche',
        },
        {
            name: 'tt8',
            value: 'Informationen der Ressortchefs und Fragestunde',
        },
        {
            name: 'tt9',
            value: 'Verschiedenes',
        },
    ],
    Unterlagen: [
        {
            value: 'Sessionseinladung',
            link: '/protocols/20181019/sessionseinladung.pdf',
        },
        {
            value: 'Beilagenblatt',
            link: '/protocols/20181019/beilagenblatt.pdf',
        },
        {
            value: 'Antrag Nachkredit für Papstbesuch',
            link: '/protocols/20181019/nk_papstbesuch.pdf',
        },
        {
            value: 'Nachkredit Referendumsabstimmung',
            link: '/protocols/20181019/nk_referendungsabstimmung.pdf',
        },
        {
            value: 'Antrag Voranschlag 2019',
            link: '/protocols/20181019/antrag_voranschlag.pdf',
        },
        {
            value: 'Bericht zum Voranschlag 2019',
            link: '/protocols/20181019/bericht_vorschlag_2019.pdf',
        },
        {
            value: 'Finanzplanung 2019-2022',
            link: '/protocols/20181019/finanzplanung.pdf',
        },
        {
            value: 'Anderssprachigenseelsorge Voranschlag 2019',
            link: '/protocols/20181019/anderssprachigen_seelsorge.pdf',
        },
        {
            value: 'Antrag Finanzausgleich 2019 (87.5%)',
            link: '/protocols/20181019/antrag_finanzausgleich.pdf',
        },
        {
            value: 'Übersicht Finanzausgleich 2010 - 2019',
            link: '/protocols/20181019/finanzausgleich.pdf',
        },
        {
            value: 'Finanzausgleich 2019 -Seite 11 (87.5%)',
            link: '/protocols/20181019/finanzausgleich_s11_87.pdf',
        },
        {
            value: 'Finanzausgleich 2019 -Seite 11 (90%)',
            link: '/protocols/20181019/finanzausgleich_s11_90.pdf',
        },
        {
            value: 'Bericht und Antrag der GPK an den Kantonskirchenrat',
            link: '/protocols/20181019/b_u_a_gpk',
        },
        {
            value: 'Bericht und Antrag Übernahme KAS',
            link: '/protocols/20181019/b_u_a_uebernahme_KAS.pdf',
        },
    ]
}