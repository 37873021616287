const numberOfInformationWrtings = 100;
const importetInformationWritings = {};
for(let i = 0; i < numberOfInformationWrtings; i++) {
    try {
        importetInformationWritings[i] = require('./informationWritings/' + i);
    }
    catch( noMoreFilesError ){
        break;
    }
}
function createInformationWritings( importetInformationWritings ) {
    let informatinWritingsArray = [];
    if (importetInformationWritings.hasOwnProperty('0')){

        for (let infoWritingKey in importetInformationWritings) {
            if (importetInformationWritings.hasOwnProperty(infoWritingKey)){
                informatinWritingsArray.unshift(importetInformationWritings[infoWritingKey].default)
            }

        }
    }
    return informatinWritingsArray;
}
const infoWritingsFinal = createInformationWritings(importetInformationWritings);

export default {
    informationWritings: infoWritingsFinal
}