<template>
    <div>
        <div class="menu-point-font mb-3">
            {{item.title}}
        </div>
        <v-list v-show="item.submenuActive"
                class="red accent-4 pa-0 mt-1"
                v-for="(submenu, j) in item.submenus"
                :key="j"
                dark
        >

            <v-list-group v-if="submenu.subsubmenus"
                          class="text-xs-left white--text ma-1"
            >
                <v-list-tile slot="activator">
                    <v-list-tile-title v-text="submenu.title"
                                       @click.stop="navPage(submenu.target)"
                    >

                    </v-list-tile-title>

                </v-list-tile>

                <v-list-tile
                        v-for="(subsubmenu, k) in submenu.subsubmenus"
                        :key="k"
                        @click.stop="navPage(subsubmenu.target)"
                >
                    <v-list-tile-content>
                        <v-list-tile-title>
                            {{subsubmenu.title}}
                        </v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
            </v-list-group>

            <v-list-tile v-else
                         @click.stop="navPage(submenu.target)"
                         class="text-xs-left white--text ma-1">
                <v-list-tile-title v-text="submenu.title"></v-list-tile-title>
            </v-list-tile>
        </v-list>
    </div>
</template>

<script>

    import router from '../../router';
    export default {
        name: "DesktopSubmenuItem",
        props: [
            'item'
        ],
        methods: {
            navPage (page) {
                router.push({path: page});
            },
            mouseOverMenu(menu) {
                this.menu[menu].submenuActive = true;
            },
            mouseLeaveMenu (menu) {
                this.menu[menu].submenuActive = false;
            },
            toggleSubMenuActive (menu) {
                this.menu[menu].submenuActive = !this.menu[menu].submenuActive;
            },
            SubSubMenuActive (menu, submenu) {
                this.menu[menu].submenus[submenu].subsubmenuActive = true;
            },
            SubSubMenuNotActive (menu, submenu) {
                this.menu[menu].submenus[submenu].subsubmenuActive = false;
            },
            toggleSubSubMenuActive (menu, submenu) {
                this.menu[menu].submenus[submenu].subsubmenuActive =
                    !this.menu[menu].submenus[submenu].subsubmenuActive;
            },
            toggleSearch () {
                this.searchState = !this.searchState
            }
        },
        computed: {
            searchTexts() {
                let searchText = [];
                for (let langKey in this.$store.getters.text) {
                    if (this.$store.getters.text.hasOwnProperty(langKey)) {
                        searchText.push(this.$store.getters.text[langKey].value)
                    }
                }
                return searchText
            },
            menu () {
                return this.$store.getters.menu
            }

        }
    }
</script>

<style scoped>

</style>