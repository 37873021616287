export default {
    id: 28,
    name: "20230124InformationWriting",
    type: "InformationWrting",
    title: 'Einladung an die Schulung von Mitgliedern der Kirchenräte etc. zur Umsetzung des Verhaltenskodexes zum Umgang mit Macht und zur Prävention von Machtmissbrauch im Bistum Chur im Kanton Schwyz',
    value: `Alle Mitglieder der Kirchenräte, des Kantonskirchenrates und weitere interessierte Personen 
sind für die Schulung zur Umsetzung des Verhaltenskodexes zum Umgang mit Macht und zur Prävention von Machtmissbrauch im Bistum Chur 
im Kanton Schwyz eingeladen auf den 25. 
Februar um 9.00 Uhr bis ca. 12.00 Uhr Schweizerisches Jugend- und Bildungs-Zentrum SJBZ, Lincolnweg 23, 8840 Einsiedeln.`,
    target: "InfoSite",
    Post: true,
    date: 20230124,
    files: {
        filesTitle: "Dateien",
        filesList: [
            {
                name: "Verhaltenscodex 2023 - Einladung",
                path: '/documents/info_writing_for_20230124/Verhaltenskodex_2023_Einladung.pdf'
            },
            {
                name: "Merkblatt Verhaltenscodex für Kirchgemeinden",
                path: '/documents/info_writing_for_20230124/Merkblatt_Verhaltenskodex.pdf'
            }
        ]
    }
}