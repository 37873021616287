export default {
    titles: [
        {
            target: '/Aktuelles',
            value: 'Aktuelles',
            key: 'Aktuelles',
        },
        {
            target: '/Organisation/Sekretariat',
            value: 'Sekretariat',
            key: 'Sekretariat',
        },
        {
            target: '/Organisation/Kantonskirchenrat',
            value: 'Verzeichnis der Mitglieder',
            key: 'kkrMitglieder',
        },
        {
            target: '/Organisation/Kantonskirchenrat',
            value: 'Büro des Kantonskirchenrates',
            key: 'kkrBuero',
        },
        {
            target: '/Organisation/Kantonskirchenrat',
            value: 'Sekretariat für den Kantonskirchenrat und für den Kantonalen Kirchenvorstand',
            key: 'kkrSekretariat',
        },
        {
            target: '/Organisation/Protokolle',
            value: 'Protokolle',
            key: 'Protokolle',
        },
        {
            target: '/Organisation/Kirchenvorstand',
            value: 'Kantonaler Kirchenvorstand',
            key: 'Kirchenvorstand',
        },
        {
            target: '/Organisation/Kirchenvorstand',
            value: 'Rechenschaftsberichte',
            key: 'Rechenschaftsberichte',
        },
        {
            target: '/Organisation/Rekurskommission',
            value: 'Rekurskommission',
            key: 'Rekurskommission',
        },
        {
            target: '/Dokumente/Rechtssammlung',
            value: 'Systematische Rechtssammlung der Röm.-kath. Kantonalkirche Schwyz',
            key: 'Rechtssammlung',
        },
        {
            target: '/Dokumente/Finanzausgleich',
            value: 'Finanzausgleich',
            key: 'Finanzausgleich'
        },
        {
            target: '/Dokumente/Mustervorlagen',
            value: 'Mustervorlagen',
            key: 'Mustervorlagen'
        },
        {
            target: '/Dokumente/Archiv',
            value: 'Archiv (älter als 2 Jahre)',
            key: 'Archiv'
        },
        {
            target: '/Kirchgemeinden',
            value: 'Kirchgemeinden',
            key: 'Kirchgemeinden',
        },
        {
            target: '/Kirchgemeinden/PraesidentInnen',
            value: 'Präsidentinnen und Präsidenten',
            key: 'PraesidentInnen',
        },
        {
            target: '/Kirchgemeinden/Pfarreien',
            value: 'Pfarreien',
            key: 'Pfarreien',
        },
        {
            target: '/Links',
            value: 'Links',
            key: 'Links',
        },
        {
            target: '/Contact',
            value: 'Info',
            key: 'Contact',
        },
    ],
    subtitles: [

        {
            target: '/Organisation/Rekurskommission',
            value: 'Präsident',
            key: 'Praesidaent',
        },
        {
            target: '/Organisation/Rekurskommission',
            value: 'Mitglieder',
            key: 'Mitglieder',
        },
        {
            target: '/Organisation/Rekurskommission',
            value: 'Ersatzmitglieder',
            key: 'Ersatzmitglieder',
        },

        {
            target: '/Dokumente/Rechtssammlung',
            value: 'Nr.',
            key: 'Nr',
        },
        {
            target: '/Dokumente/Rechtssammlung',
            value: 'Erlass',
            key: 'Erlass',
        },
        {
            target: '/Dokumente/Rechtssammlung',
            value: 'Abkürzung',
            key: 'Abk',
        },
        {
            target: '/Dokumente/Finanzausgleich',
            value: 'Gesetzliche Grundlagen',
            key: 'Grundlagen',
        },
        {
            target: '/Dokumente/Finanzausgleich',
            value: 'Finanzausgleiche',
            key: 'Finanzausgleiche',
        },
        {
            target: '/Kirchgemeinden/Pfarreien',
            value: 'Dekanat Innerschwyz',
            key: 'DekanatInnerschwyz',
        },
        {
            target: '/Kirchgemeinden/Pfarreien',
            value: 'Dekanat Ausserschwyz',
            key: 'DekanatAusserschwyz',
        },
    ],
}