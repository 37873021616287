export default {
    id: 14,
    name: "20200129InformationWriting",
    type: "InformationWrting",
    title: 'Informationsabend “Auf das Zusammenspiel kommt es an; die Kirche im dualen System”',
    subtitle: 'Freitag, 6. März 2020, 19.30 Uhr, im Schweizerischen Jugend- und Bildungs-Zentrum SJBZ' +
        "Lincolnweg 23, 8840 Einsiedeln",
    value: 'für alle Mitglieder von Kirchenräten, für die Mitglieder des Kantonskirchenrats ' +
        'sowie für sonstige interessierte Personen',
    target: "InfoSite",
    Post: true,
    date: 20200129,
    files: {
        filesTitle: "Einladung",
        filesList: [
            {
                name: "Einladung vom 27. Januar 2020",
                path: '/documents/info_writing_for_20200129/Einladung_Informationsabend.pdf'
            },
        ]
    }
}