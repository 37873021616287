export default {
    id: 23,
    name: "20210120InformationWriting",
    type: "InformationWrting",
    title: 'Referendumsabstimmung bezüglich der Einführung eines Stimm- und Wahlrechts für Katholiken ohne Schweizer Bürgerrecht in der Röm.-kath. Kantonalkirche Schwyz vom 27. Juni 2021',
    subtitle: 'Abstimmungsergebnisse / Karte / Aufruf Präsident / Referendumsbotschaft',
    value: 'Die Abstimmungsfrage lautet: “Wollen Sie den Beschluss des Kantonskirchenrates vom 23. Oktober 2020 über die Einführung eines Stimm- und Wahlrechts für Katholiken ohne Schweizer Bürgerrecht in der Römisch-katholischen Kantonalkirche Schwyz annehmen?” <br><br>'+
    'Abstimmungsbotschaft<br>'+
    'Aufruf des Präsidenten des Kantonalen Kirchenvorstandes<br><br>'+
    'Die Abstimmungsergebnisse werden am Nachmittag des 27. Juni 2021 laufend aktualisiert.<br><br><br>',
    target: "InfoSite",
    Post: true,
    date: 20210627,
    files: {
        filesTitle: "Brief und Formulare:",
        filesList: [
            {
                name: "<br><b>Endresultat</b><br><br>",
                path: '/documents/info_writing_for_20210627/Endresultate_Abstimmung.pdf'
            },
            {
                name: "Karte<br>",
                path: '/documents/info_writing_for_20210627/Karte.pdf'
            },
            {
                name: "Aufruf Präsident",
                path: '/documents/info_writing_for_20210627/Aufruf_Praesident.docx'
            },
            {
                name: "Referendumsbotschaft",
                path: '/documents/info_writing_for_20210627/Referendumsbotschaft.pdf'
            },
        ]
    }
}