import sessionsProtokolle from "./sessionsProtokolle/sessionsProtokolle";

export default {
    protokolle: {
        target: 'ProtokolleDetailed',
        headers: [
            {
                text: 'Art',
                align: 'left',
                sortable: false,
                value: 'title',
            },
            {   text: 'Datum (Session)',
                align: 'left',
                sortable: false,
                value: 'sessionDate',
            },
        ],
        pagination: {
            rowsPerPage: -1
        },
        gleichbleibendeTitel: {
            shortResults: 'Die Ergebnisse in Kurzform',
            traktanden: 'Traktanden',
            unterlagen: 'Unterlagen zur Session',
        },
        SessionProtokolle: sessionsProtokolle.sessionsProtokolle

    }
}