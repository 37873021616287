import rechtssammlung from "./documents/rechtssammlung";
import rechtssammlung_old from "./documents/rechtssammlung_old";
import finanzausgleiche from "./documents/finanzausgleiche";
import finanzausgleichGesetze from "./documents/finanzausgleichGesetze";
import terminlisten from "./documents/terminlisten";

const documentsPartsList = [
    rechtssammlung,
    rechtssammlung_old,
    finanzausgleiche,
    finanzausgleichGesetze,
    terminlisten
];

function createArrays (documentsParts){
    let arrayToReturn = [];
    documentsParts.forEach( documentsPart => {
        arrayToReturn.push(documentsPart.documents)
    });
    return arrayToReturn
}

function createDocumentsFromArray (arrays) {
    return [].concat.apply([],arrays);
}

function createDocuments (){
    let arrays = createArrays(documentsPartsList);
    return createDocumentsFromArray(arrays);
}

export default {
    documents: createDocuments()
}