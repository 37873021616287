export default {
    id: 20191025,
    date: 20191025,
    uploadDate: '25.10.2019',
    title: 'Session des Kantonskirchenrates vom 25. Oktober 2019   ',
    shortDescriptionString: 'Sessionsprotokoll / Informationsschreiben / Ergebnisse in Kurzform ' +
        '/ Traktanden / Unterlagen zur Session',
    subtitle: 'Der Kantonskirchenrat hielt seine ordentliche Herbstsession ab am Freitag, 25. Oktober 2019,' +
        '<br>im Schweizerischen Jugend- und Bildungszentrum SJBZ in Einsiedeln.',
    sessionDate: '25.10.2019',
    resultPdfs: [
        {
            value: 'Protokoll der Session vom 25. Oktober 2019',
            link: '/protocols/20191025/protokoll.pdf',
        },
        {
            value: 'Informationsschreiben vom 8. November 2019',
            link: '/protocols/20191025/informationsschreiben.pdf',
        },
    ],
    shortResults: [
        {
            name: 'sr1',
            value: 'Der Voranschlag 2020 wurde gemäss dem unveränderten Antrag mit einem Total von' +
                'Fr. 2’017’933.50 und dem Pro-Kopf-Beitrag ' +
                'an die Kantonalkirche von gesamthaft Fr. 21.40 beschlossen.'
        },
        {
            name: 'sr2',
            value: 'Der Finanzausgleich 2020 wurde gemäss dem unveränderten Antrag ' +
                'mit einem Ausgleich des Normleistungsaufwandes von Fr. 257.85 ' +
                '(d.h. einem Ausgleich der Normkosten zu 87.5%) ' +
                'und mit einem Gesamtbudget von Fr. 1’072’856.-- beschlossen.'
        },
    ],
    Traktanden: [
        {
            name: 'tt1',
            value: 'Gebet, Begrüssung und Präsenz',
        },
        {
            name: 'tt2',
            value: 'Beschluss über den Voranschlag 2020',
        },
        {
            name: 'tt3',
            value: 'Beschluss über den Finanzausgleich 2020',
        },
        {
            name: 'tt4',
            value: 'Informationen der Ressortchefs und Fragestunde',
        },
        {
            name: 'tt5',
            value: 'Verschiedenes',
        },
    ],
    Unterlagen: [
        {
            value: 'Sessionseinladung',
            link: '/protocols/20191025/sessionseinladung.pdf',
        },
        {
            value: 'Beilagenblatt',
            link: '/protocols/20191025/beilagenblatt.pdf',
        },
        {
            value: 'Antrag Voranschlag 2020',
            link: '/protocols/20191025/antrag_voranschlag.pdf',
        },
        {
            value: 'Bericht zum Voranschlag 2020',
            link: '/protocols/20191025/bericht_zum_voranschlag.pdf',
        },
        {
            value: 'Anderssprachigen-Seelsorge - Voranschlag 2020',
            link: '/protocols/20191025/anderssprachigen_seelsorge.pdf',
        },
        {
            value: 'KAS Voranschlag 2020',
            link: '/protocols/20191025/kas_voranschlag.pdf',
        },
        {
            value: 'Chance Kirchenberufe ab 2020',
            link: '/protocols/20191025/chance_kirchenberufe.pdf',
        },
        {
            value: 'Finanzplanung 2020-2023',
            link: '/protocols/20191025/finanz_planung.pdf',
        },
        {
            value: 'Antrag Finanzausgleich 2020',
            link: '/protocols/20191025/antrag_finanzausgleich.pdf',
        },
        {
            value: 'Beilagenblatt',
            link: '/protocols/20191025/finanzausgleich.pdf',
        },
        {
            value: 'Antrag Voranschlag 2020',
            link: '/protocols/20191025/Finanzausgleich 2011-2020.pdf',
        },
        {
            value: 'Bericht und Antrag der GPK an den Kantonskirchenrat',
            link: '/protocols/20191025/bericht_und_antrag.pdf',
        },
    ]
}