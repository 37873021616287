export default {
    id: 10,
    type:"InformationWrtingForVoteResult",
    name: 'Volksabstimmung20190630',
    title: 'Endergebnis der Volksabstimmung über den Beitritt der Kantonalkirche zur RKZ - 30. Juni 2019',
    subtitle: 'Ergebnisse nach Gemeinden / Medienmitteilung',
    value: '<div class="sz-kath-title font-weight-bold">Die Abstimmungsfrage lautete: </div>' +
        '<div class="font-weight-bold">«Wollen Sie den Beschluss des Kantonskirchenrates vom 25. Mai 2018 ' +
        'über den Beitritt der Römisch­-katholischen Kantonalkirche Schwyz ' +
        'zur Römisch­-Katholischen Zentralkonferenz der Schweiz (RKZ) annehmen?» </div>',
    target: "InfoSite",
    Post: true,
    date: 20190630,
    tables: [
        {
            title: "Endergebnis",
            headers: [
                {text: "20% Stimmbeteiligung", value: "label"},
                {text: "Ja", value: "yes"},
                {text: "Nein", value: "no"}
            ],
            data: [
                {
                    label: "Total Stimmen Kanton",
                    yes: 6791,
                    no: 6664
                },
                {
                    label: "Verteilung in Prozenten",
                    yes: 50.5,
                    no: 49.5
                }
            ]
        },
        {
            title: "Kirchgemeinden",
            headers: [
                {text: "Kirchgemeinden", value: "label"},
                {text: "Ja", value: "yes"},
                {text: "Nein", value: "no"}
            ],
            data: [
                {
                    label: "Alpthal",
                    yes: 18,
                    no: 64
                },
                {
                    label: "Altendorf",
                    yes: 263,
                    no: 184
                },
                {
                    label: "Arth",
                    yes: 255,
                    no: 165
                },
                {
                    label: "Buttikon",
                    yes: 46,
                    no: 83
                },
                {
                    label: "Einsiedeln",
                    yes: 712,
                    no: 672
                },
                {
                    label: "Feusisberg",
                    yes: 98,
                    no: 129
                },
                {
                    label: "Freienbach",
                    yes: 565,
                    no: 456
                },
                {
                    label: "Galgenen",
                    yes: 84,
                    no: 122
                },
                {
                    label: "Gersau",
                    yes: 107,
                    no: 80
                },
                {
                    label: "Goldau",
                    yes: 421,
                    no: 181
                },
                {
                    label: "Illgau",
                    yes: 60,
                    no: 77
                },
                {
                    label: "Immensee",
                    yes: 146,
                    no: 70
                },
                {
                    label: "Ingenbohl-Brunnen",
                    yes: 691,
                    no: 458
                },
                {
                    label: "Lachen",
                    yes: 224,
                    no: 260
                },
                {
                    label: "Lauerz",
                    yes: 58,
                    no: 61
                },
                {
                    label: "Merlischachen",
                    yes: 69,
                    no: 25
                },
                {
                    label: "Morschach-Stoos",
                    yes: 55,
                    no: 39
                },
                {
                    label: "Muotathal",
                    yes: 232,
                    no: 247
                },
                {
                    label: "Nuolen",
                    yes: 17,
                    no: 33
                },
                {
                    label: "Oberiberg",
                    yes: 55,
                    no: 60
                },
                {
                    label: "Reichenburg",
                    yes: 31,
                    no: 197
                },
                {
                    label: "Riemenstalden",
                    yes: 12,
                    no: 7
                },
                {
                    label: "Rothenthurm",
                    yes: 80,
                    no: 363
                },
                {
                    label: "Sattel",
                    yes: 76,
                    no: 118
                },
                {
                    label: "Schindellegi",
                    yes: 228,
                    no: 160
                },
                {
                    label: "Schübelbach",
                    yes: 36,
                    no: 60
                },
                {
                    label: "Schwyz",
                    yes: 845,
                    no: 727
                },
                {
                    label: "Siebnen",
                    yes: 184,
                    no: 196
                },
                {
                    label: "Steinen",
                    yes: 121,
                    no: 233
                },
                {
                    label: "Steinerberg",
                    yes: 51,
                    no: 72
                },
                {
                    label: "Studen",
                    yes: 11,
                    no: 45
                },
                {
                    label: "Tuggen",
                    yes: 86,
                    no: 108
                },
                {
                    label: "Unteriberg",
                    yes: 35,
                    no: 226
                },
                {
                    label: "Wägital",
                    yes: 17,
                    no: 68
                },
                {
                    label: "Wangen",
                    yes: 99,
                    no: 150
                },
                {
                    label: "Wollerau",
                    yes: 248,
                    no: 203
                }
            ]
        },
    ],
    voteMap: {
        src: '/documents/votes/20190630/vote_map.png',
        alt: 'Wahlergebniskarte'
    },
    files: {
        filesTitle: "Unter den folgenden Links sind die entprechenden " +
            "Unterlagen und Sessionprotokolle zu finden.",
        filesList: [
            {
                name: "Ergebnisse der einzelnen Kirchgemeinden (pdf-Format)",
                path: '/documents/votes/20190630.pdf'
            },
            {
                name: "Medienmitteilung zur Abstimmung über den RKZ Beitritt",
                path: '/documents/votes/20190630_MediaMessage.docx'
            },
            {
                name: "Abstimmungsbroschüre",
                path: '/documents/votes/20190630/abstimmungsbroschuere.pdf'
            },
            {
                name: "Dekret für die Referendumsabstimmung vom 30. Juni 2019",
                path: '/documents/votes/20190630_MediaMessage.docx'
            },
        ]
    },
    links: [
        {
            id: 20180525,
            name: "Session des Kantonskirchenratesvom 28. Mai 2018",
            page: 'ProtokolleDetailed',
            pre: "",
            post: "",
        },
    ]
}