export default {
    id: 17,
    name: "20200316InformationWriting",
    type: "InformationWrting",
    title: 'Keine Gottesdienste im Bistum Chur wegen der Coronavirus-Pandemie',
    value: 'Wieder Gottesdienste möglich <br>' +
        'Seit dem 28. Mai 2020 sind wieder Gottesdienste möglich, wobei ein Schutzkonzept einzuhalten ist. ' +
        'Es wird auf die jeweils aktuellen Informationen auf der Homepage des Bistums Chur verwiesen: <a href="https://www.bistum-chur.ch/allgemein/neue-covid-massnahmen-fuer-das-ganze-bistum/" class="link">bistum-chur.ch',
    target: "InfoSite",
    Post: true,
    date: 20200528,
}
