export default {
    id: 20210928,
    date: 20210928,
    uploadDate: '29.9.2021',
    title: 'Session des Kantonskirchenrates vom 22. Oktober 2021   ',
    shortDescriptionString: `Sessionsprotokoll / Informationsschreiben / Ergebnisse in Kurzform /
Traktanden /Sessionseinladung und Unterlagen`,
    subtitle: `Der Kantonskirchenrat hielt seine ordentliche Herbstsession am 22. 
Oktober 2021 im Schweizerischen Jugend- und Bildungszentrum SJBZ in 
Einsiedeln (unter Einhaltung des aktuellen Schutzkonzepts) ab.`,
    sessionDate: '22.10.2021',
    resultPdfs: [
        {
            value: 'Protokoll der Session vom 22. Oktober 2021',
            link: '/protocols/20211022/Sessionsprotokoll.pdf',
        },
        {
            value: 'Informationsschreiben vom 5. November 2021',
            link: '/protocols/20211022/Informationsschreiben.pdf',
        },
    ],
    shortResults: [
        {
            name: 'sr1',
            value: 'Der Nachkredit 2021 von Fr. 16’000.-- für die Katechetische Arbeitsstelle KAS wurde gewährt.'
        },
        {
            name: 'sr2',
            value: 'Der Zusammenschluss der Kirchgemeinden Arth und Goldau zur Kirchgemeinde Arth-Goldau per 1. Januar 2022 wurde genehmigt.'
        },
        {
            name: 'sr3',
            value: 'Der Voranschlag 2022 mit einem Total des Aufwandes von Fr. 2’219’779.50 und dem Pro-Kopf-Beitrag an die Kantonalkirche von gesamthaft Fr. 24.20 wurde gemäss dem Antrag des Kantonalen Kirchenvorstandes beschlossen.'
        },
        {
            name: 'sr4',
            value: `
Der Finanzausgleich 2022 wurde gemäss dem unveränderten Antrag mit
einem Ausgleich des Normleistungsaufwandes von Fr. 276.50 (d.h. einem
Ausgleich der Normkosten zu 92.5%) und mit einem Gesamtbudget von Fr.
1’133’520.-- beschlossen.`
        },
        {
            name: 'sr4',
            value: `
Das Postulat 1-2021 betreffend vermehrten Mitfinanzierungen durch die
Kantonalkirche wurde für nicht erheblich erklärt.`
        },
    ],
    Traktanden: [
        {
            name: 'tt1',
            value: 'Gebet, Begrüssung und Präsenz',
        },
        {
            name: 'tt2',
            value: 'Nachkredit 2021 für die Katechetische Arbeitsstelle KAS',
        },
        {
            name: 'tt3',
            value: 'Genehmigung des Zusammenschlusses der beiden Kirchgemeinden Arth und Goldau zur Kirchgemeinde Arth-Goldau',
        },
        {
            name: 'tt4',
            value: 'Beschluss über den Voranschlag 2022',
        },
        {
            name: 'tt5',
            value: 'Beschluss über den Finanzausgleich 2022',
        },
        {
            name: 'tt6',
            value: 'Finanzierungsbeschluss für die RKZ-Plenarversammlung vom 24./25. März 2023 in Einsiedeln',
        },
        {
            name: 'tt7',
            value: 'Postulat 1-2021 betreffend vermehrten Mitfinanzierungen durch die Kantonalkirche',
        },
        {
            name: 'tt8',
            value: 'Informationen der Ressortchefs und Fragestunde',
        },
        {
            name: 'tt9',
            value: 'Verschiedenes',
        },
    ],
    Unterlagen: [
        {
            value: 'Sessionseinladung',
            link: '/protocols/20211022/sessionseinladung.pdf',
        },
        {
            value: 'Beilagenblatt',
            link: '/protocols/20211022/Beilagenblatt.pdf',
        },
        {
            value: 'Nachkredit KAS',
            link: '/protocols/20211022/Nachkredit_KAS.pdf',
        },
        {
            value: 'Zusammenschluss Arth-Goldau',
            link: '/protocols/20211022/Zusammenschluss_Arth-Goldau.pdf',
        },
        {
            value: 'GPK - ANTRAG BUDGET 2022',
            link: '/protocols/20211022/GPK_-_ANTRAG_BUDGET_2022.pdf',
        },
        {
            value: 'Voranschlag 2022 KVS 08.09.2021',
            link: '/protocols/20211022/Voranschlag_2022_KVS_08.09.2021.pdf',
        },
        {
            value: 'KAS_Budget_2020-2022_sw aktuell',
            link: '/protocols/20211022/KAS_Budget_2020-2022_sw_aktuell.pdf',
        },
        {
            value: 'ASS Voranschlag 2022 FK 30.03.2021',
            link: '/protocols/20211022/ASS_Voranschlag_2022_FK_30.03.2021.pdf',
        },
        {
            value: 'Bericht zum Voranschlag 2022',
            link: '/protocols/20211022/Bericht_zum_Voranschlag_2022.pdf',
        },
        {
            value: 'Finanzplanung 2022-2025 Stand 06.09.2021',
            link: '/protocols/20211022/Finanzplanung_2022-2025_Stand_06.09.2021.pdf',
        },
        {
            value: 'FA 2022 mit 92.5 KVS 08.09.2021',
            link: '/protocols/20211022/FA_2022_mit_92.5_KVS_08.09.2021.pdf',
        },
        {
            value: 'Finanzausgleich 2015-2022 Varianten',
            link: '/protocols/20211022/Finanzausgleich_2015-2022_Varianten.pdf',
        },
        {
            value: 'Verpflichtungskredit RKZ',
            link: '/protocols/20211022/Verpflichtungskredit_RKZ.pdf',
        },
        {
            value: 'Beantwortung Postulat',
            link: '/protocols/20211022/Beantwortung_Postulat.pdf',
        },
    ]
}