export default {
    id: 4,
    type:"InformationWrting",
    name: 'ReferendungRKZBeitrittNichtAngenommen',
    title: 'Referendum gegen RKZ-Beitritt nicht zustande gekommen',
    value: 'Das Referendum gegen den RKZ-Beitrittsbeschluss ist doch nicht zustandegekommen, ' +
        'so dass die Abstimmung vom 16. Dezember 2018 nicht durchgeführt wird.<br><br>' +
        'Nach dem Abschluss des ergriffenen Rechtsweges gegen diese Absage der ' +
        'Volksabstimmung kann dann weiter informiert werden.<br><br>' +
        'Auszug aus dem Informationsschtreiben vom 8. November 2018:<br><br>' +
        '"Bekanntlich haben ursprünglich nur die minimal dafür nötigen fünf Kirchgemeinden das Referendum ' +
        'gegen den Beschluss des Kantonskirchenrates vom 25. Mai 2018 über den Beitritt der Kantonalkirche Schwyz ' +
        'zur RKZ ergriffen. Noch bevor der Kantonale Kirchenvorstand das entsprechende Abstimmungsdekret erlassen ' +
        'konnte, hat eine Kirchgemeinde ihr Referendumsbegehren wieder zurückgezogen, so dass die nötige Anzahl ' +
        'Kirchgemeinden nicht mehr erreicht wird und diese Abstimmung nicht durchzuführen ist ' +
        '(wie auch der Kantonskirchenrat am 25. Mai 2018 eine freiwillige Unterstellung unter das Referendum ' +
        'deutlich abgelehnt hatte). Gegen diese Absage der Referendumsabstimmung haben die Kirchgemeinde Freienbach ' +
        '(und nicht Feusisberg, wie in den Medien zum Teil fälschlicherweise berichtet worden ist) ' +
        'und zwei Stimmberechtigte eine Beschwerde an die Rekurskommission erhoben. Das Verfahren ist zur Zeit ' +
        'vor der Rekurskommission hängig.<br>' +
        'Der Kantonale Kirchenvorstand kann dann über das Ergebnis und gegebenenfalls das weitere ' +
        'Vorgehen informieren, wenn das Beschwerdeverfahren rechtskräftig erledigt ist. <br>' +
        'Dabei ist in Erinnerung zu rufen, dass der Kantonale Kirchenvorstand jeweils nicht nur die Mitglieder ' +
        'des Kantonskirchenrats, sondern ausdrücklich auch die Kirchgemeinden etc. jeweils mit den ' +
        'Sessionsunterlagen bedient. Die Kommunikation in der Kantonalkirche ist damit umfassender als im ' +
        'politischen Kanton, wo die Gemeinden die Unterlagen, die an die Mitglieder des Kantonsrats gehen, ' +
        'nicht automatisch ebenfalls zugestellt erhalten. Diese Zustellung an einen weit gefassten Adressatenkreis' +
        ' geschah denn auch mit dem Bericht und Antrag zum RKZ-Beitritt vom 15. Dezember 2017, in welchem ' +
        'bekanntlich die voraussichtlichen Kosten des RKZ-Beitritts offen ausgewiesen sind, wie ebenfalls ' +
        'die Leistungen und Nutzen der RKZ für die Kirche Schweiz ausführlich dargestellt wurden. ' +
        'Es wäre klar verfehlt, von Intransparenz zu sprechen, sondern die ganzen Grundlagen sind offen, ' +
        'frühzeitig und breit bekanntgegeben worden. Auch kann dem Kantonalen Kirchenvorstand nicht vorgeworfen ' +
        'werden, eine - vor allem auch für die Kirchgemeinden - aufwändige Abstimmung nicht durchzuführen, ' +
        'deren Voraussetzungen schliesslich nicht mehr gegeben sind und die vom Kantonskirchenrat an der Session ' +
        'vom 25. Mai 2018 ausdrücklich abgelehnt wurde."',
    target: "InfoSite",
    Post: true,
    date: 20181108,
    files: {
        filesTitle: "Weitere Information:",
        filesList: [
            {
                name: "Wieder neue Ausgangslage für Schwyzer RKZ-Beitritt",
                path: 'https://www.kath.ch/newsd/wieder-neue-ausgangslage-fuer-schwyzer-rkz-beitritt/'
            },
        ]
    }
}