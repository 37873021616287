export default {
    id: 20170519,
    date: 20170519,
    uploadDate: '14.06.2017',
    title: 'Session des Kantonskirchenrates vom 19. Mai 2017',
    subtitle: 'Der Kantonskirchenrat hielt seine ordentliche Frühjahrssession <br>' +
        'am 19 Mai 2017, im Schweizerischen Jugend- und Bildungszentrum SJBZ in Einsiedeln ab.',
    sessionDate: '19.05.2017',
    resultPdfs: [
        {
            value: 'Protokoll der Session vom 19. Mai 2017',
            link: '/protocols/20170519/20170614153105.pdf',
        },
        {
            value: 'Informationsschreiben vom 12. Juni 2017',
            link: '/protocols/20170519/20170614153339.pdf',
        },
    ],
    shortResults: [
        {
            name: '20170519sr1',
            value: 'der Rechenschaftsbericht 2016 der Rekurskommission wurde genehmigt;'
        },
        {
            name: '20170519sr2',
            value: 'die Jahresrechnung 2016 und Bilanz per 31. Dezember 2016 wurden genehmigt;'
        },
        {
            name: '20170519sr3',
            value: 'der Tätigkeitsbericht 2016 des Kantonalen Kirchenvorstandes wurde genehmigt.'
        },
        {
            name: '20170519sr4',
            value: 'bei der Ersatzwahl in den Kantonalen Kirchenvorstand wurde für den aus dem' +
                ' Kanton Schwyz weggezogenen Matthias Rupper (Schwyz) neu Hans-Peter Schuler-Geser' +
                ' (Brunnen) als Mitglied des Kantonalen Kirchenvorstands gewählt. Der Amtsantritt' +
                ' wird per 1. Juni 2017 sein.'
        }
    ],
    Traktanden: [
        {
            name: '20170519tt1',
            value: 'Gebet, Begrüssung, Präsenz und Vereidigung eines neuen Mitglieds',
        },
        {
            name: '20170519tt2',
            value: 'Rechenschaftsbericht 2016 der Rekurskommission',
        },
        {
            name: '20170519tt3',
            value: 'Jahresrechnung 2016 und Bilanz per 31. Dezember 2016',
        },
        {
            name: '20170519tt4',
            value: 'Tätigkeitsbericht 2016 des Kantonalen Kirchenvorstandes',
        },
        {
            name: '20170519tt5',
            value: 'Ersatzwahl in den Kantonalen Kirchenvorstand',
        },
        {
            name: '20170519tt6',
            value: 'Informationen der Ressortchefs und Fragestunde',
        },
        {
            name: '20170519tt7',
            value: 'Verschiedenes',
        },
    ],
    Unterlagen: [
        {
            value: 'Sessionseinladung',
            link: '/protocols/20170519/20170424191807.pdf',
        },
        {
            value: 'Beilagenblatt',
            link: '/protocols/20170519/20170424191900.pdf',
        },
        {
            value: 'Rechenschaftsbericht 2016',
            link: '/protocols/20170519/20170424192009.pdf',
        },
        {
            value: 'Bericht und Antrag der GPK an den Kantonskirchenrat',
            link: '/protocols/20170519/20170424192104.pdf',
        },
    ]
}