export default {
    id: 20210503,
    date: 20210503,
    uploadDate: '03.05.2021',
    title: 'Session des Kantonskirchenrates vom 28. Mai 2021',
    shortDescriptionString: 'Sessionsprotokoll / Informationsschreiben / Ergebnisse in Kurzform / Traktanden / Sessionseinladung und Unterlagen',
    subtitle: 'Der Kantonskirchenrat hielt seine ordentliche Frühjahrssession am 28. Mai 2021 im Kultur-' +
        ' und Kongresszentrum ZWEI RABEN in Einsiedeln (unter Einhaltung des aktuellen Schutzkonzepts) ab.',
    sessionDate: '28.05.2021',
    resultPdfs: [
        {
            value: 'Protokoll der Session vom 28. Mai 2021',
            link: '/protocols/20210503/Sessionsprotokoll.pdf',
        },
        {
            value: 'Informationsschreiben vom 22. Juni 2021',
            link: '/protocols/20210503/Informationsschreiben.pdf',
        },
    ],
    shortResults: [
        {
            name: 'sr1',
            value: 'Der Rechenschaftsbericht 2020 der Rekurskommission wurde genehmigt.'
        },
        {
            name: 'sr2',
            value: 'Die Jahresrechnung 2020 und Bilanz per 31. Dezember 2020 wurden genehmigt.'
        },
        {
            name: 'sr3',
            value: 'Der Tätigkeitsbericht 2020 des Kantonalen Kirchenvorstandes wurde genehmigt.'
        },
        {
            name: 'sr4',
            value: 'Der Nachkredit von total Fr. 35’000.-- für die Referendumsabstimmung betreffend' +
            ' der Einführung eines Stimm- und Wahlrechts für Katholiken ohne Schweizer Bürgerrecht wurde gewährt'
        },
    ],
    Traktanden: [
        {
            name: 'tt1',
            value: 'Gebet, Begrüssung und Präsenz',
        },
        {
            name: 'tt2',
            value: 'Rechenschaftsbericht 2020 der Rekurskommission',
        },
        {
            name: 'tt4',
            value: 'Jahresrechnung 2020 und Bilanz per 31. Dezember 2020',
        },
        {
            name: 'tt5',
            value: 'Nachkredit für die Referendumsabstimmung betreffend der Einführung eines Stimm- und Wahlrechts für Katholiken ohne Schweizer Bürgerrecht',
        },
        {
            name: 'tt6',
            value: 'Tätigkeitsbericht 2020 des Kantonalen Kirchenvorstandes',
        },
        {
            name: 'tt8',
            value: 'Informationen der Ressortchefs und Fragestunde',
        },
        {
            name: 'tt9',
            value: 'Verschiedenes',
        },
    ],
    Unterlagen: [
        {
            value: 'Sessionseinladung',
            link: '/protocols/20210503/sessionseinladung.pdf',
        },
        {
            value: 'Beilagenblatt',
            link: '/protocols/20210503/beilagenblatt.pdf',
        },
        {
            value: 'Schutzkonzept',
            link: '/protocols/20210503/schutzkonzept.pdf',
        },
        {
            value: 'Rechenschaftsberichtsbroschüre 2020',
            link: '/rechenschaftsberichte/rechenschaftsbericht_2020.pdf',
        },
        {
            value: 'Nachkredit Referendumsabstimmung',
            link: '/protocols/20210503/nachkredit_referendumsabstimmung.pdf',
        },
        {
            value: 'GPK-Antrag',
            link: '/protocols/20210503/gpk-antrag.pdf',
        },
        {
            value: 'Neubau Kaserne Schweizergarde',
            link: '/protocols/20210503/neubau_kaserne_schweizergarde.pdf',
        },
    ]
}