export default {
    kirchgemeindenTable: {
      headers: [
        {
          text: "Kirchgemeinde",
          align: "center",
          sortable: true,
          value: "location"
        },
        {
          text: "Katholiken/innen (1.1.2023)",
          value: "members",
          align: "center"
        },
        {
          text: "Anzahl Kantonskirchenräte/innen",
          value: "leaders",
          align: "center"
        },
        {
          text: "Stimmen im Kantonskirchenrat",
          value: "voices",
          align: "center"
        }
      ],
      pagination: {
        rowsPerPage: -1
      },
      kirchengemeinden: [
        {
          location: "Alpthal",
          members: 397,
          leaders: 1,
          voices: 1,
          link: "https://www.kath.ch/addresses/?instid=3613"
        },
        {
          location: "Altendorf",
          members: 3386,
          leaders: 2,
          voices: 5,
          link: "https://www.kath.ch/addresses/?instid=3539"
        },
        {
          location: "Arth-Goldau",
          members: 6641,
          leaders: 4,
          voices: 9,
          link: "https://www.kath.ch/addresses/?instid=3569"
        },
        {
          location: "Buttikon",
          members: 1440,
          leaders: 1,
          voices: 2,
          link: "https://www.kath.ch/addresses/?instid=3540"
        },
        {
          location: "Einsiedeln",
          members: 9959,
          leaders: 5,
          voices: 13,
          link: "https://www.kath.ch/addresses/?instid=3541"
        },
        {
          location: "Feusisberg",
          members: 836,
          leaders: 1,
          voices: 1,
          link: "https://www.kath.ch/addresses/?instid=5153"
        },
        {
          location: "Freienbach",
          members: 6417,
          leaders: 4,
          voices: 9,
          link: "https://www.kath.ch/addresses/?instid=3542"
        },
        {
          location: "Galgenen",
          members: 1849,
          leaders: 1,
          voices: 2,
          link: "https://www.kath.ch/addresses/?instid=3543"
        },
        {
          location: "Gersau",
          members: 1335,
          leaders: 1,
          voices: 2,
          link: "https://www.kath.ch/addresses/?instid=3570"
        },
        {
          location: "Illgau",
          members: 742,
          leaders: 1,
          voices: 1,
          link: "https://www.kath.ch/addresses/?instid=3572"
        },
        {
          location: "Immensee",
          members: 1609,
          leaders: 1,
          voices: 2,
          link: "https://www.kath.ch/addresses/?instid=3573"
        },
        {
          location: "Ingenbohl",
          members: 5716,
          leaders: 3,
          voices: 7,
          link: "https://www.kath.ch/addresses/?instid=3574"
        },
        {
          location: "Küssnacht am Rigi",
          members: 5557,
          leaders: 3,
          voices: 7,
          link: "https://www.kath.ch/addresses/?instid=3575"
        },
        {
          location: "Lachen",
          members: 4131,
          leaders: 2,
          voices: 5,
          link: "https://www.kath.ch/addresses/?instid=3544"
        },
        {
          location: "Lauerz",
          members: 726,
          leaders: 1,
          voices: 1,
          link: "https://www.kath.ch/addresses/?instid=3576"
        },
        {
          location: "Merlischachen",
          members: 649,
          leaders: 1,
          voices: 1,
          link: "https://www.kath.ch/addresses/?instid=3577"
        },
        {
          location: "Morschach",
          members: 700,
          leaders: 1,
          voices: 1,
          link: "https://www.kath.ch/addresses/?instid=3578"
        },
        {
          location: "Muotathal",
          members: 3019,
          leaders: 2,
          voices: 4,
          link: "https://www.kath.ch/addresses/?instid=6226"
        },
        {
          location: "Nuolen",
          members: 232,
          leaders: 1,
          voices: 1,
          link: "https://www.kath.ch/addresses/?instid=3545"
        },
        {
          location: "Oberiberg",
          members: 542,
          leaders: 1,
          voices: 1,
          link: "https://www.kath.ch/addresses/?instid=3580"
        },
        {
          location: "Reichenburg",
          members: 1881,
          leaders: 1,
          voices: 2,
          link: "https://www.kath.ch/addresses/?instid=3547"
        },
        {
          location: "Riemenstalden",
          members: 74,
          leaders: 1,
          voices: 1,
          link: "https://www.kath.ch/addresses/?instid=3581"
        },
        {
          location: "Rothenthurm",
          members: 1793,
          leaders: 1,
          voices: 2,
          link: "https://www.kath.ch/addresses/?instid=3582"
        },
        {
          location: "Sattel",
          members: 1386,
          leaders: 1,
          voices: 2,
          link: "https://www.kath.ch/addresses/?instid=3583"
        },
        {
          location: "Schindellegi",
          members: 1526,
          leaders: 1,
          voices: 2,
          link: "https://www.kath.ch/addresses/?instid=3548"
        },
        {
          location: "Schübelbach",
          members: 1042,
          leaders: 1,
          voices: 1,
          link: "https://www.kath.ch/addresses/?instid=3549"
        },
        {
          location: "Schwyz",
          members: 10265,
          leaders: 6,
          voices: 14,
          link: "https://www.kath.ch/addresses/?instid=3584"
        },
        {
          location: "Siebnen",
          members: 3606,
          leaders: 2,
          voices: 5,
          link: "https://www.kath.ch/addresses/?instid=3550"
        },
        {
          location: "Steinen",
          members: 2444,
          leaders: 1,
          voices: 3,
          link: "https://www.kath.ch/addresses/?instid=3612"
        },
        {
          location: "Steinerberg",
          members: 686,
          leaders: 1,
          voices: 1,
          link: "https://www.kath.ch/addresses/?instid=3586"
        },
        {
          location: "Studen",
          members: 337,
          leaders: 1,
          voices: 1,
          link: "https://www.kath.ch/addresses/?instid=3587"
        },
        {
          location: "Tuggen",
          members: 1888,
          leaders: 1,
          voices: 3,
          link: "https://www.kath.ch/addresses/?instid=3551"
        },
        {
          location: "Unteriberg",
          members: 1475,
          leaders: 1,
          voices: 2,
          link: "https://www.kath.ch/addresses/?instid=3588"
        },
        {
          location: "Wägital",
          members: 796,
          leaders: 1,
          voices: 1,
          link: "https://www.kath.ch/addresses/?instid=5197"
        },
        {
          location: "Wangen",
          members: 1885,
          leaders: 1,
          voices: 2,
          link: "https://www.kath.ch/addresses/?instid=3553"
        },
        {
          location: "Wollerau",
          members: 2603,
          leaders: 2,
          voices: 3,
          link: "https://www.kath.ch/addresses/?instid=3554"
        }
      ]
    }
  }