export default {
    id: 25,
    name: "20211215InformationWriting",
    type: "InformationWrting",
    title: 'Informationsveranstaltung vom 2. April 2022 in Einsiedeln',
    subtitle: '',
    value: `Sehr geehrte Damen und Herren<br><br><br>
    Gerne laden der Verein der Kirchgemeindepräsidenten und Kirchgemeindeverwalter und der Kantonale
    Kirchenvorstand zu einer Informationsveranstaltung ein auf
    <br><br> <b>
    Samstag, 2. April 2022, 08.30 Uhr,
    </b> <br><br>
    im Schweizerischen Jugend- und Bildungs-Zentrum SJBZ, Lincolnweg 23, 8840 Einsiedeln.
    An dieser Informationsveranstaltung soll über aktuelle Themen im Bereich der Kirche und der Kantonalkirche
    samt den Kirchgemeinden informiert werden, wie auch die Vernetzung unter den (Kantons-)Kirchenräten und
    der Kantonalkirche gefördert wird.<br><br>
    In vier Kurzreferaten werden Dr. Stefan Loppacher und/oder Karin Iten (Präventionsbeauftragte des Bistums
    Chur), Silvia Brändle (Leiterin der Katechetischen Arbeitsstelle Kanton Schwyz KAS), Aldo Dubacher
    (Vorstand des Vereins der Kirchenratspräsidenten und Kirchengutsverwalter) und Lorenz Bösch (Präsident des
    Kantonalen Kirchenvorstandes) die Themen Prävention sexuelle Gewalt, künftige Herausforderungen für die
    Kirchgemeinden, Aufgaben und Angebote der Katechetischen Arbeitsstelle KAS, sowie Strategiediskussion
    2030 der Kantonalkirche behandeln. Im Anschluss an die Kurzreferate können die Themen in der Gruppendiskussion vertieft besprochen werden, wie auch eigene Erfahrungen ausgetauscht und Fragen gestellt werden
    können. Weitere Diskussionen sind am abschliessenden Apéro riche möglich und erwünscht.<br><br>
    Die Versammlung ist offen für alle Mitglieder von Kirchenräten, für die Mitglieder des Kantonskirchenrats
    sowie für sonstige interessierte Personen. Wir ersuchen Sie, diese Einladung entsprechend auch weiter zu geben.
    Für die Planung wird um eine Anmeldung mit Namensangabe bis spätestens am 15. März 2022 ersucht auf:
    sekretariat@sz.kath.ch. Auch stehen wir für allfällige Fragen selbstverständlich gerne zur Verfügung.<br><br><br>
    Mit freundlichen Grüssen<br><br>
    Kantonaler Kirchenvorstand
    `,
    target: "InfoSite",
    Post: true,
    date: 20211215,
    files: {
        filesTitle: "Dateien",
        filesList: [
            {
                name: "Einladung zur Informationsveranstaltung vom 2. April 2022 in Einsiedeln",
                path: '/documents/info_event_20220402/00_Informationsveranstaltung-Einladung.pdf'
            },
            {
                name: "Kantonalkirche 2030 Lageanalyse",
                path: '/documents/info_event_20220402/01_Kantonalkirche_2030_Lageanalyse.pdf'
            },
            {
                name: "Vertrauenskodex Machtmissbrauch",
                path: '/documents/info_event_20220402/02_Vertrauenskodex_Machtmissbrauch.pdf'
            },
            {
                name: "Herausforderung Kirchgemeinden 2022",
                path: '/documents/info_event_20220402/03_Herausforderungen_Kirchgemeinden_2022.pdf'
            },
            {
                name: "Präsentation KAS PDF",
                path: '/documents/info_event_20220402/04_Praesentation_KAS_pdf.pdf'
            },
            {
                name: "Kantonalkirche erklärt",
                path: '/documents/info_event_20220402/05_Kantonalkirche_erklaert.pdf'
            },
        ]
    }
}