export default {
    id: 20230424,
    date: 20230424,
    uploadDate: '24.4.2023',
    title: 'Session des Kantonskirchenrates vom 26. Mai 2023',
    shortDescriptionString: `Sessionsprotokoll / Informationsschreiben / Ergebnisse in Kurzform  / Traktanden / Sessionseinladung und Unterlagen`,
    subtitle: `Der Kantonskirchenrat plant seine ordentliche Frühlingssession auf den
    Freitag, 26. Mai 2023, im Schweizerischen Jugend- und Bildungszentrum
    SJBZ in Einsiedeln.`,
    sessionDate: '26.5.2023',    
    resultPdfs: [
        {
            value: "Sessionsprotokoll",
            link: "/protocols/20230526/Sessionsprotokoll.pdf"
        },
        {
            value: "Schreiben",
            link: "/protocols/20230526/Schreiben.pdf"
        },
        {
            value: "Formular Anstellungsbedingungen - Word",
            link: "/protocols/20230526/Formular_Anstellungsbedingungen.docx"
        },
    ],
    shortResults: [
        {
            name: 'sr1',
            value: 'Der Rechenschaftsbericht 2022 der Rekurskommission wurde genehmigt.'
        },
        {
            name: 'sr2',
            value: 'Die Jahresrechnung 2022 und Bilanz per 31. Dezember 2022 wurden genehmigt.'
        },
        {
            name: 'sr3',
            value: 'Der Tätigkeitsbericht 2022 des Kantonalen Kirchenvorstandes wurde genehmigt.'
        },
        {
            name: 'sr4',
            value: 'Die Motion M 1-2022 “Finanzausgleich für effektiven Normaufwand” wurde für nicht erheblich erklärt, womit sie erledigt ist.'
        },
    ],
    Traktanden: [
        {
            name: 'tt1',
            value: 'Gebet, Begrüssung und Präsenz',
        },
        {
            name: 'tt2',
            value: 'Rechenschaftsbericht 2022 der Rekurskommission',
        },
        {
            name: 'tt3',
            value: 'Jahresrechnung 2022 und Bilanz per 31. Dezember 2022',
        },
        {
            name: 'tt4',
            value: 'Tätigkeitsbericht 2022 des Kantonalen Kirchenvorstandes',
        },
        {
            name: 'tt5',
            value: 'Beantwortung der Motion M 1-2022 “Finanzausgleich für effektiven Normaufwand”',
        },
        {
            name: 'tt6',
            value: 'Informationen der Ressortchefs und Fragestunde',
        },
        {
            name: 'tt7',
            value: 'Verschiedenes',
        },
    ],
    Unterlagen: [    
        {
            value: "Sessionseinladung",
            link: "/protocols/20230526/1_Sessionseinladung.pdf"
        },
        {
            value: "Beilagenblatt",
            link: "/protocols/20230526/2_Beilagenblatt.pdf"
        },
        {
            value: "Rechenschaftsbericht_2022",
            link: "/rechenschaftsberichte/Rechenschaftsbericht_2022.pdf"
        },
        {
            value: "GPK-Antrag",
            link: "/protocols/20230526/4_GPK-Antrag.pdf"
        },
        {
            value: "Motionsantwort",
            link: "/protocols/20230526/5_Motionsantwort.pdf"
        },
    ]
}