export default [
    {
        id: 20151016,
        date: 20151016,
        title: "Session des Kantonskirchenrates vom 16. Oktober 2015",
        sessionDate: "16.10.2015",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 16. Oktober 2015",
                link: "/protocols/older_than_2016/20151016.pdf",
            },
        ],
    },
    {
        id: 20150424,
        date: 20150424,
        title: "Session des Kantonskirchenrates vom 24. April 2015",
        sessionDate: "24.04.2015",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 24. April 2015",
                link: "/protocols/older_than_2016/20150424.pdf",
            },
        ],
    },
    {
        id: 20141017,
        date: 20141017,
        title: "Session des Kantonskirchenrates vom 17. Oktober 2014",
        sessionDate: "17.10.2014",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 17. Oktober 2014",
                link: "/protocols/older_than_2016/20141017.pdf",
            },
        ],
    },
    {
        id: 20140425,
        date: 20140425,
        title: "Session des Kantonskirchenrates vom 25. April 2014",
        sessionDate: "25.04.2014",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 25. April 2014",
                link: "/protocols/older_than_2016/20140425.pdf",
            },
        ],
    },
    {
        id: 20131018,
        date: 20131018,
        title: "Session des Kantonskirchenrates vom 18. Oktober 2013",
        sessionDate: "18.10.2013",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 18. Oktober 2013",
                link: "/protocols/older_than_2016/20131018.pdf",
            },
        ],
    },
    {
        id: 20130426,
        date: 20130426,
        title: "Session des Kantonskirchenrates vom 26. April 2013",
        sessionDate: "26.04.2013",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 26. April 2013",
                link: "/protocols/older_than_2016/20130426.pdf",
            },
        ],
    },
    {
        id: 20120928,
        date: 20120928,
        title: "Session des Kantonskirchenrates vom 28. September 2012",
        sessionDate: "28.09.2012",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 28. September 2012",
                link: "/protocols/older_than_2016/20120928.pdf",
            },
        ],
    },
    {
        id: 20120629,
        date: 20120629,
        title: "Session des Kantonskirchenrates vom 29. Juni 2012",
        sessionDate: "29.06.2012",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 29. Juni 2012",
                link: "/protocols/older_than_2016/20120629.pdf",
            },
        ],
    },
    {
        id: 20120427,
        date: 20120427,
        title: "Session des Kantonskirchenrates vom 27. April 2012",
        sessionDate: "27.04.2012",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 27. April 2012",
                link: "/protocols/older_than_2016/20120427.pdf",
            },
        ],
    },
    {
        id: 20110923,
        date: 20110923,
        title: "Session des Kantonskirchenrates vom 23. September 2011",
        sessionDate: "23.09.2011",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 23. September 2011",
                link: "/protocols/older_than_2016/20110923.pdf",
            },
        ],
    },
    {
        id: 20110429,
        date: 20110429,
        title: "Session des Kantonskirchenrates vom 29. April 2011",
        sessionDate: "29.04.2011",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 29. April 2011",
                link: "/protocols/older_than_2016/20110429.pdf",
            },
        ],
    },
    {
        id: 20100924,
        date: 20100924,
        title: "Session des Kantonskirchenrates vom 24. September 2010",
        sessionDate: "24.09.2010",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 24. September 2010",
                link: "/protocols/older_than_2016/20100924.pdf",
            },
        ],
    },
    {
        id: 20100423,
        date: 20100423,
        title: "Session des Kantonskirchenrates vom 23. April 2010",
        sessionDate: "23.04.2010",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 23. April 2010",
                link: "/protocols/older_than_2016/20100423.pdf",
            },
        ],
    },
    {
        id: 20090925,
        date: 20090925,
        title: "Session des Kantonskirchenrates vom 25. September 2009",
        sessionDate: "25.09.2009",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 25. September 2009",
                link: "/protocols/older_than_2016/20090925.pdf",
            },
        ],
    },
    {
        id: 20090424,
        date: 20090424,
        title: "Session des Kantonskirchenrates vom 24. April 2009",
        sessionDate: "24.04.2009",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 24. April 2009",
                link: "/protocols/older_than_2016/20090424.pdf",
            },
        ],
    },
    {
        id: 20080926,
        date: 20080926,
        title: "Session des Kantonskirchenrates vom 26. September 2008",
        sessionDate: "26.09.2008",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 26. September 2008",
                link: "/protocols/older_than_2016/20080926.pdf",
            },
        ],
    },
    {
        id: 20080627,
        date: 20080627,
        title: "Session des Kantonskirchenrates vom 27. Juni 2008",
        sessionDate: "27.06.2008",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 27. Juni 2008",
                link: "/protocols/older_than_2016/20080627.pdf",
            },
        ],
    },
    {
        id: 20080425,
        date: 20080425,
        title: "Session des Kantonskirchenrates vom 25. April 2008",
        sessionDate: "25.04.2008",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 25. April 2008",
                link: "/protocols/older_than_2016/20080425.pdf",
            },
        ],
    },
    {
        id: 20070928,
        date: 20070928,
        title: "Session des Kantonskirchenrates vom 28. September 2007",
        sessionDate: "28.09.2007",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 28. September 2007",
                link: "/protocols/older_than_2016/20070928.pdf",
            },
        ],
    },
    {
        id: 20070427,
        date: 20070427,
        title: "Session des Kantonskirchenrates vom 27. April 2007",
        sessionDate: "27.04.2007",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 27. April 2007",
                link: "/protocols/older_than_2016/20070427.pdf",
            },
        ],
    },
    {
        id: 20060922,
        date: 20060922,
        title: "Session des Kantonskirchenrates vom 22. September 2006",
        sessionDate: "22.09.2006",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 22. September 2006",
                link: "/protocols/older_than_2016/20060922.pdf",
            },
        ],
    },
    {
        id: 20060428,
        date: 20060428,
        title: "Session des Kantonskirchenrates vom 28. April 2006",
        sessionDate: "28.04.2006",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 28. April 2006",
                link: "/protocols/older_than_2016/20060428.pdf",
            },
        ],
    },
    {
        id: 20050930,
        date: 20050930,
        title: "Session des Kantonskirchenrates vom 30. September 2005",
        sessionDate: "30.09.2005",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 30. September 2005",
                link: "/protocols/older_than_2016/20050930.pdf",
            },
        ],
    },
    {
        id: 20050422,
        date: 20050422,
        title: "Session des Kantonskirchenrates vom 22. April 2005",
        sessionDate: "22.04.2005",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 22. April 2005",
                link: "/protocols/older_than_2016/20050422.pdf",
            },
        ],
    },
    {
        id: 20040924,
        date: 20040924,
        title: "Session des Kantonskirchenrates vom 24. September 2004",
        sessionDate: "24.09.2004",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 24. September 2004",
                link: "/protocols/older_than_2016/20040924.pdf",
            },
        ],
    },
    {
        id: 20040702,
        date: 20040702,
        title: "Session des Kantonskirchenrates vom 02. Juli 2004",
        sessionDate: "02.07.2004",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 02. Juli 2004",
                link: "/protocols/older_than_2016/20040702.pdf",
            },
        ],
    },
    {
        id: 20040402,
        date: 20040402,
        title: "Session des Kantonskirchenrates vom 02. April 2004",
        sessionDate: "02.04.2004",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 02. April 2004",
                link: "/protocols/older_than_2016/20040402.pdf",
            },
        ],
    },
    {
        id: 20030926,
        date: 20030926,
        title: "Session des Kantonskirchenrates vom 26. September 2003",
        sessionDate: "26.09.2003",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 26. September 2003",
                link: "/protocols/older_than_2016/20030926.pdf",
            },
        ],
    },
    {
        id: 20030411,
        date: 20030411,
        title: "Session des Kantonskirchenrates vom 11. April 2003",
        sessionDate: "11.04.2003",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 11. April 2003",
                link: "/protocols/older_than_2016/20030411.pdf",
            },
        ],
    },
    {
        id: 20020920,
        date: 20020920,
        title: "Session des Kantonskirchenrates vom 20. September 2002",
        sessionDate: "20.09.2002",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 20. September 2002",
                link: "/protocols/older_than_2016/20020920.pdf",
            },
        ],
    },
    {
        id: 20020405,
        date: 20020405,
        title: "Session des Kantonskirchenrates vom 05. April 2002",
        sessionDate: "05.04.2002",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 05. April 2002",
                link: "/protocols/older_than_2016/20020405.pdf",
            },
        ],
    },
    {
        id: 20011207,
        date: 20011207,
        title: "Session des Kantonskirchenrates vom 07. Dezember 2001",
        sessionDate: "07.12.2001",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 07. Dezember 2001",
                link: "/protocols/older_than_2016/20011207.pdf",
            },
        ],
    },
    {
        id: 20010921,
        date: 20010921,
        title: "Session des Kantonskirchenrates vom 21. September 2001",
        sessionDate: "21.09.2001",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 21. September 2001",
                link: "/protocols/older_than_2016/20010921.pdf",
            },
        ],
    },
    {
        id: 20010405,
        date: 20010405,
        title: "Session des Kantonskirchenrates vom 05. April 2001",
        sessionDate: "05.04.2001",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 05. April 2001",
                link: "/protocols/older_than_2016/20010405.pdf",
            },
        ],
    },
    {
        id: 20000915,
        date: 20000915,
        title: "Session des Kantonskirchenrates vom 15. September 2000",
        sessionDate: "15.09.2000",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 15. September 2000",
                link: "/protocols/older_than_2016/20000915.pdf",
            },
        ],
    },
    {
        id: 20000414,
        date: 20000414,
        title: "Session des Kantonskirchenrates vom 14. April 2000",
        sessionDate: "14.04.2000",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 14. April 2000",
                link: "/protocols/older_than_2016/20000414.pdf",
            },
        ],
    },
    {
        id: 19990917,
        date: 19990917,
        title: "Session des Kantonskirchenrates vom 17. September 1999",
        sessionDate: "17.09.1999",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 17. September 1999",
                link: "/protocols/older_than_2016/19990917.pdf",
            },
        ],
    },
    {
        id: 19990305,
        date: 19990305,
        title: "Session des Kantonskirchenrates vom 05. März 1999",
        sessionDate: "05.03.1999",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 05. März 1999",
                link: "/protocols/older_than_2016/19990305.pdf",
            },
        ],
    },
    {
        id: 19981030,
        date: 19981030,
        title: "Session des Kantonskirchenrates vom 30. Oktober 1998",
        sessionDate: "30.10.1998",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 30. Oktober 1998",
                link: "/protocols/older_than_2016/19981030.pdf",
            },
        ],
    },
    {
        id: 19981030.1,
        date: 19981030.1,
        title: "Session des Kantonskirchenrates vom 30. Oktober 1998",
        sessionDate: "30.10.1998",
        resultPdfs: [
            {
                value: "Protokoll der Session vom 30. Oktober 1998",
                link: "/protocols/older_than_2016/19981030k.pdf",
            },
        ],
    },
]
