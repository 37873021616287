import {filelist} from "./filelists/filelist20240925"
export default {
    id: 20240925,
    date: 20240925,
    uploadDate: '25.09.2024',
    title: 'Session des Kantonskirchenrates vom 18. Oktober 2024, weitere Informationen und Vernehmlassung Strategie',
    shortDescriptionString: 'Traktanden / Sessionseinladung und Unterlagen',
    subtitle: `Der Kantonskirchenrat plant seine ordentliche Herbstsession auf den Freitag, 18. Oktober 2024, im Schweizerischen Jugend- und Bildungszentrum SJBZ in Einsiedeln.`,
    sessionDate: '18.10.2024',
    Traktanden: [
        {
            name: 'tt1',
            value: 'Gebet, Begrüssung, Präsenz und Vereidigung von Mitgliedern',
        },
        {
            name: 'tt2',
            value: 'Vorstellung des Dualen Systems, der Abläufe im Kantonskirchenrat und dessen Funktionsweise',
        },
        {
            name: 'tt3',
            value: 'Beschluss über den Abschluss eines Vertrages zwischen der Diözese Chur und den staatskirchenrechtlichen Organisationen der Bistumskantone Uri, Schwyz, Ob- und Nidwalden, Glarus und Graubünden über die Berufseinführung im Bistum Chur',
        },
        {
            name: 'tt4',
            value: 'Beschluss über einen Verpflichtungskredit zugunsten des Projektes “Jubilate - Chance Kirchengesang” für die Jahre 2025 - 2028',
        },
        {
            name: 'tt5',
            value: 'Beschluss über den Voranschlag 2025',
        },
        {
            name: 'tt6',
            value: 'Beschluss über den Finanzausgleich 2025',
        },
        {
            name: 'tt7',
            value: 'Informationen der Ressortchefs und Fragestunde',
        },
        {
            name: 'tt8',
            value: 'Verschiedenes',
        },
    ],
    Unterlagen: filelist
}