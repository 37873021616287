export default {
    id: 1,
    type:"InformationWrting",
    name: 'infoWritingFor20180101',
    title: 'Informationsschreiben und Besoldungsentscheide des Kantonalen Kirchenvorstandes\n' +
        'per 1. Januar 2018',
    subtitle: '(Jahresarbeitsstunden/Teuerungsausgleich/Lohntabellen, verschiedene Meldungen)',
    value: 'Der Kantonale Kirchenvorstand beschloss an der Sitzung vom 14. Dezember 2017: <br>' +
        '<br>' +
        '1. Die “Lohnklassen und Lohnstufen” gemäss Anhang 2 der Personal- und Besoldungsverordnung werden für das ' +
        'Jahr 2018 nicht angehoben, sondern bleiben unverändert gegenüber den Jahren 2011 - 2017 ' +
        '(auf den Indexstand von unverändert 104.2 Punkten, Basis Dezember 2005 = 100 Punkte). ' +
        'Indexstand 30. November 2017: 102.3 <br>' +
        '<br>' +
        '2. Die Jahresarbeitsstunden für das Jahr 2018 gemäss § 14 Abs. 1 des Personal- und Besoldungsgesetzes ' +
        'belaufen sich auf 2’080 Stunden (ohne Ferienanspruch gerechnet).',
    target: "InfoSite",
    Post: true,
    date: 20171216,
    files: {
        filesTitle: "Dateien: ",
        filesList: [
            {
                name: "Informationsschreiben vom 15. Dezember 2017",
                path: '/documents/info_writing_for_20171216/informationsschreiben.pdf'
            },
            {
                name: "Lohntabelle 2018 (pdf)",
                path: '/documents/info_writing_for_20171216/lohntabelle.pdf'
            },
            {
                name: "Lohntabelle 2018 (Excel-Tabelle)",
                path: '/documents/info_writing_for_20171216/lohntabelle.xls'
            },
            {
                name: "Meldung Katholikenzahl per 1. Januar 2018 (pdf)",
                path: '/documents/info_writing_for_20171216/katholikenzahl.pdf'
            },
            {
                name: "Meldung Katholikenzahl per 1. Januar 2018 (Word)",
                path: '/documents/info_writing_for_20171216/katholikenzahl.doc'
            },
            {
                name: "Meldung Steuersatz per 1. Januar 2018 (pdf)",
                path: '/documents/info_writing_for_20171216/steuersatz.pdf'
            },
            {
                name: "Meldung Steuersatz per 1. Januar 2018 (Word)",
                path: '/documents/info_writing_for_20171216/steuersatz.doc'
            },
            {
                name: "Terminliste für Kirchgemeinden",
                path: '/documents/b_u_a/beitritt_rkz.pdf'
            },
            {
                name: "Terminliste für Kirchgemeinden",
                path: '/documents/info_writing_for_20171216/medienmitteilung.pdf'
            },
        ]
    }
}