export default {
    dekanat: {
        name: 'Dekanat Innerschwyz',
        Dekanate: [
            {
                Ort:'Alpthal, Hl. Apollonia',
                Pfarramt:'Kath. Pfarramt',
                Strasse:'Dorfstrasse 28',
                PLZ:'8849 Alpthal',
                phone: '055 412 26 72',
                Fax: '055 412 26 72',
                email: 'pfarramt@st-apollonia.ch',
                website: 'www.st-apollonia.ch',
                Details: 'https://www.kath.ch/addresses/?instid=31',
                expanded: false
            },
            {
                Ort:'Arth, Hll. Georg und Zeno',
                Pfarramt:'Kath. Pfarramt',
                Strasse:'Bahnhofstrasse 6',
                PLZ:'6415 Arth',
                phone: '041 855 11 57',
                email: 'sekretariat.arth@kath-arth-goldau.ch',
                website: 'www.kath-arth-goldau.ch',
                Details: 'www.kath.ch/addresses/?instid=71',
                expanded: false
            },
            {
                Ort:'Gersau, Hl. Marzellus',
                Pfarramt:'Röm.-kath. Pfarramt',
                Strasse:'Gütschstrasse 2',
                PLZ:'6442 Gersau',
                phone: '041 828 11 75',
                email: 'pfarramt@kirchgemeinde-gersau.ch',
                website: 'www.kirchgemeinde-gersau.ch',
                Details: 'www.kath.ch/addresses/?instid=633',
                expanded: false
            },
            {
                Ort:'Goldau, Hl. Herz Jesu',
                Pfarramt:'Kath. Pfarramt',
                Strasse:'Gotthardstrasse 31',
                PLZ:'6410 Goldau',
                phone: '041 855 57 65',
                Fax: '041 855 57 17',
                email: 'pfarramt.goldau@bluewin.ch',
                website: 'www.kirchegoldau.ch',
                Details: 'www.kath.ch/addresses/?instid=656',
                expanded: false
            },
            {
                Ort: 'Ibach, Hl. Antonius Erm.',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Gotthardstrasse 87',
                PLZ: '6438 Ibach',
                phone: '+41 41 818 61 20',
                email: 'pfarramt.ibach@kirchgemeinde-schwyz.ch',
                Details: 'www.kath.ch/addresses/?instid=773',
                expanded: false
            },
            {
                Ort:'Illgau, Dreikönigen',
                Pfarramt:'Kath. Pfarramt',
                Strasse:'Pfarrhaus',
                PLZ:'6434 Illgau',
                phone: '041 830 12 33',
                email: 'pfarramt.illgau@bluewin.ch',
                Details: 'www.kath.ch/addresses/?instid=776',
                expanded: false
            },
            {
                Ort:'Immensee, Hl. Sebastian',
                Pfarramt:'Kath. Pfarramt',
                Strasse:'Eichlistrasse 11',
                PLZ:'6405 Immensee',
                phone: '041 850 11 33',
                Fax: '041 850 88 14',
                email: 'pfarramt.immensee@bluewin.ch',
                website: 'www.kath-immensee@bluewin.ch',
                Details: 'www.kath.ch/addresses/?instid=779',
                expanded: false
            },
            {
                Ort:'Ingenbohl-Brunnen, Hl. Leonhard',
                Pfarramt:'Kath. Pfarramt',
                Strasse:'Klosterstrasse 6',
                PLZ:'6440 Brunnen',
                phone: '041 820 18 63',
                email: 'kontakt@pfarrei-ingenbohl.ch',
                Details: 'www.kath.ch/addresses/?instid=782',
                expanded: false
            },
            {
                Ort:'Küssnacht am Rigi, Hll. Peter und Paul',
                Pfarramt:'Kath. Pfarramt',
                Strasse:'Pfarrhausplatz 1',
                PLZ:'6403 Küssnacht am Rigi',
                phone: '041 850 02 83',
                email: 'info@pfarrei-kuessnacht.ch',
                website: 'www.pfarrei-kuessnacht.ch',
                Details: 'www.kath.ch/addresses/?instid=839',
                expanded: false
            },
            {
                Ort:'Lauerz, Hl. Nikolaus',
                Pfarramt:'Kath. Pfarramt',
                Strasse:'Seestrasse 12',
                PLZ:'6424 Lauerz',
                phone: '041 811 24 05',
                email: 'kath.pfarramt-lauerz@kfanet.ch',
                Details: 'www.kath.ch/addresses/?instid=862',
                expanded: false
            },
            {
                Ort:'Merlischachen, Hl. Jakob d. Ä.',
                Pfarramt:'Kath. Pfarramt',
                Strasse:'Pfarrhausplatz 1',
                PLZ:'6403 Küssnacht am Rigi',
                phone: '041 854 30 11',
                email: 'info@pfarrei-kuessnacht.ch',
                Details: 'www.kath.ch/addresses/?instid=1029',
                expanded: false
            },
            {
                Ort:'Morschach, Hl. Gallus',
                Pfarramt:'Kath. Pfarramt',
                Strasse:'Dorfstrasse 12',
                PLZ:'6443 Morschach',
                phone: '041 820 11 49',
                Fax: '041 820 05 20',
                email: 'pfarrei.morschach@bluewin.ch',
                Details: 'www.kath.ch/addresses/?instid=1074',
                expanded: false
            },
            {
                Ort: 'Muotathal, Hl. Sigismund',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Wil 27',
                PLZ: '6436 Muotathal',
                phone: '041 830 11 25',
                email: 'pfarramt.muotathal@bluewin.ch',
                website: 'www.pfarrei-muotathal.ch',
                Details: 'www.kath.ch/addresses/?instid=1097',
                expanded: false
            },
            {
                Ort:'Oberiberg, Hl. Johannes d. T.',
                Pfarramt:'Kath. Pfarramt',
                Strasse:'Kirchenstrasse 44',
                PLZ:'8843 Oberiberg',
                phone: '055 414 11 46',
                email: 'pfarrer@kirche-oberiberg.ch',
                website: 'www.kirche-oberiberg.ch',
                Details: 'www.kath.ch/addresses/?instid=1168',
                expanded: false
            },
            {
                Ort:'Riemenstalden, Maria v. Gutem Rat',
                Strasse:'Pfarrhaus',
                PLZ:'6452 Riemenstalden',
                phone: '041 820 30 31',
                Fax: '041 820 30 31',
                Details: 'www.kath.ch/addresses/?instid=1316',
                expanded: false
            },
            {
                Ort: 'Rothenthurm, Hl. Antonius Erm.',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Hauptstrasse 9',
                PLZ: '6418 Rothenthurm',
                phone: '041 838 11 93',
                email: 'pfarramt-rothenthurm@bluewin.ch',
                Details: 'www.kath.ch/addresses/?instid=1345',
                expanded: false
            },
            {
                Ort: 'Sattel, Hll. Peter und Paul',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Dorfstrasse 35',
                PLZ: '6417 Sattel',
                phone: '041 835 11 07',
                email: 'pfarreisekretariat.sattel@bluewin.ch',
                Details: 'www.kath.ch/addresses/?instid=1409',
                expanded: false
            },
            {
                Ort: 'Schwyz, Hl. Martin',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Herrengasse 22',
                PLZ: '6430 Schwyz',
                phone: '041 818 61 10',
                email: 'pfarramt.schwyz@kirchgemeinde-schwyz.ch',
                website: 'www.pfarrei-schwyz.ch',
                Details: 'www.kath.ch/addresses/?instid=1444',
                expanded: false
            },
            {
                Ort: 'Seewen, U.L.F. v. Seewen',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Alte Gasse 19',
                PLZ: '6423 Seewen SZ',
                phone: '041 811 11 84',
                email: 'pfarramt.seewen@bluewin.ch',
                Details: 'www.kath.ch/addresses/?instid=1449',
                expanded: false
            },
            {
                Ort: 'Steinen, Hl. Jakobus',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Rossbergstrasse 1',
                PLZ: '6422 Steinen',
                phone: '041 832 13 28',
                Fax: '041 832 08 28',
                email: 'pfarramtsteinen@bluewin.ch',
                website: 'www.pfarrei-steinen.ch/',
                Details: 'www.kath.ch/addresses/?instid=1593',
                expanded: false
            },
            {
                Ort: 'Steinerberg, Hl. Anna',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'c/o Schuler, Oberstockstrasse 31',
                PLZ: '6416 Steinerberg',
                phone: '041 832 13 63',
                email: 'kgsteinerberg@bluewin.ch',
                Details: 'www.kath.ch/addresses/?instid=1594',
                expanded: false
            },
            {
                Ort: 'Studen, Hl. Wendelin',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Waagtalstrasse 31',
                PLZ: '8842 Unteriberg',
                phone: '055 414 11 16',
                email: 'pfarrei@sankt-josef.ch',
                website: 'www.sankt-josef.ch/',
                Details: 'www.kath.ch/addresses/?instid=1599',
                expanded: false
            },
            {
                Ort: 'Unteriberg, Hl. Josef',
                Pfarramt: 'Kath. Pfarramt',
                Strasse: 'Waagtalstrasse 31',
                PLZ: '8842 Unteriberg',
                phone: '055 414 11 16',
                Fax: '055 414 20 37',
                email: 'pfarrei@sankt-josef.ch',
                website: 'www.sankt-josef.ch',
                Details: 'www.kath.ch/addresses/?instid=1685',
                expanded: false
            },
        ],
    }
}