export default {
    documents: [
        {
            type: 'RechtssammlungOld',
            file: '/documents/Rechtsammlung/20160115151415.pdf',
            number: '',
            description: 'Organisationsstatut der Römisch-katholischen Kantonalkirche Schwyz (vom 8. April 1998)',
            short: '',
        },
        {
            type: 'RechtssammlungOld',
            file: '/documents/Rechtsammlung/20120522153017.pdf',
            number: '402',
            description: 'Mitfinanzierungsbeschluss Fremdsprachigen Seelsorge vom 27. April 2012 (ehemals Nr. 402)',
            short: '',
        },
        {
            type: 'RechtssammlungOld',
            file: '/documents/Rechtsammlung/20120522153017.pdf',
            number: '620',
            description: 'Mollzugsverordnung zum Gesetz über Finanzhaushalt der ' +
                'Kirchgemeinden vom 24. September 2003 (ehemals 620)',
            short: '',
        },
    ]
}