import dekanatInnerschwyz from "./pfarreienDekanatBereiche/dekanatInnerschwyz";
import dekanatAusserschwyz from "./pfarreienDekanatBereiche/dekanatAusserschwyz";

export default {
    pfarreien: {
        title: 'Pfarreien',
        DekanatBereiche:[
            dekanatInnerschwyz.dekanat,
            dekanatAusserschwyz.dekanat,
        ],
    }
}