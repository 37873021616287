import sp20200504 from "./sp20200504";
import sp20191025 from "./sp20191025";
import sp20170519 from "./sp20170519";
import sp20160520 from "./sp20160520";
import sp20180528 from "./sp20180528";
import sp20190524 from "./sp20190524";
import sp20181019 from "./sp20181019";
import sp20171020 from "./sp20171020";
import sp20161021 from "./sp20161021";
import sp20160701 from "./sp20160701";
import sp20200619 from "./sp20200619";
import sp20201004 from "./sp20201004";
import sp20210528 from "./sp20210528";
import sp20211022 from "./sp20211022";
import sp20220520 from "./sp20220520";
import sp20221021 from "./sp20221021";
import sp20230526 from "./sp20230526";
import sp20231020 from "./sp20231020";
import sp20240531 from "./sp20240531";
import sp20240612 from "./sp20240612";
import sp20240925 from "./sp20240925";
export default [
    sp20240925,
    sp20240531,
    sp20231020,
    sp20230526,
    sp20221021,
    sp20220520,
    sp20211022,
    sp20210528,
    sp20201004,
    sp20200619,
    sp20200504,
    sp20191025,
    sp20190524,
    sp20181019,
    sp20180528,
    sp20171020,
    sp20170519,
    sp20161021,
    sp20160701,
    sp20160520,
    sp20240612,
]