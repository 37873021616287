export default {
    id: 20200619,
    date: 20200619,
    uploadDate: '28.05.2020',
    title: 'Konstituierende Session des Kantonskirchenrates vom  19. Juni 2020',
    shortDescriptionString: 'Sessionsprotokoll / Informationsschreiben / ' + 
        ' Ergebnisse in Kurzform /Traktanden / Unterlagen zur Session',
    subtitle: 'Der Kantonskirchenrat hielt seine konstituierende Session für die ' + 
    'Legislatur 2020 - 2024 am 19. Juni 2020 im Kultur- und Kongresszentrum ' + 
    'ZWEI RABEN in Einsiedeln (unter Einhaltung der aktuellen Hygiene- und ' + 
    'Abstandsvorschriften) ab.',
    sessionDate: '19.06.2020',
    resultPdfs: [
        {
            value: 'Protokoll der 2., konstituierenden Session 2020 des Kantonskirchenrates vom 19. Juni 2020',
            link: '/protocols/20200619/Sessionsprotokoll.pdf',
        },
        {
            value: 'Wahlen anlässlich der konstituierenden Session des Kantonskirchenrats vom 19. Juni 2020',
            link: '/protocols/20200619/wahlen2020_1.pdf',
        },
        {
            value: 'Informationsschreiben vom 26. Juni 2020',
            link: '/protocols/20200619/Informationsschreiben.pdf',
        },
    ],
    shortResults: [
        {
            name: 'sr1',
            value: `Büro des Kantonskirchenrates:<br>
Präsident: Johannes Schwimmer (Schwyz)<br>
Vizepräsidentin: Maria Bürgler (Illgau)<br>
Stimmenzähler: Emil Gnos (Küssnacht) und Cyrill Villiger (Lauerz)`
        },
        {
            name: 'sr2',
            value: `Sekretär des Kantonskirchenrats: Dr. Linus Bruhin (Nuolen)`
        },
        {
            name: 'sr3',
            value: `Kantonaler Kirchenvorstand:<br>
            Präsident: Lorenz Bösch (Ingenbohl-Brunnen)<br>
            Finanzverantwortliche: Karin Birchler (Studen)<br>
            Mitglieder: Vreni Bürgi (Wollerau), Hans-Peter Schuler (Ingenbohl-Brunnen) und Stephan Betschart (Muotathal)`
        },
        {
            name: 'sr4',
            value: `Sekretär des Kantonalen Kirchenvorstands: Dr. Linus Bruhin (Nuolen)`
        },
        {
            name: 'sr5',
            value: `Rekurskommission:<br>
            Präsident: lic.iur. Arthur Schilter (Arth) <br>
            Mitglieder: lic.iur. Annegreth Fässler (Schwyz) und Dr. Andreas Hubli (Altendorf)<br>
            Ersatzmitglieder: lic.iur. Jolanda Fleischli (Wangen) und Peter Trutmann (Immensee)`
        },
        {
            name: 'sr6',
            value: `Geschäftsprüfungskommission:<br>
            Präsident: Dr. Jürg F. Wyrsch (Tuggen)<br>
            Mitglieder:  Albert Beeler (Steinen), Dr. Ruedi Beeler (Rothenthurm), Claude Camenzind (Freienbach), Vreny Fuchs (Einsiedeln), Walter Furter (Ingenbohl-Brunnen), Mily Samaz (Freienbach), Bruno Grätzer (Morschach-Stoos) und Urs Peter Seeholzer (Küssnacht)`
        },
        {
            name: 'sr7',
            value: `Vorberatende Kommission für die Einführung eines Stimm- und Wahlrechts für Katholiken ohne Schweizer Bürgerrecht:<br>
            Präsident: Josef Reichmuth (Arth)<br>
            Mitglieder: Felix Beeler (Alpthal), Claude Camenzind (Freienbach), Dr. Roland Graf (Unteriberg), Urs Heini (Schwyz), Andreas Marty (Arth) und Brigitte Rickenbacher (Immensee)`
        },
    ],
    Traktanden: [
        {
            name: 'tt1',
            value: 'Teil1: Vorsitz Alterspräsident',
            unterTraktanden: [
                {
                    name: 'utt1',
                    prefix: '1',
                    value: 'Begrüssung und Präsenz',
                },
                {
                    name: 'utt2',
                    prefix: '2',
                    value: 'Bezeichnung von provisorischen Stimmenzählern',
                },
                {
                    name: 'utt3',
                    prefix: '3',
                    value: 'Beschluss über die Gültigkeit der Wahl der Mitglieder des Kantonskirchenrats',
                },
                {
                    name: 'utt4',
                    prefix: '4',
                    value: 'Wahl der Präsidentin bzw. des Präsidenten des Kantonskirchenrat',
                },
            ]
        },
        {
            name: 'tt2',
            value: 'Teil II: Vorsitz Präsidentin bzw. Präsident des Kantonskirchenrats',
            unterTraktanden: [
                {
                    name: 'utt1',
                    prefix: '5',
                    value: 'Gottesdienst, samt Vereidigung der Mitglieder des Kantonskirchenrats ' +
                        '(Amtseid oder Amtsgelübde)',
                },
                {
                    name: 'utt2',
                    prefix: '6',
                    value: 'Wahlen in das Büro des Kantonskirchenrats:',
                    unterUnterTraktanden: [
                        {
                            name: 'uutt1',
                            prefix: 'a)',
                            value: 'Wahl der Vizepräsidentin bzw. des Vizepräsidenten des Kantonskirchenrates',
                        },
                        {
                            name: 'uutt2',
                            prefix: 'b)',
                            value: 'Wahl von zwei Stimmenzählerinnen bzw. Stimmenzählern',
                        },
                    ]
                },
                {
                    name: 'utt3',
                    prefix: '7',
                    value: 'Wahl der Sekretärin bzw. des Sekretärs des Kantonskirchenrates',
                },
                {
                    name: 'utt4',
                    prefix: '8',
                    value: ' Wahl des Kantonalen Kirchenvorstandes:',
                    unterUnterTraktanden: [
                        {
                            name: 'uutt1',
                            prefix: 'a)',
                            value: 'Wahl der Präsidentin bzw. des Präsidenten des Kantonalen Kirchenvorstandes',
                        },
                        {
                            name: 'uutt2',
                            prefix: 'b)',
                            value: 'Wahl der bzw. des Finanzverantwortlichen des Kantonalen Kirchenvorstandes',
                        },
                        {
                            name: 'uutt2',
                            prefix: 'c)',
                            value: 'Wahl der drei Mitglieder des Kantonalen Kirchenvorstandes',
                        },
                    ]
                },
                {
                    name: 'utt3',
                    prefix: '9',
                    value: 'Vereidigung der Mitglieder des Kantonalen Kirchenvorstandes (Amtseid oder Amtsgelübde)',
                },
                {
                    name: 'utt3',
                    prefix: '10',
                    value: 'Wahl der Sekretärin bzw. des Sekretärs des Kantonalen Kirchenvorstandes',
                },
                {
                    name: 'utt2',
                    prefix: '11',
                    value: 'Wahlen in das Büro des Kantonskirchenrats:',
                    unterUnterTraktanden: [
                        {
                            name: 'uutt1',
                            prefix: 'a)',
                            value: 'Wahl der Präsidentin bzw. des Präsidenten der Rekurskommission',
                        },
                        {
                            name: 'uutt2',
                            prefix: 'b)',
                            value: 'Wahl der zwei Mitglieder und der zwei Ersatzmitglieder der Rekurskommission',
                        },
                    ],
                },
                {
                    name: 'utt3',
                    prefix: '12',
                    value: 'Wahl der Geschäftsprüfungskommission mit einer Präsidentin bzw. ' +
                        'einem Präsidenten und acht Mitgliedern',
                },
                {
                    name: 'utt3',
                    prefix: '13',
                    value: 'Wahl einer vorberatenden Kommission für die Übernahme der Organisation der ' +
                        'Anderssprachigenseelsorge durch die Kantonalkirche',
                },
                {
                    name: 'utt3',
                    prefix: '14',
                    value: 'Informationen der Ressortchefs und Fragestunde',
                },
                {
                    name: 'utt3',
                    prefix: '15',
                    value: 'Verschiedenes',
                },
            ]
        },
    ],
    Unterlagen: [
        {
            value: 'Sessionseinladung',
            link: '/protocols/20200619/sessionseinladung.pdf',
        },
        {
            value: 'Beilagenblatt',
            link: '/protocols/20200619/beilagenblatt.pdf',
        },
        {
            value: 'Kantonskirchenrat in der 6. Legislatur',
            link: '/protocols/20200619/kantonskirchenrat_in_der_6_legislatur_new.pdf',
        },
        {
            value: 'Prüfbericht GPK',
            link: '/protocols/20200619/pruefbericht_gpk.pdf',
        },
        {
            value: 'Wahlvorschläge',
            link: '/protocols/20200619/wahlvorschlaege.pdf',
        },
        {
            value: 'Bericht und Antrag für die Einführung eines Stimm- und Wahlrechts für Katholiken ohne Schweizer Bürgerrecht',
            link: '/protocols/20200619/stimmrechtsantrag.pdf',
        },
        {
            value: 'Kommissionen',
            link: '/protocols/20200619/Kommissionen_new.pdf',
        },
    ]
}