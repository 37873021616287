export default {
    id: 12,
    name: 'aktuelles_meldungSteuersatz',
    title: 'Meldung Steuersatz und Voranschlag für das Jahr 2019',
    value: "Für die Berechnungen zum Finanzausgleich und dem Voranschlag der Kantonalkirche " +
        "ist u.a. auch der dann von der Kirchgemeindeversammlung beschlossene Steuersatz " +
        "der einzelnen Kirchgemeinden massgebend.<br>" +
        "Ebenso benötigt die kantonale Steuerverwaltung bereits auf Anfang Januar 2019 diese Angaben " +
        "der Kirchgemeinden, welche ihr vom Kantonalen Kirchenvorstand weitergeleitet werden.",
    target: "InfoSite",
    Post: true,
    date: 20181126,
    termin: "Termin: Dienstag, 18. Dezember 2018",
    files: {
        filesTitle: "Brief und Formulare:",
        filesList: [
            {
                name: "Meldung Steuersatz per 1. Januar 2019 (pdf)",
                path: '/documents/steuersaetze/20190522180919.pdf'
            },
            {
                name: "Meldung Steuersatz per 1. Januar 2019 (Word)",
                path: '/documents/steuersaetze/20181126200005.docx'
            },
        ]
    }
}