export default {
    id: 20161021,
    date: 20161021,
    title: 'Session des Kantonskirchenrates vom 21. Oktober 2016',
    subtitle: 'Der Kantonskirchenrat hielt seine ordentliche Herbstsession <br>' +
        'am 21. Oktober 2016, im SJBZ in Einsiedeln ab.',
    sessionDate: '21.10.2016',
    resultPdfs: [
        {
            value: 'Protokoll der Session vom  21. Oktober 2016',
            link: '/protocols/20161021/20161021_protokoll.pdf',
        },
        {
            value: 'Informationsschreiben vom 9. November 2016',
            link: '/protocols/20161021/20161021_infoschreiben.pdf',
        },
        {
            value: 'Gesetz über die Anderssprachigenseelsorge',
            link: '/protocols/20161021/20161021_gesetzt.pdf',
        },
        {
            value: 'Merkblatt Urheberrecht',
            link: '/protocols/20161021/20161021_mb_Urherberrechte.pdf',
        },
    ],
    shortResults: [
        {
            name: 'sr1',
            value: 'Das Gesetz über die Organisation der Anderssprachigenseelsorge der Römisch-katholischen ' +
                'Kantonalkirche Schwyz wurde mit Änderungen in den Formulierungen erlassen.'
        },
        {
            name: 'sr2',
            value: 'Der Voranschlag 2017 wurde gemäss dem unveränderten Antrag mit einem Total von' +
                'Fr. 1’666’382.-- und dem Pro-Kopf-Beitrag an die Kantonalkirche von gesamthaft Fr. 17.10 beschlossen.'
        },
        {
            name: 'sr3',
            value: 'Der Finanzausgleich 2017 wurde gemäss dem unveränderten Antrag mit einem Ausgleich des ' +
                'Normleistungsaufwandes von Fr. 255.45 (d.h. einem Ausgleich der Normkosten zu 92.5%) ' +
                'und mit einem Gesamtbudget von Fr. 1’051’242.-- beschlossen.'
        },
    ],
    Traktanden: [
        {
            name: 'tt1',
            value: 'Gebet, Begrüssung, Präsenz und Vereidigung von neuen Mitgliedern',
        },
        {
            name: 'tt2',
            value: 'Beschluss über das Gesetz über die Organisation der Anderssprachigenseelsorge der Römisch-katholischen Kantonalkirche Schwyz',
        },
        {
            name: 'tt3',
            value: 'Beschluss über den Voranschlag 2017',
        },
        {
            name: 'tt4',
            value: 'Beschluss über den Finanzausgleich 2017',
        },
        {
            name: 'tt5',
            value: 'Informationen der Ressortchefs und Fragestunde',
        },
        {
            name: 'tt6',
            value: 'Verschiedenes',
        },
    ],
    Unterlagen: [
        {
            value: 'Sessionseinladung',
            link: '/protocols/20161021/Sessionseinladung.pdf',
        },
        {
            value: 'Beilagenblatt',
            link: '/protocols/20161021/Beilagenblatt.pdf',
        },
        {
            value: 'Antrag Voranschlag 2017',
            link: '/protocols/20161021/av2017.pdf',
        },
        {
            value: 'Bericht zum Voranschlag 2017',
            link: '/protocols/20161021/bv2017.pdf',
        },
        {
            value: 'Finanzplanung 2017 - 2020',
            link: '/protocols/20161021/fp2017_2020.pdf',
        },
        {
            value: 'Antrag Finanzausgleich 2017',
            link: '/protocols/20161021/af2017.pdf',
        },
        {
            value: 'Übersicht Finanzausgleich 2008 - 2016',
            link: '/protocols/20161021/uebersicht_fa_08_16.pdf',
        },
        {
            value: 'Bericht der GPK',
            link: '/protocols/20161021/bericht_gpk.pdf',
        },
        {
            value: 'Bericht ASS-Kommission',
            link: '/protocols/20161021/b_ASS_K.pdf',
        },
        {
            value: 'Stellungnahme zur ASS',
            link: '/protocols/20161021/s_ASS.pdf',
        },
        {
            value: 'Antrag ASS-Gesetz',
            link: '/protocols/20161021/a_ASS_G.pdf',
        },
    ]
}