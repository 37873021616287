export default {
    documents: [
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/0.pdf',
            number : '',
            description : '<b>Systematik und Inhalt der Rechtssammlung</b>',
            short: '',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/100.pdf',
            number : '100',
            description : 'Verfassung der Römisch-katholischen Kantonalkirche Schwyz vom 17. Oktober 2014',
            short: 'RKKV',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/110.pdf',
            number : '110',
            description : 'Wahl- und Abstimmungsgesetz der Römisch-katholischen Kantonalkirche Schwyz ' +
                'vom 21. September 2001',
            short: 'WAG',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/120.pdf',
            number : '120',
            description : 'Geschäftsordnung für den Kantonskirchenrat der Römisch-katholischen Kantonalkirche Schwyz ' +
                'vom 17. September 1999',
            short: 'GO-KKR',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/130.pdf',
            number : '130',
            description : 'Entschädigungs- und Besoldungsgesetz für die Römisch-katholischen Kantonalkirche Schwyz ' +
                'vom 25. September 2009',
            short: 'EntschBG',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/140.pdf',
            number : '140',
            description : 'Geschäftsordnung für den Kantonalen Kirchenvorstand ' +
                'der Römisch-katholischen Kantonalkirche Schwyz vom 22. April 2005',
            short: 'GO-KVS',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/141.pdf',
            number : '141',
            description : 'Pflichtenheft des Präsidenten des Kantonalen Kirchenvorstandes vom 6. November 2019',
            short: 'PflH Präsidium',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/142.pdf',
            number : '142',
            description : 'Pflichtenheft des Ressortchefs Finanzen des Kantonalen Kirchenvorstandes ' +
                'vom 6. November 2019',
            short: 'PflH Finanzen',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/143.pdf',
            number : '143',
            description : 'Pflichtenheft des Ressortchefs Seelsorge des Kantonalen Kirchenvorstandes ' +
                'vom 6. November 2019',
            short: 'PflH Seelsorge',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/144.pdf',
            number : '144',
            description : 'Pflichtenheft des Ressortchefs Bildung ' +
                'des Kantonalen Kirchenvorstandes vom 6. November 2019',
            short: 'PflH Bildung',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/145.pdf',
            number : '145',
            description : 'Pflichtenheft des Ressortchefs Rechtswesen des Kantonalen Kirchenvorstandes ' +
                'vom 6. November 2019',
            short: 'PflH Rechtswesen',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/146.pdf',
            number : '146',
            description : 'Pflichtenheft des Sekretärs der Kantonalkirche vom 6. November 2019',
            short: 'PflH Sekretär',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/210.pdf',
            number : '210',
            description : 'Finanzausgleichsgesetz der Römisch-katholischen Kantonalkirche Schwyz vom 5. April 2001',
            short: 'FAG',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/310.pdf',
            number : '310',
            description : 'Gesetz über die Organisation der Kirchgemeinden ' +
                'der Römisch-katholischen Kantonalkirche Schwyz vom 20. September 2002',
            short: 'KGOG',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/311.pdf',
            number : '311',
            description : 'Verordnung über das Archivwesen der Kirchgemeinden ' +
                'der Römisch-katholischen Kantonalkirche Schwyz vom 13. Dezember 2006',
            short: 'ArchivV',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/312.pdf',
            number : '312',
            description : 'Verordnung über den Datenschutz in den Kirchgemeinden ' +
                'der Römisch-katholischen Kantonalkirche Schwyz vom 13. Dezember 2006',
            short: 'DatenschutzV',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/320.pdf',
            number : '320',
            description : 'Gesetz über den Finanzhaushalt der Kirchgemeinden ' +
                'der Römisch-katholischen Kantonalkirche Schwyz vom 20. September 2002',
            short: 'FHG',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/321.pdf',
            number : '321',
            description : 'Vollzugsverordnung zum Gesetz über den Finanzhaushalt der Kirchgemeinden ' +
                'vom 6. November 2019',
            short: 'VVzFHG',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/330.pdf',
            number : '330',
            description : 'Vereinbarung zwischen dem Bistum Chur und der Römisch-katholischen Kantonalkirche Schwyz ' +
                'betreffend der kirchlichen Stiftungen im Kanton Schwyz, die teilweise oder ganz von Kirchgemeinden ' +
                'unterstützt werden vom 1. März 2001 / 12. Mai 2001',
            short: 'StVer',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/410.pdf',
            number : '410',
            description : 'Mitfinanzierungsgesetz der Römisch-katholischen Kantonalkirche Schwyz vom 5. April 2001',
            short: 'MitfG',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/420.pdf',
            number : '420',
            description : 'Beschluss des Kantonskirchenrates der Römisch-katholischen Kantonalkirche Schwyz über ' +
                'die Mitfinanzierung der Regionalen Arbeitsstelle Jungwacht/Blauring Uri/Schwyz vom 22. April 2005',
            short: 'MitfB RAST',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/430.pdf',
            number : '430',
            description : 'Beschluss des Kantonskirchenrates der Römisch-katholischen Kantonalkirche Schwyz ' +
                'über die Mitfinanzierung der Spitalseelsorge vom 29. Mai 2020',
            short: 'MitfB Spital',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/510.pdf',
            number : '510',
            description : 'Gesetz über die Anderssprachigenseelsorge der Römisch-katholischen Kantonalkirche Schwyz ' +
                'vom 21. Oktober 2016',
            short: 'ASSG',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/520.pdf',
            number : '520',
            description : 'Gesetz über die Katechetische Arbeitsstelle ' +
                'der Römisch-katholischen Kantonalkirche Schwyz vom 24. Mai 2019',
            short: 'KASG',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/521.pdf',
            number : '521',
            description : 'Leistungsauftrag Katechetische Arbeitsstelle vom 6. November 2019',
            short: 'LA-KAS',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/610.pdf',
            number : '610',
            description : 'Personal- und Besoldungsgesetz der Römisch-katholischen Kantonalkirche Schwyz ' +
                'vom 7. Dezember 2001',
            short: 'PersBG',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/611.pdf',
            number : '611',
            description : 'Personal- und Besoldungsverordnung der Römisch-katholischen Kantonalkirche Schwyz ' +
                'vom 12. Februar 2003',
            short: 'PersBV',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/620.pdf',
            number : '620',
            description : 'Vereinbarung zwischen dem Bistum Chur und der Abtei Einsiedeln sowie ' +
                'der Römisch-katholischen Kantonalkirche Schwyz betreffend Ernennung, Anstellung und Abberufung ' +
                'der Geistlichen vom 14. August 2002',
            short: 'GeistlVer',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/710.pdf',
            number : '710',
            description : 'Gesetz über die Rekurskommission der Römisch-katholischen Kantonalkirche Schwyz ' +
                'vom 15. September 2000',
            short: 'RKG',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/720.pdf',
            number : '720',
            description : 'Gesetz über die Rechtspflege der Römisch-katholischen Kantonalkirche Schwyz ' +
                'vom 15. September 2000',
            short: 'RPflG',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/721.pdf',
            number : '721',
            description : 'Gebührenordnung für die Verwaltung und die Rechtspflege ' +
                'der Römisch-katholischen Kantonalkirche Schwyz vom 15. Dezember 2000',
            short: 'GebO',
        },
        {
            type : 'Rechtssammlung',
            file : '/documents/Rechtsammlung/730.pdf',
            number : '730',
            description : 'Vereinbarung zwischen der Diözese Chur und den zuständigen staatskirchenrechtlichen ' +
                'Organisationen der Bistumskantone Glarus, Graubünden, Nidwalden, Obwalden, Schwyz, Uri und Zürich ' +
                'über die Führung einer diözesanen Schlichtungsstelle vom 21. März 2001',
            short: 'SchlStVer',
        },
    ]
}