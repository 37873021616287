export default {
    documents: [
        {
            type: 'finanzausgleich',
            file: '/protocols/20231020/9_Antrag_Finanzausgleich_2024.pdf',
            number: '',
            description: 'Finanzausgleich 2024',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/protocols/20221021/FA_2023__mit_97.5.pdf',
            number: '',
            description: 'Finanzausgleich 2023',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2022.pdf',
            number: '',
            description: 'Finanzausgleich 2022',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2021.pdf',
            number: '',
            description: 'Finanzausgleich 2021',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2020.pdf',
            number: '',
            description: 'Finanzausgleich 2020',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2019.pdf',
            number: '',
            description: 'Finanzausgleich 2019',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2018.pdf',
            number: '',
            description: 'Finanzausgleich 2018',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2017.pdf',
            number: '',
            description: 'Finanzausgleich 2017',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2016.pdf',
            number: '',
            description: 'Finanzausgleich 2016',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2015.pdf',
            number: '',
            description: 'Finanzausgleich 2015',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2014.pdf',
            number: '',
            description: 'Finanzausgleich 2014',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2013.pdf',
            number: '',
            description: 'Finanzausgleich 2013',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2012.pdf',
            number: '',
            description: 'Finanzausgleich 2012',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2011.pdf',
            number: '',
            description: 'Finanzausgleich 2011',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2010.pdf',
            number: '',
            description: 'Finanzausgleich 2010',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2009.pdf',
            number: '',
            description: 'Finanzausgleich 2009',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2008.pdf',
            number: '',
            description: 'Finanzausgleich 2008',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2007.pdf',
            number: '',
            description: 'Finanzausgleich 2007',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2006.pdf',
            number: '',
            description: 'Finanzausgleich 2006',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2005.pdf',
            number: '',
            description: 'Finanzausgleich 2005',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2004.pdf',
            number: '',
            description: 'Finanzausgleich 2004',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2003.pdf',
            number: '',
            description: 'Finanzausgleich 2003',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2002.pdf',
            number: '',
            description: 'Finanzausgleich 2002',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2001.pdf',
            number: '',
            description: 'Finanzausgleich 2001',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_2000.pdf',
            number: '',
            description: 'Finanzausgleich 2000',
            short: '',
        },
        {
            type: 'finanzausgleich',
            file: '/documents/finanz_ausgleiche/Finanzausgleich_1999.pdf',
            number: '',
            description: 'Finanzausgleich 1999',
            short: '',
        },
    ]
}