export default {
    links: [
        {
            id: -1,
            name: "Link",
            value: 'Kirchensteuern sei Dank',
            link: 'https://sz.kirchensteuern-sei-dank.ch',
            target: "/Links",
            Post: false,
            date: 20231101,
        },
        {
            id: -1,
            name: "Link",
            value: 'Portal kath.ch',
            link: 'https://www.kath.ch/',
            target: "/Links",
            Post: false,
            date: 20141017,
        },
        {
            id: -1,
            name: "Link",
            value: 'Bistum Chur',
            link: 'https://www.bistum-chur.ch/',
            target: "/Links",
            Post: false,
            date: 20141017,
        },
        {
            id: -1,
            name: "Link",
            value: 'Generalvikariat Urschweiz',
            link: 'https://www.generalvikariat-urschweiz.ch/',
            target: "/Links",
            Post: false,
            date: 20141017,
        },
        {
            id: -1,
            name: "Link",
            value: 'Katechetische Arbeitsstelle Schwyz',
            link: 'https://sz.kath.ch/kas/',
            target: "/Links",
            Post: false,
            date: 20141017,
        },
        {
            id: -1,
            name: "Link",
            value: 'Seelsorgerat des Kantons Schwyz',
            link: 'http://www.seelsorgerat-schwyz.ch/',
            target: "/Links",
            Post: false,
            date: 20141017,
        },
        {
            id: -1,
            name: "Link",
            value: 'Römisch-Katholische Zentralkonferenz der Schweiz (RKZ)',
            link: 'https://www.rkz.ch/aktuell',
            target: "/Links",
            Post: false,
            date: 20141017,
        },
        {
            id: -1,
            name: "Link",
            value: 'Kloster Einsiedeln',
            link: 'https://www.kloster-einsiedeln.ch/',
            target: "/Links",
            Post: false,
            date: 20141017,
        },
        {
            id: -1,
            name: "Link",
            value: 'Kanton Schwyz',
            link: 'https://www.sz.ch/',
            target: "/Links",
            Post: false,
            date: 20141017,
        },
        {
            id: -1,
            name: "Link",
            value: 'SJBZ Einsiedeln / Hotel Allegro',
            link: 'https://hotel-allegro.ch/',
            target: "/Links",
            Post: false,
            date: 20141017,
        },
    ]
}
