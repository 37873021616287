export default {
    id: 16,
    name: "20200208InformationWriting",
    type: "InformationWrting",
    title: 'Vernehmlassung zum Stimm- und Wahlrecht für Ausländer in der Römisch-katholischen Kantonalkirche Schwyz',
    value: 'Für die Einführung eines Stimm- und Wahlrecht für Ausländerinnen und Ausländer in der ' +
        'Römisch-katholischen Kantonalkirche Schwyz wird eine Vernehmlassung durchgeführt: ' +
        '"Ein Mitglied der Kantonalkirche und der Kirchgemeinde seines Wohnsitzes ohne Schweizer Bürgerrecht, ' +
        'welches das 18. Altersjahr zurückgelegt hat, ist stimm- und wahlberechtigt, ' +
        'sobald es die Niederlassungsbewilligung C erhalten hat."' +
        'Die Kirchgemeinderäte und weitere interessierte Personen sind eingeladen, ' +
        'bis zum 15. April 2020 ihre Stellungnahme abzugeben. ' +
        'Und das Schreiben gemäss Anhang sowie das Vernehmlassungsformular (als PDF und Word) sind zu verlinken. ' +
        'Und allenfalls kommt dann noch eine Medienmitteilung hinzu, die ich aber vermutlich erst nachher erhalten werde.',
    target: "InfoSite",
    Post: true,
    date: 20200311,
    files: {
        filesTitle: "Dateien",
        filesList: [
            {
                name: "Schreiben",
                path: '/documents/info_writing_for_20200311/Schreiben.pdf'
            },
            {
                name: "Vernehmlassungsformular",
                path: '/documents/info_writing_for_20200311/Vernehmlassungsformular.pdf'
            },
            {
                name: "KK Medienmitteilung Ausländer Stimm- und Wahlrecht",
                path: '/documents/info_writing_for_20200311/KK_Medienmitteilung_Auslaender_Stimm-_und_Wahlrecht.docx'
            },
        ]
    }
}