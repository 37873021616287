export default {
    id: 20201004,
    date: 20201023,
    uploadDate: '04.10.2020',
    title: 'Session des Kantonskirchenrates vom 23. Oktober 2020',
    shortDescriptionString: 'Sessionsprotokoll / Informationsschreiben / Ergebnisse in Kurzform /' +
        'Traktanden / Unterlagen zur Session',
    subtitle: 'Der Kantonskirchenrat hielt seine ordentliche Herbstsession am 23.' +
        'Oktober 2020 im Schweizerischen Jugend- und Bildungszentrum SJBZ in' +
        'Einsiedeln (unter Einhaltung der aktuellen Hygiene- und' +
        'Abstandsvorschriften) ab.',
    sessionDate: '23.10.2020',
    resultPdfs: [
        {
            value: 'Protokoll der Session vom 23. Oktober 2020',
            link: '/protocols/20201004/Sessionsprotokoll.pdf',
        },
        {
            value: 'Informationsschreiben vom 8. November 2019',
            link: '/protocols/20201004/Informationsschreiben.pdf',
        },
        {
            value: 'genehmigter Voranschlag 2021',
            link: '/protocols/20201004/Voranschlag_2021_KKR_2020-10-23.pdf',
        },
    ],
    shortResults: [
        {
            name: 'sr1',
            value: '§ 3 des Wahl- und Abstimmungsgesetzes wird antragsgemäss um einen Absatz 2 ergänzt: ' +
            '“Ein Mitglied der Kantonalkirche und der Kirchgemeinde seines Wohnsitzes ohne Schweizer Bürgerrecht, ' +
            'welches das 18. Altersjahr zurückgelegt hat, ist stimm- und wahlberechtigt, ' +
            'sobald es die Niederlassungsbewilligung C erhalten hat.” Dieser Beschluss unterliegt dem fakultativen Referendum.'
        },
        {
            name: 'sr2',
            value: 'Der Voranschlag 2020 wurde in einer leicht erhöhten Fassung mit einem Total von Fr. 2’134’464.-- ' +
            'und dem beantragten Pro-Kopf-Beitrag an die Kantonalkirche von gesamthaft Fr. 22.80 beschlossen.'
        },
        {
            name: 'sr3',
            value: 'Der Finanzausgleich 2021 wurde gemäss dem unveränderten Antrag mit einem Ausgleich des ' +
            'Normleistungsaufwandes von Fr. 264.25 (d.h. einem Ausgleich der Normkosten zu 90.0%) ' +
            'und mit einem Gesamtbudget von Fr. 1’069’090.-- beschlossen.'
        },
    ],
    Traktanden: [
        {
            name: 'tt1',
            value: 'Gebet, Begrüssung, Präsenz und Vereidigung eines Mitglieds',
        },
        {
            name: 'tt2',
            value: 'Änderung des Wahl- und Abstimmungsgesetzes zur Einführung eines Stimm- und Wahlrechts für Katholiken ohne Schweizer Bürgerrecht',
        },
        {
            name: 'tt3',
            value: 'Beschluss über den Voranschlag 2021',
        },
        {
            name: 'tt4',
            value: 'Beschluss über den Finanzausgleich 2021',
        },
        {
            name: 'tt5',
            value: 'Informationen der Ressortchefs und Fragestunde',
        },
        {
            name: 'tt6',
            value: 'Verschiedenes',
        }
    ],
    Unterlagen: [
        {
            value: 'Sessionseinladung',
            link: '/protocols/20201004/sessionseinladung.pdf',
        },
        {
            value: 'Beilagenblatt',
            link: '/protocols/20201004/beilagenblatt.pdf',
        },
        {
            value: 'Ausländerstimmrecht 2020 - vorberatende Kommission - Antrag',
            link: '/protocols/20201004/Auslaenderstimmrecht_2020_-_vorberatende_Kommission_-_Antrag.pdf',
        },
        {
            value: 'Voranschlag 2021',
            link: '/protocols/20201004/Voranschlag_2021.pdf',
        },
        {
            value: 'ASS Voranschlag 2021',
            link: '/protocols/20201004/ASS_Voranschlag_2021.pdf',
        },
        {
            value: 'KAS Voranschlag 2021',
            link: '/protocols/20201004/KAS_Voranschlag_2021.pdf',
        },
        {
            value: 'Bericht zum Voranschlag 2021',
            link: '/protocols/20201004/Bericht_zum_Voranschlag_2021.pdf',
        },
        {
            value: 'GPK 2020 - Antrag Budget 2021',
            link: '/protocols/20201004/GPK_2020_-_Antrag_Budget_2021.pdf',
        },
        {
            value: 'Finanzplanung 2021-2024',
            link: '/protocols/20201004/Finanzplanung_2021-2024.pdf',
        },
        {
            value: 'Finanzausgleich 2021',
            link: '/protocols/20201004/Finanzausgleich_2021.pdf',
        },
        {
            value: 'Finanzausgleich 2014-2021 Varianten',
            link: '/protocols/20201004/Finanzausgleich_2014-2021_Varianten.pdf',
        },
    ]
}